import React, {Fragment, useEffect, useState} from 'react';
import AppHeader from 'Layout/AppHeader/';
import AppSidebar from 'Layout/AppSidebar/';
import AppFooter from 'Layout/AppFooter/';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {MySpinner} from 'components/atoms';
import {debounce} from 'lodash';
import {
  Datatable,
  Collaborator,
  Appointments,
  PushNotification,
} from 'components/organisms';
import {Button} from 'antd';
import {
  Row,
  // Button,
  Form,
  CardBody,
  Card,
  Spinner,
  Input as Input2,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
} from 'reactstrap';
import ShowContact from '../../components/organisms/showContact.js'
import CenterFormApi from 'services/api/CenterFormApi';
import history from 'services/history';
import moment from 'moment';
import {customStylesTable, style, styleQA} from 'shared/customTable';
import iconUser from 'shared/user.png';
import Diagnosis from 'pages/User/Diagnosis';
import UserProfile from '../UserProfile/index';
import {EyeOutlined, LoadingOutlined} from '@ant-design/icons';
import {demands, returnBGcolor, returnStatement} from 'shared/data';
import {Editor} from 'react-draft-wysiwyg';
import {ContentState, EditorState, convertToRaw} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import {resetServerContext} from 'react-beautiful-dnd';
import {
  showToast,
  truncateString,
  userStatus,
  userVacation,
} from 'shared/extra';

const CguPage = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [blockBtn, setblockBtn] = useState(true);
  const [isCGU, setIsSGU] = useState(true);
  // const [totalRows, setTotalRows] = useState(15);

  const [showPushNotification, setShowPushNotification] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [totalRows, setTotalRows] = useState(15);
  const [cguData, setcguData] = useState(EditorState.createEmpty());
  const [contactData, setContactData] = useState();
  const [sort, setSort] = useState({
    _id: -1,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');

  const fetchContact = async (page,limit) => {
    try {
      setLoadingTable(true);
      setLoadingData(true);
      const response = await CenterFormApi.post(`/api/fetchContact`, {
        page,
        limit,
      });
      setTotalRows(response.data.total);

      setLoadingTable(false);

      setContactData(response.data.data);
    } catch (e) {}
  };

  const handlePageChange = page => {
    fetchContact(page, limit);
  };

  // console.log(contactData);

  const fetchCGU = async () => {
    try {
      setLoadingData(true);
      const response = await CenterFormApi.get(`/api/mobile/fetch-cgu`);
      const blocksFromHtml = htmlToDraft(response.data.data.text);
      const {contentBlocks, entityMap} = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      setLoadingData(false);

      setcguData(
        response.data.data.cgu ? editorState : EditorState.createEmpty(),
      );
    } catch (e) {}
  };

  const handlePerRowsChange = async (limit, page) => {
    fetchContact(page, limit);
  };

  const columns = [
    {
      style: styleQA,
      name: 'Question',
      selector: row => truncateString(row.message, 50),
      fieldName: 'message',
    },

    {
      name: 'Nom',
      selector: row => row.from_user.firstName + ' ' + row.from_user.lastName,
      fieldName: 'firstName',
    },
    {
      name: 'Statut',
      selector: row => userStatus(row.from_user.state),
      fieldName: 'state',
    },
    // {
    //   name: 'Prochain Rendez-vous',
    //   selector: row => row.email,
    //   fieldName: 'email',
    //   style: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     color: 'black',
    //   },
    // },
    {
      name: 'E-mail',
      selector: row => row.from_user.email,

      fieldName: 'email',
    },
    {
      name: 'Téléphone ',
      selector: row => row.from_user.phone,

      fieldName: 'phone',
    },
    {
      name: 'Sexe ',
      selector: row =>
        row.from_user.gender == 'female' ? (
          <div
            style={{
              width: '70px',
              fontWeight: 800,
              background: '#F11A7B',
              color: 'white',
              padding: '6px',
              borderRadius: '5px',
              textAlign: 'center',
            }}>
            Femme
          </div>
        ) : (
          <div
            style={{
              width: '70px',
              fontWeight: 800,
              background: '#0079FF',
              color: 'white',
              padding: '6px',
              borderRadius: '5px',
              textAlign: 'center',
            }}>
            Homme
          </div>
        ),
    },
  ];

  const updateCGU = async () => {
    try {
      const res = await CenterFormApi.post(`/api/back-office/cgu`, {
        text: draftToHtml(convertToRaw(cguData.getCurrentContent())),
      });

      if (res.status == 200 || resetServerContext.status == 201) {
        setblockBtn(true);
        showToast('success', 'Les modifications ont été apportées');
      }
    } catch (e) {
      setblockBtn(true);
      showToast('error', "Les modifications n'ont pas été apportées");
    }
  };

  useEffect(() => {
    fetchCGU();
    fetchContact(page,15); // fetch page 1 of users
  }, []);

  const onInputChange = e => {
    setblockBtn(false);
    setcguData(e);
  };

  // console.log(showPushNotification);

  return (
    <Fragment>
      {/* <AppHeader pageTitle="Collaborateurs" /> */}
      <div
        style={{overflow: 'none', padding: '0 25px'}}
        // className="app-main ecs-main"
      >
        {/* // <AppSidebar /> */}
        <div
        // className="app-main__outer"
        >
          <div
          // className="app-main__inner"
          >
            {/* <MySpinner active={loadingData} /> */}
            <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <label
                    style={{
 
                      backgroundColor: isCGU ? "orange":"#50C4ED",
                      marginBottom: "0", color: "white"
                    }}
                    className="center-form-label-cgu">
                    {isCGU
                      ? 'Les conditions générales d’utilisation'
                      : 'Contact'}
                  </label>
                  <div>
                    <Button
                      onClick={() => {
                        setIsSGU(true);
                      }}
                      type="primary"
                      style={{
                        color: 'black',
                        // width: '200px',
                        //  marginRight:"15px",
                        margin: '0 10px 10px 0',
                        border: '1px solid black',
                        width: isCGU ? '150px' : '100px',
                        background: 'orange',
                        opacity: isCGU ? '.5' : '1',
                      }}>
                      CGU
                    </Button>
                    <Button
                      onClick={() => {
                        setIsSGU(false);
                      }}
                      type="primary"
                      style={{
                        color: 'White',
                        border: '1px solid black',
                        width: !isCGU ? '150px' : '100px',
                        opacity: !isCGU ? '.5' : '1',
                        //  width: '200px',
                        margin: '0 0 10px 0',
                        background: '#039be5',
                      }}>
                      Contact
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  {isCGU && (
                    <Button
                      type="primary"
                      style={{
                        width: '200px',
                        margin: '0 0 10px 0',
                        background: 'orange',
                      }}
                      disabled={blockBtn}
                      onClick={updateCGU}>
                      Sauvgarder
                    </Button>
                  )}
                </div>

                {!loadingData && isCGU ? (
                  <Editor
                    editorState={cguData}
                    wrapperClassName="target-wrapper-cgu"
                    editorClassName="target-editor"
                    onEditorStateChange={value => onInputChange(value)}
                  />
                ) : isCGU ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <LoadingOutlined />
                  </div>
                ) : (
                  <Card
                    className="card-container"
                    style={{
                      boxShadow: 'none',
                      borderTop: '1px solid #bfbfbf38',
                      borderBottom: '1px solid #bfbfbf38',
                    }}>
                    <CardBody className="users-table">
                      <Datatable
                        title={
                          <div className="tableHeader">
                                <label
                            style={{marginBottom:"0"}}
                                  className="card-title">
                              Liste de Contact
                            </label>
                          </div>
                        }
                        columns={columns}
                        data={contactData}
                        paginationPerPage={limit}
                        progressPending={loadingTable}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        // onSort={handleSort}
                        defaultSortFieldId={1}
                        defaultSortAsc={false}
                        onRowClicked={row => {
                          setShowPushNotification(true);
                          setSelectedQuestion(row);
                        }}
                        customStyles={customStylesTable({
                          fromContact: true,
                          pointer: true,
                        })}
                      />
                    </CardBody>
                  </Card>
                )}
              </Row>
            </ReactCSSTransitionGroup>
          </div>
        </div>
        
      </div>
  
       <ShowContact
         data={selectedQuestion}
         showPushNotification={showPushNotification}
         onHide={() => setShowPushNotification(false)}
       />
    </Fragment>
  );
};

export default CguPage;
