import React from 'react';

const CircleComponent = ({color,text}) => {
    return (
      <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
    <div style={{ borderRadius: "50%", backgroundColor: color, height: "15px", width: "15px" }}></div>
            <span style={{
                marginTop:"2px",
                marginLeft: '5px',
            marginRight:'15px',
                fontSize: "13px"
            }}>{text}</span>
            </div>
  );
}

export default CircleComponent;