import React, {useEffect, useRef} from 'react';
import ReactECharts from 'echarts-for-react';
import {option, optionW} from 'shared/optionCarts';
import {optionDate, optionsT} from 'shared/optionCartsDate';
import {
  all_weights,
  applyChecker,
  applyChecker2,
  applyColorWO,
  checkSuccessive,
  expandArray,
  getStartOfWeeksOfYearFromNow2v,
  removeDuplicateMeals,
} from 'shared/extra';
import html2canvas from 'html2canvas';
import {VerticalAlignBottomOutlined} from '@ant-design/icons';
import CircleComponent from 'DemoPages/Elements/points';

function getStartOfWeeksOfYearFromNow(td) {
  //  console.log(today);
  const today = new Date(td);
  const currentYear = today.getFullYear();
  const currentWeek = getWeekNumber(today);
  // console.log(currentWeek);
  const weeks = [];
  let mealWeek = 0;
  // Loop through each week of the year starting from the current week
  for (let i = currentWeek; i <= 52; i++) {
    mealWeek++;
    const date = new Date(currentYear, 0, 8 + (i - 2) * 7);
    const dd = String(date.getDate()).padStart(1, '0');
    const mm = String(date.getMonth() + 1).padStart(1, '0');
    const weekStart = `S${mealWeek} \n ${dd}/${mm}${
      mm == 1 || mealWeek == 1 ? ' • ' + date.getFullYear() : ''
    }`;
    weeks.push(weekStart);
  }
  // console.log(weeks[0]);
  return weeks;
}








function findMatches(meal_date, startWeeks) {
  console.log(meal_date);
  console.log(startWeeks);
  return startWeeks.map(week => meal_date.includes(week));
}


function findMatches2(meal_date, startWeeks) {
  console.log(meal_date);
  console.log(startWeeks);
  console.log(startWeeks.map(week => meal_date.includes(week)));
  return startWeeks.map(week => meal_date.includes(week));
}

const Page = ({ data, objective, label, maxMin, area, name, weightDataObj }) => {
  console.log('xxx', weightDataObj);
  // data=all_weights
  // const filtred = removeDuplicateMeals(data)
  // console.log(filtred.length);
  const divRef = useRef(null);
  const captureScreenshot = () => {
    if (!divRef.current) return;
    html2canvas(divRef.current).then(canvas => {
      // Convert canvas to image and do something with it
      const screenshotUrl = canvas.toDataURL();
      console.log(screenshotUrl); // URL representing the screenshot
      // Now you can use screenshotUrl to display, download, or process the screenshot
      // Create a link element
      const link = document.createElement('a');
      link.href = screenshotUrl;
      link.download = `poids_historisé_${name}.png`; // Set the download filename

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    });
  };
  // console.log('xxx', objective);
  const chartRef = useRef(null);
  const handleSaveAsImage = () => {
    if (chartRef && chartRef.current) {
      // Trigger the saveAsImage action
      chartRef.current.getEchartsInstance().saveAsImage({
        // Optional settings for the saved image
        name: 'my_chart_screenshot', // Specify the file name
        type: 'png', // Specify the image type (png/jpeg/svg)
        pixelRatio: 2, // Specify the pixel ratio for higher resolution (optional)
      });
    }
  };
   

  
  const seriesStartDay = data[0]?.meal_date;
  let last = data[data.length-1]?.meal_date;

  console.log(seriesStartDay,last);
  let listeOfWeeksOnX = getStartOfWeeksOfYearFromNow2v(seriesStartDay,last);
  
  console.log(listeOfWeeksOnX);




  const listeOfmeal_dates = data.map(datas => datas.meal_date);
  


  const weights = data.map(datas => datas.weight);



  console.log(weights);
  console.log("mm",findMatches(listeOfmeal_dates, listeOfWeeksOnX));





  const newWeightRow = applyChecker(weights, findMatches(listeOfmeal_dates, listeOfWeeksOnX));
  console.log(newWeightRow);
  const weeksNumber = data?.map(datas => datas.weekNumberAgenda);
 
  // const newData = newWeightRow.slice(0, findMatches(x, sWeek).length);

  let checkerTOStart = weightDataObj[0]?.goal;
  console.log("yyyy",weightDataObj);
  const xObj = weightDataObj?.map(datas => datas.meal_date || '2024-05-13');
  console.log(xObj);
  const objWeights = weightDataObj?.map(datas => datas.weight);
  // console.log(findMatches2(xObj, listeOfWeeksOnX));
  
  // // console.log(objWeights.length < weights.length);
  console.log(objWeights);
  // // console.log(objWeights);
  
  if (objWeights.length < weights.length) {
    for (let i = 0; i <= weights.length - objWeights.length; i++){
      // console.log();
      objWeights.push(objWeights[objWeights.length-1])
    }
  }
  console.log(objWeights,weights);
  // // const maches = findMatches(xObj, sWeek);

  const appliedChecker = applyChecker(objWeights, findMatches2(xObj, listeOfWeeksOnX));
  console.log(appliedChecker);
  const appliedCheckerWithoutNull = appliedChecker.map((value,i) => {
    if (!value) {
    return appliedChecker[i+1]||appliedChecker[i-1]||appliedChecker[i]
    } else {
      return value
  }
})
console.log(appliedCheckerWithoutNull);
  const objWeightsColor = applyColorWO(appliedCheckerWithoutNull);
  console.log('objWeightsColor', objWeightsColor)
 

  return (
    <div
      ref={divRef}
      style={{
        padding: 50,
        position: 'relative',
      }}>
      <div
        title="légende"
        
        style={{
          fontWeight: 500,
          display: 'flex',
          fontSize: 40,
          height:"50px",
          borderRadius: '5px',
          width: "180px",
          height: "auto",
          color: 'black',
          flexWrap:"wrap",
          border:"1px solid #0000001",
          background: '#faebd791',
          justifyContent: 'space-between',
          alignItems: 'center',
          
          // textDecoration: 'underline',
          cursor: 'auto',
          position: 'absolute',
          padding:"5px 10px",
          right: 20,
          top: -40,
        }}>
        {/* <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}> */}

          <CircleComponent color={'#ffa500'} text={"Poids de semaine"} />  
        <CircleComponent color={'#03AED2'} text={"Poids non renseigné"} />  
        <CircleComponent color={'#03AED2'} text={"Mode vacance"} />  
        <CircleComponent color={'#41B06E'} text={"Poids Objectif"} />  
        <CircleComponent color={'#FFA1F5'} text={"Poids de forme"} />  
    
    
        {/* </div> */}
        
      </div>



      {/* <span
        title="Capture d'écran"
        onClick={captureScreenshot}
        style={{
          fontWeight: 500,
          display: 'flex',
          fontSize: 40,
          borderRadius: '5px',
          color: 'white',
          background: 'orange',
          justifyContent: 'space-between',
          alignItems: 'center',
          textDecoration: 'underline',
          cursor: 'pointer',
          position: 'absolute',
          right: 50,
          top: -40,
        }}>
        <VerticalAlignBottomOutlined />{' '}
      </span> */}
      <ReactECharts
        ref={chartRef}
        notMerge={true}
        lazyUpdate={true}
        id="chart"
        style={{
          cursor:"pointer",
          height: '50vh',
          zoom: 1.25,
          // transform: 'scale(.9)',
          // transformOrigin: "20% 20%"
        }}
        option={
          // option
          optionW({
            weeks: listeOfWeeksOnX,
            weights:newWeightRow,
        
            label,
            maxMin,
            area,
            weeksNumber,
            colorWeight: findMatches(listeOfmeal_dates, listeOfWeeksOnX),
            objWeights:appliedCheckerWithoutNull,
            objWeightsColor,
            checkerFormOrObj:checkerTOStart,
            // findMatches(listeOfmeal_dates, listeOfWeeksOnX),
            // [],
            // weightDataObj,
            // objWeights,
            // appliedChecker,
            // objWeights,
            // [],
            // [],
            // appliedCheckerColor,
            // checkerTOStart,
            // [],
          })
          // optionW(sWeek, w, objective ? objective : 50, label, maxMin, area,c)
        }
      />
    </div>
  );
};

export default Page;

// const options = {
//   grid: { top: 8, right: 8, bottom: 24, left: 36 },
//   xAxis: {
//     type: 'category',
//     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//   },
//   yAxis: {
//     type: 'value',
//   },
//   series: [
//     {
//       data: [820, 932, 901, 934, 1290, 1330, 1320],
//       type: 'line',
//       smooth: true,
//     },
//   ],
//   tooltip: {
//     trigger: 'axis',
//   },
// };
