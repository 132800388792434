import React, {Fragment, useEffect, useState, useRef, useContext} from 'react';
import {MySpinner, Input, DropDown} from 'components/atoms';
import {Input as AntInput, Button as ButtonAnt, Checkbox} from 'antd';
import AppHeader from 'Layout/AppHeader/';
import AppSidebar from 'Layout/AppSidebar/';
import AppFooter from 'Layout/AppFooter/';
import app from '../../shared/appointment.png';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
  UserMenu,
  UserProducts,
  PushNotification,
  Appointments,
  UserSales,
} from 'components/organisms';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line, Bar} from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import {ToastContainer, toast} from 'react-toastify';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin,
  zoomPlugin,
);

import {
  Row,
  Col,
  Button,
  Form,
  CardBody,
  Card,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
} from 'reactstrap';
import {useParams} from 'react-router-dom';
import CenterFormApi from 'services/api/CenterFormApi';
import {
  bmiCalculator,
  idealWeight,
  idealWeightLB,
} from 'services/helpers/general';
import history from 'services/history';
import moment from 'moment';
import {AuthContext} from 'services';
import {EditOutlined, EyeOutlined, LoadingOutlined} from '@ant-design/icons';
import Page from 'Layout/AppSidebar/charts';
import {
  all,
  dataWeights,
  diffDate,
  fakeData,
  showToast,
  userType,
  userTypeString,
} from 'shared/extra';
import InputAnt from 'components/atoms/AntdInput';
import {Redirect} from 'react-router-dom/cjs/react-router-dom.min';

const genders = [
  {id: 'male', name: 'Homme'},
  {id: 'female', name: 'Femme'},
  {id: 'transgender', name: 'Transgenre'},
];

const activities = [
  {id: 'sedentary', name: 'Sédentaire'},
  {id: 'not very active', name: 'Peu actif'},
  {id: 'active', name: 'Actif'},
  {id: 'very active', name: 'Très actif'},
];

const goals = [
  {id: 'losing weight', name: 'Perdre du poids'},
  // {id: 'increasing weight', name: 'Prise de poids'},
  {
    id: 'improving health',
    name: 'Amélioration de l’hygiène alimentaire et de sa santé',
  },
];
const setGoal = goal => {
  switch (goal) {
    case 'losing weight':
      return 'Perdre du poids';
    case 'improving health':
      return 'Amélioration de l’hygiène alimentaire et de sa santé';
    default:
      return '';
  }
};

const setBorderColor2 = index => {
  console.log(dataPoints);

  // Your condition to change color between points
  if (index > 0 && point < 2) {
    return 'red'; // Change color to red if both current and previous points are greater than 70
  } else {
    return 'rgba(75,192,192,1)'; // Default color
  }
};

const setBorderColor = dataPoints => {
  console.log(dataPoints);
  return dataPoints.map((point, index) => {
    // Your condition to change color between points
    if (index > 0 && point > 2) {
      return 'red'; // Change color to red if both current and previous points are greater than 70
    } else {
      return 'rgba(75,192,192,1)'; // Default color
    }
  });
};

const StandardUsers = () => {
  const defaultLocale = moment.locale();

  const CenterFormContext = useContext(AuthContext);
  const {user_data} = CenterFormContext.state;

  // if(user_data.type!='admin' &&)

  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataMDP, setloadingDataMDP] = useState(false);
  const [newWO, setnewWO] = useState(0);
  const [userData, setUserData] = useState({});
  const [showDiagnostique, setShowDiagnostique] = useState(false);
  const [actionVacation, setactionVacation] = useState(false);

  const [showUserData, setShowUserData] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const {id} = useParams();
  const [date, setdate] = useState('YYYY-MM-DD');
  const [photos, setPhotos] = useState([]);
  const [progressValue, setProgressValue] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [showPushNotification, setShowPushNotification] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showColSelect, setShowColSelect] = useState(false);
  const [showCancelForfait, setShowCancelForfait] = useState(false);
  const [ModeVacation, setShowModeVacation] = useState(false);
  const [showPayForfait, setShowPayForfait] = useState(false);
  const [allWeights, setAllWeights] = useState([]);
  const [allObjWeights, setAllObjWeights] = useState([]);

  const hiddenRef = useRef();
  const [filterCheck, setFilterCheck] = useState(false);
  const [forForfait, setForForfait] = useState([]);
  const [selectedForfait, setSelectedForfait] = useState(1);
  const [ArrayOforfait, setArrayOforfait] = useState([]);
  const [ArrayOforfaitPrice, setArrayOforfaitPrice] = useState([]);
  const [codePromo, setCodePromo] = useState('');
  const [codePromoError, setCodePromoError] = useState(false);
  const [banner, setbanner] = useState(false);
  const [unbanner, setUnbanner] = useState(false);
  const [LoadingOnValid, setLoadingOnValid] = useState(false);
  const [payUrl, setPayUrl] = useState('');
  const [loadingDataUrl, setloadingDataUrl] = useState(false);
  const [onValid, setOnValid] = useState(true);
  const [objectif, setObjectif] = useState('');
  const [showEyeModal, setShowEyeModal] = useState(false);
  const [showDayVacation, setShowDayVacation] = useState(false);
  const [paymentListe, setPaymentListe] = useState([]);
  const [objectiveWeight, setObjectiveWeight] = useState('');
  const [days, setDays] = useState(0);
  const [deactivateVacation, setDeactivateVacation] = useState(false);
  const [maxMin, setMaxMin] = useState(false);
  const [label, setLabel] = useState(false);
  const [area, setarea] = useState(false);
  const [copied, setCopied] = useState(false);
  // const [allowColl, setAllowColl] = useState(false);

  const [changePassword, setChangePassword] = useState({
    password: '',
    changePassword: '',
  });

  const copyToClipboard = () => {
    const textToCopy = payUrl;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Reset the 'copied' state after 2 seconds
      })
      .catch(error => {
        console.error('Could not copy text: ', error);
      });
  };

  // console.log(allowColl);
  // console.log('userada', userData);
  const handelvacation = async () => {
    try {
      const response = await CenterFormApi.post(
        `/api/back-office/setVacationMode`,
        {
          userId: userData.id,
          days: Number(days),
        },
      );
      if (response.data) {
        setShowModeVacation(false);
        setShowDayVacation(false);
        getUserData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handelCancelvacation = async () => {
    console.log('here');
    try {
      const response = await CenterFormApi.post(
        `/api/back-office/cancelVacationMode`,
        {
          userId: userData.id,
        },
      );
      if (response.data) {
        setShowModeVacation(false);
        setShowDayVacation(false);
        getUserData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onBanUser = async id => {
    try {
      const response = await CenterFormApi.put(`/updateUserData`, {
        id: Number(id),
        state: 'block',
      });
      console.log('responseresponseresponse', response);
      if (response.data) {
        setShowDeleteModal(false);
      }

      setbanner(true);
      document.location.reload();
    } catch (e) {}
  };

  const onUnBanUser = async id => {
    try {
      const response = await CenterFormApi.put(`/updateUserData`, {
        id: Number(id),
        state: 'active',
      });
      console.log('responseresponseresponse', response);
      if (response.data) {
        setShowDeleteModal(false);
        document.location.reload();
      }
    } catch (e) {
      setbanner(true);
    }
  };

  const m = useRef(null);
  const m3 = useRef(null);
  const m6 = useRef(null);
  const m12 = useRef(null);

  // console.log("paymentListe ",(paymentListe[0]?.stripe_data.data.object.period_start));
  // console.log("paymentListe ",(paymentListe[0]?.stripe_data.data.object));
  // console.log(
  //   'ppppppp',
  //   ArrayOforfait[selectedForfait - 1],
  //   ArrayOforfaitPrice[selectedForfait - 1],
  // );

  const getWeights = async () => {
    try {
      const res = await CenterFormApi.get(
        `api/mobile/fetchWeight?_id=${userData.id}&from_date=2023-12-03T00:00:00&to_date=2024-12-03T00:00:00`,
      );
      if (res.data.statusCode == 200) {
        console.log('www :', res.data);
        // setShowEyeModal(true);
        // setPaymentListe(res.data.data);
      } else {
        // showToast('error', 'Désolé, Une erreur se produit au serveur !', 1000);
      }
    } catch (e) {
      // showToast('error', 'Désolé, Une erreur se produit', 1000);
    }
  };

  const getPayment = async () => {
    try {
      const res = await CenterFormApi.get(
        `api/back-office/fetch-user-payment?userId=${userData.id}`,
      );
      if (res.data.statusCode == 200) {
        setShowEyeModal(true);
        setPaymentListe(res.data.data);
      } else {
        showToast('error', 'Désolé, Une erreur se produit au serveur !', 1000);
      }
    } catch (e) {
      showToast('error', 'Désolé, Une erreur se produit', 1000);
    }
  };

  const handelAnnulerForfait = () => {
    console.log('todo');
    setShowCancelForfait(false);
  };

  const handelPayForfait = async () => {
    setloadingDataUrl(true);
    console.log('todo');
    const newForfait = {
      userId: +userData.id,
      forfait_duration: ArrayOforfait[selectedForfait - 1],
      forfait: true,
      subIdPrice: ArrayOforfaitPrice[selectedForfait - 1],
      coupon: codePromo,
    };

    const createSubandFor = await CenterFormApi.post(
      `/api/back-office/create-subscription`,
      {...newForfait},
    );
    console.log(createSubandFor);

    if (createSubandFor.status == 400) {
      setCodePromoError(true);
      setloadingDataUrl(false);
    } else if (createSubandFor.data) {
      //  alert("Votre abonnement a été crée avec succés url : ");
      createSubandFor.data.data.latest_invoice.hosted_invoice_ur;
      setPayUrl(createSubandFor.data.data.latest_invoice.hosted_invoice_url);
      setloadingDataUrl(false);

      // setShowPayForfait(false);
    }
  };

  const getForfaitIfNeeded = async () => {
    const responseSubandFor = await CenterFormApi.get(
      `/api/back-office/fetch-subscription-and-forfaits`,
    );

    // console.log('fdfdfd', responseSubandFor.data.data);
    setForForfait(responseSubandFor.data.data);
    setArrayOforfait([1, 3, 6, 12]);
    setArrayOforfaitPrice([
      responseSubandFor.data.data.forfait_1month,
      responseSubandFor.data.data.forfait_3month,
      responseSubandFor.data.data.forfait_6month,
      responseSubandFor.data.data.forfait_12month,
    ]);
    setShowPayForfait(true);
  };

  const deleteUser = async e => {
    e.preventDefault();
    setLoadingData(true);
    try {
      const res = await CenterFormApi.delete('/deleteUser', {
        data: {
          id,
        },
      });
      history.push(`/${userData.type}Users/`);
    } catch (e) {}
    setLoadingData(false);
  };

  const handleUpload = async e => {
    const files = e.target.files;
    setPhotos(photos => [...photos, ...files]);
    if (files.length) {
      setShowProgress(true);
      const config = {
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total / files.length,
          );
          setProgressValue(percentCompleted);
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };
      for (let i = 0; i < files.length; i++) {
        const data = new FormData();
        data.append('userId', id);
        data.append('file', files[i]);
        await CenterFormApi.put('/uploadPhoto', data, config)
          .then(async res => {
            if (i === files.length - 1) {
              setShowProgress(false);
              toast.success('Photos téléchargés avec succès', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: '',
                icon: '🚀',
              });
            }
          })
          .catch(err => {
            console.log(err);
            toast.error(`Erreur de téléchargement de photo ${files[i].name}`, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: '',
              icon: '🚀',
            });
            if (i === files.length - 1) {
              setShowProgress(false);
            }
          });
      }
      await getUserData();
      setPhotos([]);
    }
  };

  const handleClickForUpload = () => {
    hiddenRef.current.click();
  };
  // console.log("allWeights :",allWeights);
  // console.log("allWeightsO :",allObjWeights);
  const getUserData = async () => {
    setLoadingData(true);
    try {
      const resWeight = await CenterFormApi.get(
        `api/backoffice/fetchWeight?_id=${id}&from_date=2023-12-03T00:00:00&to_date=2024-12-03T00:00:00`,
      );
      console.log('www  length:', resWeight.data.data.all_weights.length);
      if (resWeight) {
        setAllWeights(
          resWeight.data.data.all_weights
            .filter(d => d.weightRef == true)
            .reverse(),
        );
        // console.log(resWeight.data.data.objective_weight);
        setAllObjWeights(resWeight.data.data.objective_Weights);
        // setShowEyeModal(true);
        // setPaymentListe(res.data.data);
      }
      const res = await CenterFormApi.get(`/userData/${id}`);

      setUserData(res.data);
      setactionVacation(res.data.vacation);
      setObjectiveWeight(res.data.objectiveWeight);
      setObjectif(setGoal(res.data.goal));
      setbanner(res.data.state == 'active' ? true : false);
      setUnbanner(res.data.state == 'active' ? false : true);
    } catch (e) {}
    setLoadingData(false);
  };
  const getUserDataForColl = async () => {
    setLoadingData(true);
    try {
      const res = await CenterFormApi.get(`/userData/${id}`);

      const resForColl = await CenterFormApi.get(
        `/fetchCollaboratorClients?collaboratorId=${
          user_data._id || user_data.id
        }&page=1&limit=5000&sort={%22_id%22:-1}&search=${res.data.email}`,
      );
      console.log('fffffffffff', resForColl.data.recordsTotal);
      if (resForColl.data.recordsTotal >= 1 || user_data.type == 'admin') {
        setUserData(res.data);
        setactionVacation(res.data.vacation);
        setObjectiveWeight(res.data.objectiveWeight);
        setObjectif(setGoal(res.data.goal));
        setbanner(res.data.state == 'active' ? true : false);
        setUnbanner(res.data.state == 'active' ? false : true);
      } else {
        // history.replace('/')
        window.location.replace(`/collaborateurs/${user_data?._id}`);
      }
      // console.log("res ",res);
      // `/collaborateurs/${user_data?._id}`
    } catch (e) {}
    setLoadingData(false);
  };

  const changeObj = item => {
    setObjectif(item);
    setOnValid(false);
  };
  const onUpdateGoals = async () => {
    try {
      setLoadingOnValid(true);
      const res = await CenterFormApi.put('/updateUserData', {
        ...userData,
        // _id:userData.id,
        goal: objectif?.id,
        objectiveWeight: newWO,
        from_change_objective:true
      });

      if (res.data.statusCode != 500) {
        showToast('success', 'Données enregistrées', 1000);
      } else {
        showToast(
          'error',
          "Erreur, aucune modifications n'ont été apportées",
          1000,
        );
      }
      setLoadingOnValid(false);
      setOnValid(true);
    } catch (e) {
      showToast(
        'error',
        "Erreur, aucune modifications n'ont été apportées",
        1000,
      );
    }
  };

  const deleteUserPhoto = async index => {
    setLoadingData(true);
    try {
      const res = await CenterFormApi.delete(`/deleteUserPhoto`, {
        data: {
          id,
          index,
        },
      });
      setUserData({
        ...userData,
        photos: userData.photos.filter(
          (item, photoIndex) => index !== photoIndex,
        ),
      });
    } catch (e) {}
    setLoadingData(false);
  };
  // console.log(userData);
  const labels = userData.userWeights
    ? userData.userWeights.map(item => item.week)
    : [];
  let borderColor = [];

  borderColor = userData?.userWeights?.map((point, index) => {
    // console.log(point.weight);
    // Your condition to change color between points
    if (point.weight < 75) {
      return borderColor.push('red'); // Change color to red if both current and previous points are greater than 70
    } else {
      return borderColor.push('green'); // Default color
    }
  });

  const skipped = (ctx, value) => {
    // console.log(ctx.p0.raw);
    // console.log(ctx.p1.raw);
    return ctx.p0.skip || ctx.p1.skip ? value : undefined;
  };
  const down = (ctx, value) =>
    ctx.p0.parsed.y > ctx.p1.parsed.y ? value : 'orange';

  const data = {
    labels,
    datasets: [
      {
        label: 'Cubic interpolation (monotone)',
        data: userData.userWeights
          ? userData.userWeights.map(item => item.weight)
          : [],
        segment: {
          borderColor: ctx => skipped(ctx, 'orange') || down(ctx, '#9ADE7B'),
          // borderDash: ctx => skipped(ctx, [6, 6]),
        },
        // backgroundColor: borderColor ,
        // borderColor: borderColor,
        fill: false,
        cubicInterpolationMode: 'monotone',
        pointHoverBorderWidth: 5,
        pointHoverRadius: 10,
      },
    ],
    // spanGaps: true
  };
  // data.datasets[0].backgroundColor = setBorderColor(data.datasets[0].data);

  const xMin = 1;
  const xMax = userData.userWeights
    ? userData.userWeights[userData.userWeights.length - 1]?.week
    : 0;
  const idealUserWeight =
    userData.objectiveWeightUnit === 'LB'
      ? idealWeightLB({
          gender: userData.gender,
          height: userData.height,
          heightUnit: userData.heightUnit,
        })
      : idealWeight({
          gender: userData.gender,
          height: userData.height,
          heightUnit: userData.heightUnit,
        });

  const options = {
    scales: {
      y: {
        position: 'right',
        ticks: {
          color: '#9FA2B4',
        },
      },
      xAxis: {
        ticks: {
          color: '#9FA2B4',
        },
      },
    },
    // plugins: {
    //   legend: false,
    //   autocolors: true,
    // },
  };
  const options2 = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: 'Poids historié avec fitlre IMC',
      },
      legend: false,
      autocolors: false,
      annotation: {
        annotations: true
          ? {
              box1: {
                drawTime: 'afterDatasetsDraw',
                // Indicates the type of annotation
                type: 'box',
                xMin,
                xMax,
                yMax: 0,
                backgroundColor: 'rgba(255, 99, 132, 0.25)',
              },
              box2: {
                drawTime: 'afterDatasetsDraw',
                // Indicates the type of annotation
                type: 'box',
                xMin,
                xMax,
                yMin: idealUserWeight - 10,
                yMax: idealUserWeight - 5,
                backgroundColor: 'rgba(255, 214, 69, 0.39)',
              },
              box3: {
                drawTime: 'afterDatasetsDraw',
                // Indicates the type of annotation
                type: 'box',
                xMin,
                xMax,
                yMin: idealUserWeight - 5,
                yMax: idealUserWeight + 5,
                backgroundColor: 'rgba(127, 250, 69, 0.15)',
                padding: 5,
              },
              box4: {
                drawTime: 'afterDatasetsDraw',
                // Indicates the type of annotation
                type: 'box',
                xMin,
                xMax,
                yMin: idealUserWeight + 5,
                yMax: idealUserWeight + 10,
                backgroundColor: 'rgba(255, 214, 69, 0.39)',
              },
            }
          : null,
      },
      tooltip: {
        // label: 'Cubic interpolation (monotone)',
        backgroundColor: 'rgba(250, 69, 69, 1)',
        titleColor: '#252733',
        // borderWidth: 1,
        // borderColor: '#DFE0EB',
        // xAlign: 'center',
        // yAlign: 'bottom',
        // padding: 10,
        callbacks: {
          // label: context => '',
          title: context => {
            return 'true';
            context[0].formattedValue +
              '' +
              userData.objectiveWeightUnit +
              ' / ' +
              bmiCalculator({
                weight: context[0].formattedValue,
                height: userData.height,
                weightUnit: userData.objectiveWeightUnit,
                heightUnit: userData.heightUnit,
              });
          },
        },
      },
    },
  };
  const options3 = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: 'Poids historié',
      },
      legend: false,
      autocolors: false,

      tooltip: {
        // devicePixelRatio: 150,
        backgroundColor: 'rgba(250, 69, 69, 1)',
        titleColor: '#252733',
        // borderWidth: 5,
        borderColor: '#DFE0EB',
        xAlign: 'center',
        yAlign: 'right',
        padding: 10,
        margin: 150,
        callbacks: {
          label: context => '',
          title: context => {
            return (
              context[0].formattedValue +
              '' +
              userData.objectiveWeightUnit +
              ' / ' +
              bmiCalculator({
                weight: context[0].formattedValue,
                height: userData.height,
                weightUnit: userData.objectiveWeightUnit,
                heightUnit: userData.heightUnit,
              })
            );
          },
        },
      },
    },
  };

  const [collaborators, setCollaborators] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState();
  const [showSwitchToPremium, setShowSwitchToPremium] = useState(false);
  const [showSwitchToPhysical, setShowSwitchToPhysical] = useState(false);
  const [showSwitchToStandard, setShowSwitchToStandard] = useState(false);

  const changePasswordFunc = async e => {
    setloadingDataMDP(true);
    e.preventDefault();
    try {
      // setLoadingData(true);
      const res = await CenterFormApi.put('/changeUserPassword', {
        userId: id,
        password: changePassword.password,
        confirmPassword: changePassword.confirmPassword,
      });
      setChangePassword({
        password: '',
        confirmPassword: '',
      });
      setShowPasswordChange(false);
    } catch (e) {
      // console.log(e);
      setloadingDataMDP(false);
    }
    setloadingDataMDP(false);
  };

  const getCollaborators = async () => {
    setLoadingData(true);
    try {
      const res = await CenterFormApi.get('/getCollaborators');
      setCollaborators(res.data);
      setSelectedCollaborator(userData.collaborator);
    } catch (e) {}
    setLoadingData(false);
  };

  const assignCollaborator = async e => {
    e.preventDefault();
    setLoadingData(true);
    try {
      const res = await CenterFormApi.put('/assignCollaborator', {
        collaboratorId: selectedCollaborator.id,
        userId: id,
      });
      setShowColSelect(false);
      getUserData();
    } catch (e) {}
    setLoadingData(false);
  };

  useEffect(() => {
    getUserData();
  }, [id, activeTab]);

  useEffect(() => {
    getUserDataForColl();
  }, []);

  const handleCheck = e => {
    setFilterCheck(e.target.checked);
  };

  const switchUserType = async e => {
    e.preventDefault();
    setLoadingData(true);
    try {
      const type = showSwitchToPhysical
        ? 'physical'
        : showSwitchToPremium
        ? 'premium'
        : 'standard';
      await CenterFormApi.put('/switchUserType', {
        userId: id,
        customDate: date,
        type,
      });
      setShowSwitchToPhysical(false);
      setShowSwitchToPremium(false);
      setShowSwitchToStandard(false);
      console.log(type);
      if (type == 'physical') {
        history.push('/mes-demandes');
      } else if (type == 'premium') {
        history.push(`/physicalUsers/${id}`);
      } else {
        history.push(`/standardUsers/${id}`);
      }
      //  window.location.reload()
    } catch (e) {}
    setLoadingData(false);
  };
  // console.log('vvv', objectiveWeight);
  // console.log(userData.);

  let final = [];
  for (let i = 0; i < all.length; i++) {
    final[i] = all[all.length - 1 - i];
  }
  console.log('finalfinalfinal', final);
  // all.filter(d => d.weightRef == true)

  return (
    <Fragment>
      <AppHeader
        profileImg={userData.profile_image}
        activeTab={activeTab}
        tabClick={setActiveTab}
        userData={userData}
        userProfile
        pageTitle="Profile"
        setShowEyeModal={getPayment}
      />
      <div
        style={{
          background: activeTab == 1 ? '#ff4d4f0d' : '#f5f8f9',
          padding: '0 25px',
          borderRadius: '5px',
          paddingTop: '50px',
          boxShadow: '0px 1px 5.5px #0000001f',
        }}
        // className="app-main centerform-main"
      >
        {/* <AppSidebar /> */}
        <div
        // className="app-main__outer"
        >
          <div
          // className="app-main__inner"
          >
            {/* <MySpinner active={loadingData} /> */}
            {/* <ToastContainer /> */}
            <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <TabContent activeTab={activeTab}>
                <div className="pageTitle">
                  {unbanner && (
                    <span
                      style={{
                        fontSize: '15px',
                        fontWeight: '500',
                        fontStyle: 'italic',
                        padding: '3px 5px',
                        backgroundColor: 'red',
                        borderRadius: '5px',
                        color: 'white',
                      }}>
                      {'Cette utilisateur est bloqué'}
                    </span>
                  )}
                  {banner && (
                    <p style={{paddingTop: '5px'}}>
                      {' '}
                      Compte client {userTypeString(userData?.type)}
                    </p>
                  )}
                </div>
                <TabPane tabId={0}>
                  <Row
                    style={
                      {
                        // display: 'flex',
                        // border:"1px solid red"
                      }
                    }
                    className="profile-row profile-firstRow">
                    {true ? (
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          // border: '1px solid red',
                        }}
                        sm={12}>
                        <div
                          style={{
                            display: 'flex',
                            // border:"1px solid red"
                          }}>
                          {userData.type == 'physical' ? (
                            <Button
                              onClick={() => {
                                getCollaborators();
                                setShowColSelect(true);
                              }}
                              style={{width: '250px'}}
                              className="profile-btn-white"

                              // className="btn-wide  mb-2 mr-2"
                              // size="lg"
                            >
                              {userData.collaborator
                                ? `Collaborateur: ${userData.collaborator.name}`
                                : 'Sélectionner un collaborateur'}
                            </Button>
                          ) : null}
                          {/* {
                            <Button
                              className="profile-btn-white"
                              onClick={() => setShowPushNotification(true)}
                              // className="btn-wide black-button mb-2 mr-2"
                              // size="lg"
                            >
                              Envoyer notification
                            </Button>
                          } */}
                          {/* <a href={`mailto:${userData.email}`}>
                            <Button
                              className='profile-btn-white'
                                                       


                              // className="btn-wide blue-button mb-2 mr-2"
                              // size="lg"
                            >
                              Envoyer Email
                            </Button>
                          </a> */}
                        </div>
                      </Col>
                    ) : null}
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        // alignItems:"center",
                        // flexWrap:"nowrap",
                        // border: '1px solid red',
                      }}
                      sm={12}>
                      {
                        <Button
                          className="profile-btn-white"
                          onClick={() => setShowPushNotification(true)}
                          // className="btn-wide black-button mb-2 mr-2"
                          // size="lg"
                        >
                          Envoyer notification
                        </Button>
                      }

                      {userData.type != 'premium' ? (
                        <Button
                          onClick={() => setShowSwitchToPremium(true)}
                          className="profile-btn-orange"
                          // className="btn-wide black-button mb-2 mr-2"
                          // size="lg"
                        >
                          Passer au physique
                        </Button>
                      ) : (
                        ''
                      )}
                      {userData.type != 'physical' &&
                      userData.type != 'standard' ? (
                        <Button
                          onClick={() => setShowSwitchToPhysical(true)}
                          className="profile-btn-orange"
                          // className="btn-wide black-button mb-2 mr-2"
                          size="lg">
                          Passer au Semi-physique
                        </Button>
                      ) : (
                        ''
                      )}
                      {userData.type !== 'standard' && (
                        <Button
                          onClick={() => setShowSwitchToStandard(true)}
                          className="profile-btn-white"

                          // style={{backgroundColor:"#ef6c00"}}

                          // className="btn-wide black-button mb-2 mr-2"
                          // size="lg"
                        >
                          Passer au digital
                        </Button>
                      )}
                      <Button
                        onClick={() => setShowPasswordChange(true)}
                        // style={{marginLeft: 'auto'}}
                        className="profile-btn-white"
                        // className='profile-btn-orange'
                        // style={{backgroundColor:"#ef6c00"}}

                        // className="btn-wide center-form-save-btn mb-2 mr-2"
                        size="lg">
                        Changer mot de passe
                      </Button>
                      {userData.type != 'standard' && (
                        <Button
                          onClick={() =>
                            history.push('/messagerie?id=' + userData.id)
                          }
                          // style={{marginLeft: 'auto'}}
                          className="profile-btn-orange"
                          // className='profile-btn-orange'
                          // style={{backgroundColor:"#ef6c00"}}

                          // className="btn-wide center-form-save-btn mb-2 mr-2"
                          size="lg">
                          Messageries
                        </Button>
                      )}
                      {/* {user_data.type === 'admin' ? (
                        <Button
                          onClick={() => setShowDeleteModal(true)}
                          className='profile-btn-orange'
                        style={{backgroundColor:"#ef6c00"}}
                          // className="btn-wide center-form-save-btn mb-2 mr-2"
                          size="lg">
                          Supprimer utilisateur
                        </Button>
                      ) : ""} */}
                      {/* {userData.type !== 'premium' ? (
                        <Button
                          onClick={() => setShowSwitchToPremium(true)}
                          className='profile-btn-white'
                         
                          // className='profile-btn-orange'
                          // style={{backgroundColor:"#87C4FF"}}
                          // className="btn-wide black-button mb-2 mr-2"
                          // size="lg"
                        >
                          Passer au premium
                        </Button>
                        
                      ) : ""} */}
                      {/* {userData.type !== 'physical' ? (
                        <Button
                          onClick={() => setShowSwitchToPhysical(true)}
                          className='profile-btn-white'
                          // className='profile-btn-orange'
                          // style={{backgroundColor:"#9AD0C2"}}
                          // className="btn-wide black-button mb-2 mr-2"
                          size="lg">
                          Passer au physique
                        </Button>
                      ) : ""} */}
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        // alignItems:"center",
                        // flexWrap:"nowrap",
                        // border: '1px solid red',
                      }}
                      sm={12}>
                      {user_data.type === 'admin' && banner ? (
                        <Button
                          onClick={() => {
                            setShowDeleteModal(true);
                          }}
                          className="profile-btn-red"
                          // className="btn-wide center-form-save-btn mb-2 mr-2"
                          size="lg">
                          Bloquer utilisateur
                        </Button>
                      ) : user_data.type === 'admin' && unbanner ? (
                        <Button
                          onClick={() => {
                            setShowDeleteModal(true);
                          }}
                          className="profile-btn-green"
                          // style={{backgroundColor:"green"}}
                          // className="btn-wide center-form-save-btn mb-2 mr-2"
                          size="lg">
                          Débloquer utilisateur
                        </Button>
                      ) : (
                        ''
                      )}
                      {user_data.type === 'admin' ? (
                        <Button
                          onClick={() => {
                            setbanner(false);
                            setShowDeleteModal(true);
                          }}
                          className="profile-btn-red"
                          // className="btn-wide center-form-save-btn mb-2 mr-2"
                          size="lg">
                          Supprimer utilisateur
                        </Button>
                      ) : (
                        ''
                      )}
                      {userData.type != 'standard' ||
                      userData.accountType != 'DIGITAL' ? (
                        !userData.vacation ? (
                          <Button
                            onClick={() => {
                              // setbanner(false);
                              (userData?.mySubscription?.status == 'Trial' &&
                                userData.type != 'premium') ||
                              (!userData?.mySubscription &&
                                userData.type != 'premium') ||
                              (diffDate(
                                userData.mySubscription?.expiration_date,
                                new Date(),
                              ) <= 0 &&
                                userData.type != 'premium')
                                ? null
                                : setShowModeVacation(true);
                            }}
                            className={
                              (userData?.mySubscription?.status == 'Trial' &&
                                userData.type != 'premium') ||
                              (!userData?.mySubscription &&
                                userData.type != 'premium') ||
                              (diffDate(
                                userData.mySubscription?.expiration_date,
                                new Date(),
                              ) <= 0 &&
                                userData.type != 'premium')
                                ? 'profile-btn-block'
                                : 'profile-btn-green'
                            }
                            // className="btn-wide center-form-save-btn mb-2 mr-2"
                            size="lg">
                            Activer mode Vacances
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setDeactivateVacation(true);
                              setShowModeVacation(true);
                            }}
                            className="profile-btn-red"
                            // className="btn-wide center-form-save-btn mb-2 mr-2"
                            size="lg">
                            Déactiver mode Vacances
                          </Button>
                        )
                      ) : (
                        ''
                      )}
                    </Col>

                    <Col lg={userData?.accountType != 'DIGITAL' ? 10 : 12}>
                      <Card className="card-container">
                        <CardBody>
                          <div className="card-header">
                            <label className="card-label">Informations</label>
                            {userData.accountType != 'DIGITAL' && (
                              <button
                                className="view-all-btn"
                                style={{marginRight: '10px'}}
                                onClick={() => setShowUserData(true)}>
                                Voir Plus <EyeOutlined />
                              </button>
                            )}
                          </div>
                          <ul className="user-info-container">
                            <li>
                              <label>En vacance</label>
                              <p>{userData.vacation ? 'Oui' : 'Non'}</p>
                            </li>
                            <li>
                              <label>Email</label>
                              <p>{userData.email}</p>
                            </li>
                            <li>
                              <label>Téléphone</label>
                              <p>{userData.phoneNumber}</p>
                            </li>
                            <li>
                              <label>Adresse, Code Postale</label>
                              <p>
                                {userData.address}
                                {userData.address ? ',' : ''} {userData.zipCode}
                              </p>
                            </li>
                            <li>
                              <label>Ville, Pays</label>
                              <p>
                                {userData.city ? userData.city : ''}{' '}
                                {userData.country ? userData.country : '-'}
                              </p>
                            </li>
                            <li>
                              <label>Date d’inscription </label>
                              <p>
                                {moment(userData.createdAt).format(
                                  'DD-MM-YYYY',
                                )}
                              </p>
                            </li>
                            <li>
                              <label>Date de naissance</label>
                              <p>
                                {moment(userData.birthDay).format('DD/MM/YYYY')}
                              </p>
                            </li>
                            <li>
                              <label
                                title="Envoi automatique des fichiers de régime par email lors de l'affectation."
                                style={{fontWeight: 700, color: 'orange'}}>
                                Reçoi les régime par email automatiquement
                              </label>
                              <p>{userData?.mailing == true ? 'Oui' : 'Non'}</p>
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col
                      style={{
                        margin: '10px 0',
                        opacity: 0.5,
                        // padding:"100px",
                        display: 'flex',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        backgroundColor: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        border: '1px dashed black',
                        // height:"200px"
                      }}
                      lg={2}
                      onClick={() => {
                        user_data.type == 'admin'
                          ? history.push(
                              `/calendar?from=${user_data.type}&to=${userData.id}`,
                            )
                          : history.push(
                              `/calendar?from=coll&to=${userData.id}&id=${user_data._id}`,
                            );
                      }}>
                      <img src={app} style={{width: 100}}></img>
                      <p>Prendre un rendez-vous</p>
                      {/* {userData?.accountType != 'DIGITAL' && (
                        <Appointments from={'profile'} userData={userData} />
                      )} */}
                    </Col>
                    <Col lg={12}>
                      <Card className="card-container">
                        <CardBody className="user-details-card">
                          <Row>
                            {/* <Col sm={8}>
                              <Row>
                                <Col sm={12}>
                                  <label className="date-label">
                                    En date du{' '}
                                    {userData.userWeights?.length
                                      ? moment(
                                          userData.userWeights[
                                            userData.userWeights.length - 1
                                          ].createdAt,
                                        ).format('DD/MM/YYYY à HH[h]mm')
                                      : null}
                                  </label>
                                  <div className="filter-button">
                                    <input
                                      id="filter-check"
                                      type="checkbox"
                                      checked={filterCheck}
                                      onChange={handleCheck}
                                    />
                                    <label for="filter-check">Filtre IMC</label>
                                  </div>
                                </Col>
                                <Col sm={10}>
                                  <Line
                                    style={{
                                      zoom: 1.25,
                                      marginRight: '50px',
                                      // transform: 'scale(.9)',
                                      // transformOrigin: "20% 20%"
                                    }}
                                    options={options3}
                                    data={data}
                                  /> 
                                 </Col>
                              </Row>
                            </Col> */}
                            {/* <Col
                              style={{
                                // zoom: 1.25,
                                // marginLeft: '50px',
                                // marginTop: '100px',
                                // transform: 'scale(.9)',
                                // transformOrigin: "20% 20%"
                              }}
                              sm={3}> */}
                            <ul className="user-details">
                              <li>
                                <label>Age</label>
                                <p>{userData.age} ans</p>
                              </li>
                              <li>
                                <label>Taille</label>
                                <p>
                                  {userData.height} {userData.heightUnit}
                                </p>
                              </li>
                              <li>
                                <label>Poids et IMC initial</label>
                                <p style={{fontSize: 22}}>
                                  {userData.userWeights
                                    ? userData.userWeights[0]?.weight
                                    : null}
                                  {userData.userWeights
                                    ? userData.userWeights[0]?.weightUnit
                                    : null}{' '}
                                  /{' '}
                                  {userData.userWeights
                                    ? bmiCalculator({
                                        weight: userData.userWeights[0]?.weight,
                                        height: userData.height,
                                        weightUnit:
                                          userData.userWeights[0]?.weightUnit,
                                        heightUnit: userData.heightUnit,
                                      })
                                    : null}
                                </p>
                              </li>
                              <li>
                                <label>Poids et IMC Objectif</label>
                                <p style={{color: '#f53d14'}}>
                                  {userData.objectiveWeight}{' '}
                                  {userData.objectiveWeightUnit} /{' '}
                                  {userData.userWeights
                                    ? bmiCalculator({
                                        weight: userData.objectiveWeight,
                                        height: userData.height,
                                        weightUnit:
                                          userData.objectiveWeightUnit,
                                        heightUnit: userData.heightUnit,
                                      })
                                    : null}
                                </p>
                              </li>

                              <li>
                                <label>Poids et IMC idéal</label>
                                <p style={{fontSize: 22}}>
                                  {idealUserWeight}{' '}
                                  {userData.objectiveWeightUnit} /{' '}
                                  {bmiCalculator({
                                    weight: idealUserWeight,
                                    height: userData.height,
                                    weightUnit: 'KG',
                                    heightUnit: userData.heightUnit,
                                  })}
                                </p>
                              </li>
                            </ul>
                            {/* </Col> */}
                            {/* <div className="profile-seperator" /> */}

                            {/* <Col sm={3}> */}
                            <ul className="user-details user-details2">
                              <li>
                                <label>Nombre de semaine </label>
                                <p>{userData.currentWeek} semaines</p>
                              </li>
                              <li>
                                <label>Perte moyenne par semaine</label>
                                <p>
                                  {userData.averageLoss < 0 ? '+' : ''}
                                  {Math.abs(
                                    userData.averageLoss?.toFixed(2),
                                  )}{' '}
                                  {userData.objectiveWeightUnit}
                                </p>
                              </li>
                              <li>
                                <label>Perte totale</label>
                                <p>
                                  {userData.userWeights?.length &&
                                  userData.userWeights[0].weight -
                                    userData.userWeights[
                                      userData.userWeights.length - 1
                                    ].weight <
                                    0
                                    ? '+'
                                    : ''}
                                  {userData.userWeights?.length
                                    ? parseFloat(
                                        Math.abs(
                                          userData.userWeights[0].weight -
                                            userData.userWeights[
                                              userData.userWeights.length - 1
                                            ].weight,
                                        ).toFixed(2),
                                      )
                                    : 0}{' '}
                                  {userData.objectiveWeightUnit}
                                </p>
                              </li>
                              <li>
                                <label>Meilleure semaine</label>
                                <p>
                                  {userData.minValue > 0 ? '+' : '-'}
                                  {parseFloat(
                                    Math.abs(
                                      userData.minValue ? userData.minValue : 0,
                                    ).toFixed(2),
                                  )}{' '}
                                  {userData.objectiveWeightUnit} S
                                  {userData.minWeek}
                                </p>
                              </li>
                              <li>
                                <label>Pire semaine</label>
                                <p>
                                  {userData.maxValue > 0 ? '+' : '-'}
                                  {Math.abs(
                                    userData.maxValue
                                      ? parseFloat(userData.maxValue.toFixed(2))
                                      : 0,
                                  )}{' '}
                                  {userData.objectiveWeightUnit} S
                                  {userData.maxWeek}
                                </p>
                              </li>
                            </ul>
                            {/* </Col> */}

                            {/* <Col
                              style={{marginTop: '20px', width: 'fit-content'}}
                              lg={7}>
                              <div className="newWeightRow">
                                <label className="card-label">
                                  Evolution du poids
                                </label>
                              </div>
                              <label className="date-label">
                                En date du{' '}
                                {userData.userWeights?.length
                                  ? moment(
                                      userData.userWeights[
                                        userData.userWeights.length - 1
                                      ].createdAt,
                                    ).format('DD/MM/YYYY à HH[h]mm')
                                  : null}
                              </label>
                              <Bar
                                style={{
                                  zoom: 1.25,
                                  //  marginRight:"50px"
                                  // transform: 'scale(.9)',
                                  // transformOrigin: "20% 20%"
                                }}
                                options={options3}
                                data={data}
                              />
                            </Col> */}
                            {
                              // <Page
                              //   area={area}
                              //   maxMin={maxMin}
                              //   label={label}
                              //   objective={
                              //     objectiveWeight ? objectiveWeight : '0'
                              //   }
                              //   data={
                              //     // dataWeights.data.all_weights
                              //     userData?.userWeights
                              //       ? userData?.userWeights
                              //       : []
                              //   }
                              // />
                            }
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={1}>
                  <Row className="profile-row profile-firstRow">
                    {
                      <Col sm={12}>
                        <div
                          style={{
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}>
                          {userData.accountType == 'DIGITAL' &&
                            userData.mySubscription?.status == 'Active' && (
                              <Button className="profile-btn-orange" size="lg">
                                Annuler l'abonnement
                              </Button>
                            )}
                          {(userData.accountType == 'SEMIPHYSIQUE' &&
                            !userData.mySubscription?.status != 'Pending' &&
                            userData.mySubscription?.status != 'Active') ||
                          diffDate(
                            userData.mySubscription?.expiration_date,
                            new Date(),
                          ) < 0 ? (
                            <div>
                              <Button
                                className="profile-btn-orange"
                                size="lg"
                                onClick={getForfaitIfNeeded}>
                                Générer un paiement
                              </Button>{' '}
                            </div>
                          ) : userData.accountType == 'SEMIPHYSIQUE' &&
                            userData.mySubscription &&
                            diffDate(
                              userData.mySubscription?.expiration_date,
                              new Date(),
                            ) > 0 ? (
                            <Button
                              // onClick={() => {
                              //   setShowCancelForfait(true);
                              //     }}
                              disabled={true}
                              className="profile-btn-gray"
                              size="lg">
                              Générer un paiement
                            </Button>
                          ) : (
                            ''
                          )}

                          {userData.accountType == 'PHISIQUE' && (
                            <Button className="profile-btn-orange" size="lg">
                              Générer un paiement
                            </Button>
                          )}
                        </div>
                      </Col>
                    }
                  </Row>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      // border: '1px solid red',
                      margin: '0 10px',
                    }}>
                    <Col
                      // style={{border:"1px solid red"}}
                      lg={3}
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid orange',
                        borderRadius: '5px',
                        height: '500px',
                      }}>
                      <Card className="card-container">
                        <CardBody>
                          <div className="card-header">
                            <label className="card-label">
                              Diagnostique Diététique
                            </label>
                            {userData.accountType != 'DIGITAL' && (
                              <button
                                className="view-all-btn"
                                onClick={() => setShowDiagnostique(true)}>
                                Voir Plus <EyeOutlined />
                              </button>
                            )}
                          </div>
                          <ul className="user-info-container">
                            <li>
                              <label>Âge</label>
                              <p>
                                {userData.age ? userData.age + ' ans' : '-'}{' '}
                              </p>
                            </li>
                            <li>
                              <label>Régime Particulier</label>
                              <p>
                                {userData.specialRegime
                                  ? userData.specialRegime
                                  : 'Non'}
                              </p>
                            </li>
                            <li>
                              <label>Allergies</label>
                              <p>
                                {userData.allergies
                                  ? userData.allergies
                                  : 'Non'}
                              </p>
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col
                      // sm={8}
                      // style={{ border: "1px solid green" }}
                      lg={9}>
                      <Card
                        style={{
                          boxShadow: 'none',
                          borderTop: '1px solid #bfbfbf38',
                          border: '1px solid #bfbfbf38',
                        }}>
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <Row sm={12}>
                                <Col>
                                  {/* <label className="date-label">
                                    En date du{' '}
                                    {userData.userWeights?.length
                                      ? moment(
                                          userData.userWeights[
                                            userData.userWeights.length - 1
                                          ].createdAt,
                                        ).format('DD/MM/YYYY à HH[h]mm')
                                      : null}
                                  </label> */}
                                  <Checkbox
                                    style={{marginLeft: '5px'}}
                                    defaultChecked={maxMin}
                                    onChange={e => {
                                      setMaxMin(e.target.checked);
                                    }}
                                    value={maxMin}>
                                    Afficher Min & Max
                                  </Checkbox>

                                  <Checkbox
                                    style={{
                                      '--background-color': 'red',
                                      marginLeft: '5px',
                                    }}
                                    defaultChecked={label}
                                    onChange={e => {
                                      setLabel(e.target.checked);
                                    }}
                                    value={label}>
                                    Afficher label de point
                                  </Checkbox>

                                  <Checkbox
                                    style={{
                                      '--background-color': 'red',
                                      marginLeft: '5px',
                                    }}
                                    defaultChecked={false}
                                    onChange={e => {
                                      console.log('zzz', e.target.checked);
                                      setarea(e.target.checked);
                                    }}
                                    value={area}>
                                    Afficher l'aire sous la courbe
                                  </Checkbox>
                                  {/* <div className="filter-button">
                                <input
                                  id="filter-check"
                                  type="checkbox"
                                  checked={filterCheck}
                                  onChange={handleCheck}
                                />
                                <label for="filter-check">Filtre IMC</label>
                              </div> */}
                                </Col>

                                {
                                  <Page
                                    name={
                                      userData.firstName +
                                      '_' +
                                      userData.lastName
                                    }
                                    area={area}
                                    maxMin={maxMin}
                                    label={label}
                                    objective={
                                      objectiveWeight ? objectiveWeight : '0'
                                    }
                                    data={
                                      // final
                                      allWeights
                                    }
                                    weightDataObj={allObjWeights}
                                  />
                                }
                                {/* <Line
                                    style={{
                                      zoom:"130%"
                                      // transform: 'scale(.9)',
                                      // transformOrigin: "20% 20%"
                                    }}
                                    options={options3} data={data} /> */}
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col
                      // style={{ border: "1px solid green" }}
                      lg={1}>
                      <Card
                        style={{
                          boxShadow: 'none',
                          borderTop: '1px solid #bfbfbf38',
                          border: '1px solid #bfbfbf38',
                        }}>
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <Row>
                                <Col sm={12}>
                                  <label className="date-label">
                                    En date du{' '}
                                    {userData.userWeights?.length
                                      ? moment(
                                          userData.userWeights[
                                            userData.userWeights.length - 1
                                          ].createdAt,
                                        ).format('DD/MM/YYYY à HH[h]mm')
                                      : null}
                                  </label>
                                  <div className="filter-button">
                                 
                                    <label for="filter-check">Filtre IMC</label>
                                  </div>
                                </Col>
                                <Col sm={12}>
                                  <Line options={options2} data={data} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col> */}
                  </div>
                  {(userData.type != 'standard' ||
                    userData.accountType != 'DIGITAL') && (
                    <Row
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid #bfbfbf38',
                        marginLeft: '2px',
                        marginRight: '2px',
                        borderRadius: '5px',
                        paddingTop: '20px',
                        marginTop: '27px',
                        zIndex: 11,
                      }}
                      sm={3}>
                      {/* <Col sm={1}> */}
                      {/* <label className="card-label">Objectif</label> */}
                      {/* <p style={{ backgroundColor: "orange", padding: 2, borderRadius: "5px", fontWeight: 700, textAlign: "center" }}>Objectif</p>
                       */}
                      {/* </Col> */}
                      <div className="form-group">
                        <DropDown
                          style={{
                            // outline:"1px solid #7FDBDA",
                            // backgroundColor: '#7FDBDA',
                            borderRadius: '5px',
                          }}
                          value={objectif}
                          onClick={item => changeObj(item)}
                          label="Changer objectif"
                          data={goals}
                        />
                      </div>
                      {!onValid && (
                        <div className="form-group">
                          <Input
                            // border="1px solid #7FDBDA"
                            step="1"
                            pattern="[0-9]+([,\.][0-9]+)?"
                            type="number"
                            // min={40}
                            // max={150}
                            value={newWO}
                            onChange={e => {
                              if (
                                e.target.value <= 150 &&
                                e.target.value >= 0
                              ) {
                                setnewWO(e.target.value);
                              }
                            }}
                            label="Nouveau poids objectif (40-150 KG)"
                            placeholder=" "
                          />
                        </div>
                      )}
                      <Button
                        disabled={onValid}
                        onClick={onUpdateGoals}
                        style={{
                          marginTop: '13px',
                          opacity: onValid ? '0' : '1',
                        }}
                        className="profile-btn-white">
                        {LoadingOnValid ? <LoadingOutlined /> : 'Valider'}
                      </Button>
                    </Row>
                  )}
                  {userData.type ? (
                    <Row>
                      <Col lg={12}>
                        {
                          <UserMenu
                            userData={userData}
                            vacation={userData.vacation}
                          />
                        }
                      </Col>
                    </Row>
                  ) : null}

                  {/* {userData.accountType == 'DIGITAL' && (
                    <Row>
                      <Col lg={12}>
                        <UserMenu userData={userData} />
                      </Col>
                    </Row>
                  )} */}
                </TabPane>

                <TabPane tabId={2}>
                  <UserSales />
                </TabPane>
              </TabContent>

              <div>
                {/* heeeeeeeere */}
                {/* <Col><Page /></Col>{' '} */}
              </div>

              <Modal
                size="lg"
                isOpen={showDiagnostique}
                toggle={() => setShowDiagnostique(false)}>
                <ModalHeader
                  toggle={() => setShowDiagnostique(false)}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowDiagnostique(false)}>
                      &times;
                    </button>
                  }>
                  Diagnostique Diététique{' '}
                  <Button
                    onClick={() =>
                      history.push(`/${userData.type}Users/${id}/diagnostic`)
                    }
                    className="user-edit-btn">
                    Modifier
                  </Button>
                </ModalHeader>

                <ModalBody>
                  <ul className="user-modal-data">
                    <li>
                      {' '}
                      <label>Régime Particulier</label>
                      <p>{userData.specialRegime}</p>
                    </li>
                    <li>
                      <label>Allergies</label>
                      <p>{userData.allergies}</p>
                    </li>
                    <li>
                      <label>Nombre de repas par jour</label>
                      <p>{userData.numberOfMeals}</p>
                    </li>
                    <li>
                      <label>Grignotage et Description</label>
                      <div>
                        {userData.grigontageHabbit?.map(item => (
                          <p key={item}>{item}</p>
                        ))}
                      </div>
                    </li>
                    <li>
                      <label>Consommation de féculents</label>
                      <div>
                        {userData.starchConsumption?.map(item => (
                          <p key={item}>{item}</p>
                        ))}
                      </div>
                    </li>
                    <li>
                      <label>Poisson/ Fruits de mer</label>
                      <p>{userData.fishSeafood}</p>
                    </li>
                    <li>
                      <label>Viandes rouges</label>
                      <p>{userData.redMeat}</p>
                    </li>
                    <li>
                      <label>Viandes blanches</label>
                      <p>{userData.whiteMeat}</p>
                    </li>
                    <li>
                      <label>Oeufs</label>
                      <p>{userData.eggs}</p>
                    </li>
                    <li>
                      <label>Lait</label>
                      <p>{userData.milk}</p>
                    </li>
                    <li>
                      <label>Laitages</label>
                      <p>{userData.dairyProducts}</p>
                    </li>
                    <li>
                      <label>Fromages</label>
                      <p>{userData.cheeses}</p>
                    </li>
                    <li className="diagnoRow">
                      <label>
                        <strong>Boisson</strong>
                      </label>
                    </li>
                    <li>
                      <label>Eau</label>
                      <p>{userData.drink?.water}</p>
                    </li>
                    <li>
                      <label>Eau gazeuses</label>
                      <p>{userData.drink?.sparklingWater}</p>
                    </li>
                    <li>
                      <label>Café</label>
                      <p>{userData.drink?.coffee}</p>
                    </li>
                    <li>
                      <label>Thé</label>
                      <p>{userData.drink?.tea}</p>
                    </li>
                    <li>
                      <label>Sel</label>
                      <p>{userData.drink?.salt}</p>
                    </li>
                    <li className="diagnoRow">
                      <label>
                        <strong>
                          Consommation de sucres/produits sucrés par jour
                        </strong>
                      </label>
                    </li>
                    <li>
                      <label>Fruit</label>
                      <p>{userData.sugarSweetDescribtion?.fruit}</p>
                    </li>
                    <li>
                      <label>Jus</label>
                      <p>{userData.sugarSweetDescribtion?.juice}</p>
                    </li>
                    <li>
                      <label>Sucre</label>
                      <p>{userData.sugarSweetDescribtion?.sugar}</p>
                    </li>
                    <li>
                      <label>Compote</label>
                      <p>{userData.sugarSweetDescribtion?.compote}</p>
                    </li>
                    <li>
                      <label>Alcool</label>
                      <p>{userData.sugarSweetDescribtion?.alcohol}</p>
                    </li>
                    <li>
                      <label>Pâtisseries</label>
                      <p>{userData.sugarSweetDescribtion?.pastries}</p>
                    </li>
                    <li>
                      <label>Viennoiserie</label>
                      <p>{userData.sugarSweetDescribtion?.pastry}</p>
                    </li>
                    <li>
                      <label>Galette</label>
                      <p>{userData.sugarSweetDescribtion?.pancake}</p>
                    </li>
                    <li>
                      <label>Bonbon</label>
                      <p>{userData.sugarSweetDescribtion?.candy}</p>
                    </li>
                    <li>
                      <label>Chocolat</label>
                      <p>{userData.sugarSweetDescribtion?.chocolate}</p>
                    </li>
                    <li>
                      <label>Soda</label>
                      <p>{userData.sugarSweetDescribtion?.soda}</p>
                    </li>
                    <li>
                      <label>Light</label>
                      <p>{userData.sugarSweetDescribtion?.light}</p>
                    </li>
                    <li className="diagnoRow">
                      <label>
                        <strong>
                          Consommation de corps gras (beurre et huile)
                        </strong>
                      </label>
                    </li>
                    <li>
                      <label>Beurre</label>
                      <p>{userData.fattyDescribtion?.butter}</p>
                    </li>
                    <li>
                      <label>Huile et crème</label>
                      <p>{userData.fattyDescribtion?.creamOil}</p>
                    </li>
                    <li>
                      <label>Fast food</label>
                      <p>{userData.fattyDescribtion?.fastFood}</p>
                    </li>
                    <li>
                      <label>Restaurant</label>
                      <p>{userData.fattyDescribtion?.takeoutRestaurant}</p>
                    </li>
                    <li>
                      <label>Plan à emporter</label>
                      <p>{userData.fattyDescribtion?.restaurantPlan}</p>
                    </li>
                    <li className="diagnoRow">
                      <label>
                        <strong>Informations complémentaires</strong>
                      </label>
                    </li>
                    <li>
                      <label>Des informations complémentaires</label>
                      <p>{userData.otherData}</p>
                    </li>
                    <li className="diagnoRow">
                      <label>
                        <strong> Repas de la veille</strong>
                      </label>
                    </li>
                    <li>
                      <label>Petit Déjeuner</label>
                      <p>{userData.yesterdayMeal?.breakfast}</p>
                    </li>
                    <li>
                      <label>Déjeuner</label>
                      <p>{userData.yesterdayMeal?.lunch}</p>
                    </li>
                    <li>
                      <label>Dîner</label>
                      <p>{userData.yesterdayMeal?.dinner}</p>
                    </li>
                    <li>
                      <label>Grignotages</label>
                      <p>{userData.yesterdayMeal?.snacks}</p>
                    </li>
                  </ul>
                </ModalBody>
              </Modal>

              <Modal
                size="lg"
                isOpen={showUserData}
                toggle={() => setShowUserData(false)}>
                <ModalHeader
                  toggle={() => setShowUserData(false)}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowUserData(false)}>
                      &times;
                    </button>
                  }>
                  Informations{' '}
                  <Button
                    onClick={() =>
                      history.push(`/${userData.type}Users/${id}/edit`)
                    }
                    className="user-edit-btn">
                    Modifier
                  </Button>
                </ModalHeader>

                <ModalBody>
                  <ul className="user-modal-data">
                    <li>
                      <label
                        title="Envoi automatique des fichiers de régime par email lors de l'affectation."
                        style={{fontWeight: 700, color: 'orange'}}>
                        Reçoi les régimes par email automatiquement
                      </label>
                      <p>{userData?.mailing ? 'Oui' : 'Non'}</p>
                    </li>

                    <li>
                      <label>Email</label>
                      <p>{userData.email}</p>
                    </li>
                    <li>
                      <label>Téléphone</label>
                      <p>{userData.phoneNumber}</p>
                    </li>
                    <li>
                      <label>Sexe</label>
                      <p>
                        {
                          genders.filter(item => item.id === userData.gender)[0]
                            ?.name
                        }
                      </p>
                    </li>
                    <li>
                      <label>Date de naissance</label>
                      <p>{moment(userData.birthDay).format('DD/MM/YYYY')}</p>
                    </li>
                    <li>
                      <label>Adresse, Code Postale</label>
                      <p>
                        {userData.address}
                        {userData.address ? ',' : ''} {userData.zipCode}
                      </p>
                    </li>
                    <li>
                      <label>Ville, Pays</label>
                      <p>
                        {userData.city}, {userData.country}
                      </p>
                    </li>
                    <li>
                      <label>Date d’inscription</label>
                      <p>{moment(userData.createdAt).format('DD/MM/YYYY')}</p>
                    </li>
                    <li>
                      <label>Traitement médicale</label>
                      <p>{userData.medicalTreatment}</p>
                    </li>
                    <li>
                      <label>Allergies</label>
                      <p>{userData.allergies}</p>
                    </li>
                    <li>
                      <label>Régimes déjà effectués</label>
                      <p>{userData.existingDiet}</p>
                    </li>
                    <li>
                      <label>Antécédants</label>
                      <p>{userData.medicalBackground}</p>
                    </li>
                    <li>
                      <label>Grossesse</label>
                      <p>{userData.pregnancy}</p>
                    </li>
                    <li>
                      <label>Poids pris pendant la grossesse</label>{' '}
                      <p>
                        {userData.pregnancyArray?.map(
                          (item, index) =>
                            `${item} ${
                              index !== userData.pregnancyArray.length - 1
                                ? '+'
                                : ''
                            }`,
                        )}{' '}
                        {userData.pregnancyArray?.length ? '=' : ' '}{' '}
                        {userData.pregnancyArray?.reduce(
                          (a, b) => parseFloat(a) + parseFloat(b),
                        )}
                      </p>{' '}
                    </li>
                    <li>
                      <label>Profession</label>
                      <p>{userData.profession}</p>
                    </li>
                    <li>
                      <label>Objectif</label>
                      <p>
                        {
                          goals.filter(item => item.id === userData?.goal)[0]
                            ?.name
                        }
                      </p>
                    </li>
                    <li>
                      <label>Activité Physique</label>
                      <p>
                        {
                          activities.filter(
                            item => item.id === userData?.activity,
                          )[0]?.name
                        }
                      </p>
                    </li>
                    <li>
                      <label>Type d’activité</label>
                      <p>{userData.activityType}</p>
                    </li>
                  </ul>
                  <div className="photos-container">
                    {userData.photos?.map((item, index) => (
                      <div key={item}>
                        <a
                          href={`${userData.photosUrl}${item}`}
                          target="_blank">
                          <img src={`${userData.photosUrl}${item}`} />
                        </a>
                        <button
                          className="close"
                          onClick={() => deleteUserPhoto(index)}>
                          &times;
                        </button>
                      </div>
                    ))}

                    {/* {photos.map((item, index) => (
                      <div key={item}>
                        <img src={URL.createObjectURL(item)} />
                      </div>
                    ))} */}

                    {/* <div className="add-pic-btn" onClick={handleClickForUpload}>
                      <span className="icon-plus" />
                    </div> */}

                    {/* <input
                      multiple
                      onChange={handleUpload}
                      value={''}
                      accept="image/*"
                      ref={hiddenRef}
                      type="file"
                      style={{display: 'none'}}
                    /> */}
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                size="lg"
                isOpen={showPasswordChange}
                toggle={() => setShowPasswordChange(false)}>
                <ModalHeader
                  toggle={() => setShowPasswordChange(false)}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowPasswordChange(false)}>
                      &times;
                    </button>
                  }>
                  Changer le mot de passe
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={changePasswordFunc}>
                    <div className="form-group">
                      <Input
                        value={changePassword.password}
                        pattern=".*\S+.*"
                        onChange={e =>
                          setChangePassword({
                            ...changePassword,
                            password: e.target.value,
                          })
                        }
                        label="Le mot de passe"
                        type="password"
                        placeholder=" "
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        value={changePassword.confirmPassword}
                        pattern=".*\S+.*"
                        onChange={e =>
                          setChangePassword({
                            ...changePassword,
                            confirmPassword: e.target.value,
                          })
                        }
                        label="Confirmez le mot de passe"
                        type="password"
                        placeholder=" "
                      />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                      <Button
                        disabled={
                          changePassword.password !==
                            changePassword.confirmPassword || loadingDataMDP
                        }
                        type="submit"
                        className={`btn-wide center-form-save-btn ${
                          !changePassword.password ||
                          !changePassword.confirmPassword ||
                          changePassword.password !==
                            changePassword.confirmPassword
                            ? 'disabledBtn'
                            : ''
                        }`}
                        size="lg">
                        {loadingDataMDP ? (
                          <span style={{marginRight: '.5rem'}}>
                            <Spinner
                              as="span"
                              variant="warning"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="grow"
                            />{' '}
                          </span>
                        ) : null}
                        Changer le mot de passe
                      </Button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>

              <Modal
                size="lg"
                isOpen={showDeleteModal}
                toggle={!loadingData ? () => setShowDeleteModal(false) : null}>
                <ModalHeader
                  toggle={() => setShowDeleteModal(false)}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowDeleteModal(false)}>
                      &times;
                    </button>
                  }>
                  {banner ? 'Bloquer' : unbanner ? 'Débloquer' : 'Supprimer'}{' '}
                  l'utilisateur
                </ModalHeader>
                {/* <Form onSubmit=> */}
                <ModalBody>
                  <p className="modalMessage">
                    Voulez-vous vraiment{' '}
                    {banner ? 'bloquer' : unbanner ? 'débloquer' : 'supprimer'}{' '}
                    l'utilisateur?
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    disabled={loadingData}
                    color="link"
                    onClick={() => {
                      setShowDeleteModal(false);
                      setbanner(true);
                    }}>
                    Annuler
                  </Button>
                  <Button
                    disabled={loadingData}
                    // type="submit"
                    onClick={
                      banner
                        ? () => onBanUser(userData.id)
                        : unbanner
                        ? () => onUnBanUser(userData.id)
                        : deleteUser
                    }
                    className="btn-wide mb-2 mr-2"
                    size="lg"
                    style={{
                      color: 'white',
                      backgroundColor: unbanner ? 'green' : 'none',
                    }}
                    color={unbanner ? 'none' : 'danger'}>
                    {loadingData ? (
                      <span style={{marginRight: '.5rem'}}>
                        <Spinner
                          as="span"
                          variant="warning"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="grow"
                        />{' '}
                      </span>
                    ) : null}
                    {banner ? 'Bloquer' : unbanner ? 'Débloquer' : 'Supprimer'}
                  </Button>
                </ModalFooter>
                {/* </Form> */}
              </Modal>

              <Modal
                size="lg"
                isOpen={showColSelect}
                toggle={!loadingData ? () => setShowColSelect(false) : null}>
                <ModalHeader
                  toggle={() => setShowColSelect(false)}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowColSelect(false)}>
                      &times;
                    </button>
                  }>
                  Sélectionner un collaborateur
                </ModalHeader>
                <Form onSubmit={assignCollaborator}>
                  <ModalBody style={{zIndex: 11}}>
                    <DropDown
                      required
                      value={selectedCollaborator}
                      onClick={setSelectedCollaborator}
                      label="Sélectionner un collaborateur"
                      data={collaborators}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      disabled={loadingData}
                      color="link"
                      onClick={() => setShowColSelect(false)}>
                      Annuler
                    </Button>
                    <Button
                      disabled={loadingData}
                      type="submit"
                      className="btn-wide mb-2 mr-2"
                      size="lg"
                      color="danger">
                      {loadingData ? (
                        <span style={{marginRight: '.5rem'}}>
                          <Spinner
                            as="span"
                            variant="warning"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="grow"
                          />{' '}
                        </span>
                      ) : null}
                      Soumettre
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal
                size="lg"
                isOpen={
                  showSwitchToPhysical ||
                  showSwitchToStandard ||
                  showSwitchToPremium
                }
                toggle={
                  !loadingData
                    ? () => {
                        setShowSwitchToPhysical(false);
                        setShowSwitchToPremium(false);
                        setShowSwitchToStandard(false);
                      }
                    : null
                }>
                <ModalHeader
                  toggle={() => {
                    setShowSwitchToPhysical(false);
                    setShowSwitchToPremium(false);
                    setShowSwitchToStandard(false);
                  }}
                  close={
                    <button
                      className="close"
                      onClick={() => {
                        setShowSwitchToPhysical(false);
                        setShowSwitchToPremium(false);
                        setShowSwitchToStandard(false);
                      }}>
                      &times;
                    </button>
                  }>
                  Changer le type d'utilisateur
                </ModalHeader>
                <Form onSubmit={switchUserType}>
                  <ModalBody>
                    <p className="modalMessage">
                      Êtes-vous sûr de vouloir changer cet utilisateur pour{' '}
                      {showSwitchToPhysical
                        ? 'Semi-physique'
                        : showSwitchToPremium
                        ? '100% Physique'
                        : 'Digital'}{' '}
                      ?
                    </p>
                    <div style={{marginTop: '20px'}} className="form-group">
                      <Input
                        // pattern="[0-9]+([,\.][0-9]+)?"
                        // type="text"
                        value={date}
                        onChange={e => setdate(e.target.value)}
                        label="Date"
                        placeholder="AAAA-MM-JJ"
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      disabled={loadingData}
                      color="link"
                      onClick={() => {
                        setShowSwitchToPhysical(false);
                        setShowSwitchToPremium(false);
                        setShowSwitchToStandard(false);
                      }}>
                      Annuler
                    </Button>
                    <Button
                      disabled={loadingData}
                      type="submit"
                      className="btn-wide mb-2 mr-2"
                      size="lg"
                      color="danger">
                      {loadingData ? (
                        <span style={{marginRight: '.5rem'}}>
                          <Spinner
                            as="span"
                            variant="warning"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="grow"
                          />{' '}
                        </span>
                      ) : null}
                      Passer
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>

              {/* annuler forfait */}
              <Modal
                size="lg"
                isOpen={showCancelForfait}
                toggle={() => setShowCancelForfait(!showCancelForfait)}>
                <ModalHeader
                  // toggle={
                  // }}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowCancelForfait(false)}>
                      &times;
                    </button>
                  }>
                  Annuler forfait d'utilisateur
                </ModalHeader>

                <ModalBody>
                  <p className="modalMessage">
                    Êtes-vous sûr de vouloir d'annuler le forfait cet
                    utilisateur ?
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="link"
                    onClick={() => setShowCancelForfait(false)}>
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    onClick={handelAnnulerForfait}
                    className="btn-wide mb-2 mr-2"
                    size="lg"
                    color="danger">
                    D'accord
                  </Button>
                </ModalFooter>
              </Modal>

              {/* annuler / activer mode vacance */}
              <Modal
                size="lg"
                isOpen={ModeVacation}
                // toggle={() => setShowModeVacation(!ModeVacation)}
              >
                <ModalHeader
                  // toggle={
                  // }}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowModeVacation(false)}>
                      &times;
                    </button>
                  }>
                  <span
                    style={{
                      fontWeight: 700,
                      fontStyle: 'italic',
                      marginLeft: '5px',
                    }}>
                    Mode Vacances
                  </span>
                </ModalHeader>

                <ModalBody>
                  {!showDayVacation && (
                    <p style={{marginBottom: '30px'}} className="modalMessage">
                      Êtes-vous sûr de vouloir{' '}
                      {!actionVacation ? 'activer' : 'déactiver'} le mode
                      vacance ?
                    </p>
                  )}
                  {showDayVacation && (
                    <Input
                      type="number"
                      value={days}
                      min={0}
                      max={100}
                      onChange={e =>
                        e.target.value.length < 4 && setDays(e.target.value)
                      }
                      label={'Nombre de jours '}
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="link"
                    onClick={() => setShowModeVacation(false)}>
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    onClick={
                      !showDayVacation && !userData?.vacation
                        ? () => {
                            setShowDayVacation(true);
                          }
                        : !deactivateVacation
                        ? handelvacation
                        : handelCancelvacation
                    }
                    className="btn-wide mb-2 mr-2"
                    size="lg"
                    style={{
                      color: 'white',
                      backgroundColor: userData?.vacation ? '#DF2E38' : 'green',
                    }}>
                    {showDayVacation
                      ? 'Confirmer'
                      : userData?.vacation
                      ? 'Désactiver'
                      : 'Activer'}
                  </Button>
                </ModalFooter>
              </Modal>

              {/* payment forfait */}
              <Modal
                size="lg"
                isOpen={showPayForfait}
                toggle={() => setShowPayForfait(!showPayForfait)}>
                <ModalHeader
                  // toggle={
                  // }}
                  close={
                    <button
                      className="close"
                      onClick={() => setShowPayForfait(false)}>
                      &times;
                    </button>
                  }>
                  Choix du Forfait
                </ModalHeader>

                <ModalBody>
                  <Row>
                    <Card className="card-container">
                      <label className="card-label2">Forfaits</label>

                      <div className="listForfait">
                        <p
                          id="1"
                          ref={m}
                          onClick={() => {
                            const idValue = m.current?.id;
                            setSelectedForfait(Number(idValue));
                          }}
                          className="listForfait-P"
                          style={{
                            backgroundColor: '#F9F9E0',
                            color: 'black',
                            cursor: 'pointer',
                            width: '12rem',
                            border:
                              selectedForfait == 1
                                ? '1px dashed black'
                                : 'none',
                          }}>
                          <span style={{width: '100%'}} className="span-style">
                            {' '}
                            1 Mois{' '}
                          </span>
                          <span className="boldText">
                            {forForfait.forfait_1month_price}€ /mois
                          </span>{' '}
                          {/* Prix Stripe */}
                          {/* <span >{forForfait.forfait_1month}</span> */}
                        </p>
                        <p
                          id="2"
                          ref={m3}
                          onClick={() => {
                            const idValue = m3.current?.id;
                            setSelectedForfait(Number(idValue));
                          }}
                          className="listForfait-P"
                          style={{
                            backgroundColor: '#F9F9E0',
                            cursor: 'pointer',
                            width: '12rem',
                            border:
                              selectedForfait == 2
                                ? '1px dashed black'
                                : 'none',
                          }}>
                          <span style={{width: '100%'}} className="span-style">
                            {' '}
                            3 mois
                          </span>
                          <span className="boldText">
                            {forForfait.forfait_3month_price}€ /mois
                          </span>{' '}
                        </p>
                        <p
                          id="3"
                          ref={m6}
                          onClick={() => {
                            const idValue = m6.current?.id;
                            setSelectedForfait(Number(idValue));
                          }}
                          className="listForfait-P"
                          style={{
                            backgroundColor: '#F9F9E0',
                            cursor: 'pointer',
                            width: '12rem',
                            border:
                              selectedForfait == 3
                                ? '1px dashed black'
                                : 'none',
                          }}>
                          <span style={{width: '100%'}} className="span-style">
                            {' '}
                            6 mois
                          </span>
                          <span className="boldText">
                            {forForfait.forfait_6month_price}€ /mois
                          </span>{' '}
                        </p>
                        <p
                          id="4"
                          ref={m12}
                          onClick={() => {
                            const idValue = m12.current?.id;
                            setSelectedForfait(Number(idValue));
                          }}
                          className="listForfait-P"
                          style={{
                            backgroundColor: '#F9F9E0',
                            cursor: 'pointer',
                            width: '12rem',
                            border:
                              selectedForfait == 4
                                ? '1px dashed black'
                                : 'none',
                          }}>
                          <span style={{width: '100%'}} className="span-style">
                            {' '}
                            12 mois
                          </span>
                          <span className="boldText">
                            {forForfait.forfait_12month_price}€ /mois
                          </span>{' '}
                        </p>
                        <EditOutlined className="edit" style={{opacity: '0'}} />
                      </div>
                      <div>
                        <label
                          style={{backgroundColor: '#CD8D7A', width: '98.5%'}}
                          className="card-label2">
                          Code Promo
                        </label>
                        <InputAnt
                          onChange={e => {
                            setCodePromo(e.target.value);
                            setCodePromoError(false);
                          }}
                          value={codePromo}
                          placeholder="Exemple: DoIT2030"
                          style={{
                            margin: '0 0 5px 10px',
                            height: '40px',
                            width: '98.5%',
                          }}
                        />

                        {codePromoError && (
                          <span
                            style={{
                              margin: '-20px 0 0 20px',
                              fontSize: '13px',
                              color: 'red',
                              fontWeight: 500,
                            }}>
                            Le code promo ( {codePromo} ) n'est pas valide!
                          </span>
                        )}
                      </div>
                      {loadingDataUrl ? (
                        <div>
                          Génération du lien de paiement... <LoadingOutlined />
                        </div>
                      ) : (
                        <div>
                          {payUrl ? (
                            <p>
                              {' '}
                              Lien de paiement :{' '}
                              <span style={{backgroundColor: '#C5E898'}}>
                                {payUrl}
                              </span>{' '}
                            </p>
                          ) : (
                            ''
                          )}
                          {payUrl && (
                            <div
                              style={{
                                cursor: 'pointer',
                                margin: '10px',
                                color: 'white',
                                backgroundColor: !copied ? '#40A2E3' : 'gray',
                                borderRadius: '5px',
                                width: '100px',
                                textAlign: 'center',
                              }}
                              onClick={copyToClipboard}>
                              Copier
                            </div>
                          )}
                        </div>
                      )}
                    </Card>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="link"
                    onClick={() => {
                      setShowPayForfait(false);
                      setPayUrl('');
                      setSelectedForfait(1);
                    }}>
                    Annuler
                  </Button>
                  <ButtonAnt
                    type="primary"
                    onClick={
                      !payUrl
                        ? handelPayForfait
                        : () => {
                            setShowPayForfait(false);
                            setPayUrl('');
                            setSelectedForfait(1);
                          }
                    }
                    style={{color: 'white', backgroundColor: 'orange'}}>
                    {!payUrl ? 'Valider' : 'OK'}
                  </ButtonAnt>
                </ModalFooter>
              </Modal>

              <PushNotification
                user={id}
                userName={userData.firstName + ' ' + userData.lastName}
                showPushNotification={showPushNotification}
                onHide={() => setShowPushNotification(false)}
              />

              {showProgress ? (
                <div className="ecs-progressContainer">
                  <div className="ecs-circleContainer">
                    <CircularProgressbar
                      styles={buildStyles({
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

                        // Text size
                        textSize: '16px',

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors
                        pathColor: `#ffffff`,
                        textColor: '#ffffff',
                        trailColor: '#d6d6d6',
                      })}
                      strokeWidth={5}
                      text={`${progressValue}%`}
                      value={progressValue}
                    />
                  </div>

                  <p>Téléchargement....</p>
                </div>
              ) : null}
            </ReactCSSTransitionGroup>
          </div>
          {/* <AppFooter /> */}
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={showEyeModal}
        toggle={() => setShowEyeModal(false)}>
        <ModalHeader
          toggle={() => setShowEyeModal(false)}
          close={
            <button className="close" onClick={() => setShowEyeModal(false)}>
              &times;
            </button>
          }>
          Liste des paiements
        </ModalHeader>

        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}>
            {paymentListe.length > 0 ? (
              paymentListe.map((data, k) => (
                <div key={k} className="payment-item">
                  <p style={{fontWeight: 700}} className="p-payment">
                    {data.type == 'Subscription'
                      ? 'Abonnements/Forfait'
                      : 'Forfait/Abonnements'}
                  </p>
                  <p style={{color: 'green'}} className="p-payment">
                    Payé{' '}
                    <span>
                      {data.amount}€ le{' '}
                      {
                        moment(data.createdAt)
                          .format('DD/MM/YYYY')
                          .split('-')[0]

                        // moment(data.createdAt)
                        //   .format('DD/MM/YYYY')
                        // .split('-')[1]
                      }{' '}
                    </span>
                  </p>
                  <p className="p-payment">
                    {' '}
                    De{' '}
                    <span style={{color: '#897e7e'}}>
                      {moment(data.createdAt).format('DD/MM/YYYY')}
                    </span>{' '}
                    {!data.expiration_date ? (
                      ``
                    ) : (
                      <span style={{color: '#897e7e'}}>
                        {' '}
                        à{' '}
                        {moment(data.expiration_date, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY',
                        )}
                      </span>
                    )}
                    {/* à{' '} */}
                    {/* <span style={{ color: '#897e7e' }}>
                      {data}
                      
                    </span> */}
                  </p>
                </div>
              ))
            ) : (
              <div style={{fontStyle: 'italic'}}>Pas encore des paiements </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setShowEyeModal(false);
            }}
            // disabled={loadingData}
            className="profile-btn-white">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default StandardUsers;
