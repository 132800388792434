// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submitButton {
  background: #d54d29 !important;
  color: #ffffff !important;
  border: none !important;
  height: 50px;
  font-size: 1.2rem !important;
}
.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 119vh;
  
  /* height: 50%; */
  border-bottom: 1px solid black;
}
.loginContainer .card-title {
  text-align: center;
  font-size: 1.2rem !important;
}
.loginContainer img {
  width: 100%;
  max-width: 160px;
  display: block;
  margin: 2rem auto;
}
.loginContainer .main-card {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,uBAAuB;EACvB,YAAY;EACZ,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;;EAEjB,iBAAiB;EACjB,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".submitButton {\n  background: #d54d29 !important;\n  color: #ffffff !important;\n  border: none !important;\n  height: 50px;\n  font-size: 1.2rem !important;\n}\n.loginContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 119vh;\n  \n  /* height: 50%; */\n  border-bottom: 1px solid black;\n}\n.loginContainer .card-title {\n  text-align: center;\n  font-size: 1.2rem !important;\n}\n.loginContainer img {\n  width: 100%;\n  max-width: 160px;\n  display: block;\n  margin: 2rem auto;\n}\n.loginContainer .main-card {\n  max-width: 500px;\n  margin-left: auto;\n  margin-right: auto;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
