import React, {useState, useEffect, useMemo} from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
} from 'reactstrap';

import CenterFormApi from 'services/api/CenterFormApi';
import {DropDown, MySpinner, Input, DateCustomInput} from 'components/atoms';
import Datatable from './dataTable';
import moment from 'moment';
import DatePicker, {registerLocale} from 'react-datepicker';

import { CustomDatePickerInput } from 'pages/codePromo/customizeHead';


const payOptions = [
  {id: true, name: 'Payé'},
  {id: false, name: 'Impayé'},
];


const UserSales = () => {


  // billing
  const columns = [
    {
      name: 'Fichier',
      selector: row => row.file,
      sortable: true,
      fieldName: 'file',
      cell: row => {
        return (
          <div className="cell-container">
            <a>
              <span className="pe-7s-paperclip" />
            </a>
          </div>
        );
      },
    },
    {
      name: 'Référence',
      selector: row => row.file,
      sortable: true,
      fieldName: 'file',
    },
    {
      name: 'Date',
      selector: row => row.paymentDate,
      sortable: true,
      fieldName: 'paymentDate',
    },
    {
      name: 'Montant',
      selector: row => row.price,
      sortable: true,
      fieldName: 'price',
    },
  ];

  const [billingData, setBillingData] = useState([]);

  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [sort, setSort] = useState({
    _id: -1,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [reason, setReason] = useState();
  const [plansTypes, setPlansTypes] = useState([]);
  const [selectedPayOption, setSelectedPayOption] = useState();
  const [entryDate, setEntryDate] = useState();

  const getPlans = async () => {
    try {
      const res = await CenterFormApi.get(`/getPLans/${userData.type}`);
      setPlansTypes(res.data);
    } catch (e) {}
  };


  const fetchUserBills = async (page, limit, sort) => {
    setLoadingTable(true);
    setPage(page);
    setLimit(limit);
    setSort(sort);
    try {
      // const response = await CenterFormApi.get(
      //   `/fetchUserBills?page=${page}&limit=${limit}&sort=${JSON.stringify(
      //     sort,
      //   )}`,
      // );
      setBillingData(response.data.data);
      setTotalRows(response.data.recordsTotal);
    } catch (e) {}
    setLoadingTable(false);
  };

  const handlePageChange = page => {
    fetchUserBills(page, limit, sort);
  };

  const handlePerRowsChange = async (limit, page) => {
    fetchUserBills(page, limit, sort);
  };

  const handleSort = async (column, sortDirection) => {
    fetchUserBills(page, limit, {
      [column.fieldName]: sortDirection === 'desc' ? -1 : 1,
    });
  };

  useEffect(()=> {
    getPlans();
    fetchUserBills();
  }, [])
  return (
    <Row className="consult-header" style={{marginTop:"3rem"}}>
      <Col lg={12}>
        <Card className="card-container">
          <div className="card-header">
            <label className="card-label">Facturation</label>
          </div>

          <CardBody className="user-details-card">
          <Row style={{margin: 0}}>
          <Col style={{zIndex: 10}} sm={3}>
              <div className="form-group">
                <DropDown
                  value={reason}
                  onClick={setReason}
                  label="Forfait"
                  data={plansTypes}
                />
              </div>
            </Col>
            <Col style={{zIndex: 11}} sm={3}>
              <div className="form-group">
                <DropDown
                  value={selectedPayOption}
                  onClick={setSelectedPayOption}
                  label="Statut de paiement"
                  data={payOptions}
                />
              </div>
            </Col>
              <Col sm={2} style={{ zIndex: 11 }}>
              <div
                    className="ecs-group"
                    style={{border: '1px solid white', marginTop: '-1px'}}>
                    <DatePicker
                       calendarClassName="custom-calendar"
                      minDate={new Date()}
                      def
                       selected={entryDate}
                        value={entryDate}
                    onChange={date => { setEntryDate(date) }}
                      // locale={'fr'}
                      required
                      defaultShow={''}
                      // value={appointment.date}
                      dateFormat="d MMMM  yyyy"
                      dayClassName={date =>
                        date.toDateString() === entryDate?.toDateString()
                          ? 'selected-day'
                          : date.toDateString() == new Date().toDateString() &&
                            !entryDate
                          ? 'selected-day'
                          : undefined
                      }
                      customInput={
                        <CustomDatePickerInput
                          value={entryDate}
                          from="app"
                          customValidityMessage="Ce champ est obligatoire"
                        />
                      }
                    />
                  </div>
              {/* <div className="form-group">
                <DateCustomInput
                  value={entryDate}
                  onChange={date => setEntryDate(date)}
                  label="Date"
                />
              </div> */}
            </Col>
            </Row>
           {billingData.length>0&& <Datatable
              columns={columns}
              data={billingData}
              progressPending={loadingTable}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onSort={handleSort}
              defaultSortFieldId={1}
              defaultSortAsc={false}
            />
}          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UserSales;
