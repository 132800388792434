import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {MySpinner} from 'components/atoms';
import SkeletonInput from 'antd/es/skeleton/Input';
import {LoadingOutlined} from '@ant-design/icons';
import { Skeleton } from 'antd';
const sortIcon = <ArrowDownward />;
const selectProps = {indeterminate: isIndeterminate => isIndeterminate};

const DataTableBase = ({staticTable, ...props}) => {
  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tous',
  };

  return (
    <DataTable
      progressPending={'pending'}
      progressComponent={
        <Skeleton active paragraph={{ rows: 4 }} />
      }
      pagination
      noDataComponent={customNoDataComponent}
      paginationComponentOptions={paginationComponentOptions}
      paginationServer={!staticTable}
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      sortServer={!staticTable}
      {...props}
    />
  );
};

export default DataTableBase;


const customNoDataComponent = () => (
  <div >
    Aucun enregistrement à afficher
  </div>
);