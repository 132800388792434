import React, {Fragment, useEffect, useState} from 'react';
import {MySpinner} from 'components/atoms';

import AppHeader from 'Layout/AppHeader/';
import AppSidebar from 'Layout/AppSidebar/';
import AppFooter from 'Layout/AppFooter/';
import BackHandIcon from '@mui/icons-material/BackHand';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {debounce} from 'lodash';
import {Datatable, PushNotification} from 'components/organisms';
import {
  Row,
  Button,
  Form,
  CardBody,
  Card,
  Spinner,
  Input as Input2,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import CenterFormApi from 'services/api/CenterFormApi';
import history from 'services/history';
import { subscriptionStatus, subscriptionType, userName, userStatus, userVacation } from 'shared/extra';
import { customStylesTable, style } from 'shared/customTable';
import { useContext } from 'react';
import {AuthContext} from 'services';
import ButtonAntDesign from 'components/atoms/AntdButton';
import { NotificationTwoTone } from '@ant-design/icons';






const StandardUsers = () => {
  const centerFormContext = useContext(AuthContext);
  const {user_data} = centerFormContext.state;
  const [loadingData, setLoadingData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(-1);
const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [search, setSearch] = useState('');
  const [showBan,setShowBan]=useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserToNotif, setSelectedUserToNotif] = useState({_id:null,firstName:"",lastName:""});
  
  const [showPushNotification, setShowPushNotification] = useState(false);


  const onBanUser =async () => {
    setLoadingTable(true);
    try {
    const response = await CenterFormApi.put(`/api/mobile/updateUserData`, {
    id:selectedUser._id,
      state:"block"
    })
      console.log(response);
      if (response.data) {
        
        setShowBan(false)
      }
      setLoadingTable(false);

    } catch (e) {
      
      setLoadingTable(false);
  }
};




  const dismissDeleteModal = () => {
    setShowBan(false);
  };

  const fetchUsers = async (page, limit, sort, search) => {
    setLoadingTable(true);
    setPage(page);
    setLimit(limit);
    // setSort(sort);
    setSearch(search);
    try {
      // console.log(sort);
      const response = await CenterFormApi.get(
        `/fetchUsers?type=standard&page=${page}&limit=${limit}&sort=${sort.toString()}&search=${search}`,
      );
      // console.log(response.data.data);
    setLoadingTable(false);
      
      setData(response.data.data);
      setTotalRows(response.data.recordsTotal);
    } catch (e) {}
    setLoadingTable(false);
  };


  useEffect(() => {
    fetchUsers(1, limit, sort, search); // fetch page 1 of users
  }, []);

  const handlePageChange = page => {
    fetchUsers(page, limit, sort, search);
  };

  const handlePerRowsChange = async (limit, page) => {
    fetchUsers(page, limit, sort, search);
  };

  // const handleSort = async (column, sortDirection) => {
  //   fetchUsers(
  //     page,
  //     limit,
  //     {[column.fieldName]: sortDirection === 'desc' ? -1 : 1},
  //     search,
  //   );
  // };
  const handleSort = async (column, sortDirection) => {
    console.log(sortDirection === 'desc' ? -1 : 1);
    fetchUsers(page, limit, sortDirection === 'desc' ? -1 : 1, search);

    // setSort( sortDirection === 'desc' ? -1 : 1)
  };

  // console.log(sort);
  const columns = [
    {
      name: 'ID',
      selector: row => row._id,
      sortable: true,
      fieldName: '_id',
      cell: row => {
        return row._id;
      },
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: 'Vacances/Régime',
      selector: row => userVacation(row.vacation),
      // sortable: true,
      fieldName: 'Vacances',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: 'Nom',
      selector: row => userName(row.firstName, row.lastName),
      // sortable: true,
      fieldName: 'firstName',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: 'E-mail',
      selector: row => row.email,
      // sortable: true,
      fieldName: 'email',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: 'Statut ',
      selector: row => userStatus(row.state),
      // sortable: true,
      fieldName: 'statut',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: 'Abonnement ',
      selector: row =>
        row.mySubscription?.status == 'Trial'
          ? subscriptionType(row.mySubscription?.status)
          : subscriptionType(row.mySubscription?.type),
      // sortable: true,
      fieldName: 'subscription',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: "Statut d'Abonnement",
      selector: row =>
        row.mySubscription?.status == 'Trial'
          ? subscriptionStatus(row.mySubscription?.status)
          : subscriptionStatus(row.mySubscription?.status),
      // sortable: true,
      fieldName: 'status',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
      },
    },
    {
      name: 'Inscription ',
      selector: row =>
        row.signupFinshed ? (
          <div style={{width:"90px",fontWeight:800, background: '#9ADE7B', color: 'white',padding:"6px",borderRadius:"5px",textAlign:"center"}}>
            Completé
          </div>
        ) : (
          <div style={{width:"90px",fontWeight:800,background: '#F05941', color: 'white',padding:"6px",borderRadius:"5px",textAlign:"center"}}>
            Incompleté
          </div>
        ),
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    {
      name: 'Sexe ',
      selector: row =>
        row.gender=="female" ? (
          <div style={{width:"70px",fontWeight:800, background: '#F11A7B', color: 'white',padding:"6px",borderRadius:"5px",textAlign:"center"}}>
            Femme
          </div>
        ) : (
          <div style={{width:"70px",fontWeight:800,background: '#0079FF', color: 'white',padding:"6px",borderRadius:"5px",textAlign:"center"}}>
            Homme
          </div>
        ),
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    {
      style: {display: 'flex', alignItems: 'center', justifyContent: 'center'},

      name: 'Action Rapide',
      cell: row => {
        return user_data.type === 'admin' ? (
          <>
            {row.state=="active"?<button
              disabled={row.state=="block"}
             className="bann-style"
              onClick={() => {
               
                setSelectedUser(row)
                

                setShowBan(true);
              }}
              //  className="action-button"
              title="Bloquer">
            Bloquer
              <BackHandIcon style={{ color: "white" ,width:"15px",marginLeft:"4px" }}/>
            </button> : "-"}
            
          </>
        ) : null;
      },
    },
    {
      name: 'Notifer utilisateur',
      selector: row => <NotificationTwoTone
        
        onClick={() => { setSelectedUser(row._id);setSelectedUserToNotif({_id:row._id,firstName:row.firstName,lastName:row.lastName}); setShowPushNotification(true) }}
        style={{ backgroundColor: "orange", padding: "5px", fontSize: "20px", borderRadius: "5px" }} />,
      sortable: true,
      fieldName: 'Notifer utilisateur',
      style: style,
    },
  ];
// console.log(data);
  const conditionalRowStyles = [
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: 'orange',
        userSelect: 'none',
      },
    },
  ];

  // const customStylesTable = {
  //   rows: {
  //     style: {
  //       cursor:"pointer",
  //       paddingTop: "5px",
  //       fontSize: "14px",
  //       border: '1px solid #e0dfdf',
  //       minHeight: '50px', // override the row height
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       fontSize: "14px",
  //       paddingLeft: '8px', // override the cell padding for head cells
  //       paddingRight: '8px',
  //       background: '#e9e9e9',
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //     },
  //   },
  //   cells: {
  //     style: {
  //       // borderBottom: '1px solid #e0dfdf',
  //       paddingLeft: '8px',
  //       // override the cell padding for data cells
  //       paddingRight: '8px',
  //     },
  //   },
  // };

  const searchTable = debounce(async e => {
    fetchUsers(1, limit, sort, e.target.value);
  }, 1000);

  return (
    <Fragment>
      {/* <AppHeader pageTitle="Clients Standards" /> */}
      <div
     style={{padding:"0 25px"}}
      >
        {/* <AppSidebar /> */}
        <div 
        >
          <div  >
          {/* <MySpinner active={loadingData} /> */}
            <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <Row>
                <div
                   className="page-actions"
                >
                  <div
                    style={{borderTop: '5px solid #40A2E3'}}
                    className="statstics-card2">
                    <label>Nombre d’utilisateurs</label>
                    <p>{totalRows}</p>
                  </div>
                  <div style={{
                    width:"100%",
                    display: "flex", alignItems: "center", justifyContent: "flex-end"
                  }}>
                    {/* <Button
                      onClick={() => history.push(`/standardUsers/create`)}
                      className="btn-wide create-button mb-2 mr-2"
                      size="lg"
                    >
                      Créer un nouvel utilisateur standard
                    </Button> */}
                    {/* <div>
                     
                      <Button
                        className="btn-wide blue-button mb-2 mr-2"
                        size="lg"
                      >
                        Envoyer un e-mail
                      </Button>
                    </div> */}
                  
                    <ButtonAntDesign
                       color={'white'}
                       type="primary"
                      style={{
                        marginTop: "10px",
                        // marginBottom:"5px",
                         color: 'black',
                         border: '1px solid black',
                         marginLeft: 'auto',
                         display: 'block',
                       }}
                      
                       colorBgContainer={'#ce93d8'}
                        onClick={() => setShowPushNotification(true)}
                        // className="btn-wide black-button mb-2 mr-2"
                      // size="lg"
                      text={"Envoyer notification à tous"}
                      />
                      
                      
                  </div>
                </div>
              </Row>

              <Row>
                <Card
                  className="card-container"
                  style={{
                    // boxShadow: 'none',
                    // borderTop: '1px solid #bfbfbf38',
                    // borderBottom: '1px solid #bfbfbf38',
                  }}

                  // className="card-container"
                >
                  <CardBody className="users-table">
                    <div className="tableHeader">
                      <label className="card-title">
                        Mes clients Digital
                      </label>
                      <Input2
                        onChange={searchTable}
                        type="text"
                        placeholder="Rechercher"
                      />
                    </div>
                    {   (
                      <Datatable
                        columns={columns}
                        data={data}
                        paginationPerPage={limit}
                        conditionalRowStyles={conditionalRowStyles}
                        progressPending={loadingTable}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        onSort={handleSort}
                        defaultSortFieldId={1}
                        defaultSortAsc={false}
                        customStyles={customStylesTable({ pointer: true })}
                        onRowClicked={row =>
                          history.push(`/standardUsers/${row._id}`)
                        }
                      />
                    )
                    //   : loadingTable ? (
                    //   <div
                    //     style={{
                    //       display: 'flex',
                    //       justifyContent: 'center',
                    //       alignItems: 'center',
                    //     }}>
                    //     <p>Chargement... </p>
                    //   </div>
                      // ) 
                  //     :
                  //   <div
                  //   style={{
                  //     display: 'flex',
                  //     justifyContent: 'center',
                  //     alignItems: 'center',
                  //   }}>
                  //   <p>Pas des données !</p>
                  // </div>
                    }
                  </CardBody>
                </Card>
              </Row>
            </ReactCSSTransitionGroup>
          </div>
          {/* <AppFooter /> */}
        </div>
      </div>
      <PushNotification
        user={selectedUser ?selectedUser:"standard"}
        // user="standard"
        userName={selectedUserToNotif?.firstName+" "+selectedUserToNotif?.lastName}
        showPushNotification={showPushNotification}
        onHide={() => { setShowPushNotification(false); setSelectedUserToNotif({_id:null,firstName:"",lastName:""}) }}
             
      />
       <Modal
        size="lg"
        isOpen={showBan ? true : false}
        toggle={dismissDeleteModal}
        // toggle={!loading ? dismissDeleteModal : null}
      >
        <ModalHeader
          onClick={dismissDeleteModal}
          close={
            <button className="close"
            >
              &times;
            </button>
          }>
          Bloquer cette utilisateur
        </ModalHeader>
        {/* <Form
         onSubmit={onBanUser}
        > */}
          <ModalBody>
            <p className="modalMessage">
              Êtes-vous sûr de vouloir Bloquer {selectedUser?selectedUser?.lastName+" "+ selectedUser?.firstName:"cette utilisateur "} ?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={loading}
              color="link"
              onClick={dismissDeleteModal}>
              Annuler
            </Button>
            <Button
              disabled={loading}
            // type="submit"
            onClick={onBanUser}
              className="btn-wide mb-2 mr-2"
              size="lg"
              color="danger">
              {loading ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : null}
              Bloquer
            </Button>
          </ModalFooter>
        {/* </Form> */}
      </Modal>
    </Fragment>
  );
};

export default StandardUsers;
