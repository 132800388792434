export const customStylesTable = ({pointer,fromContact,fromAd}) => {
  return{
    rows: {
    
    style: {
      '&:hover': {
        backgroundColor:"#ffa50012"
      },    
        paddingTop: "5px",
        fontSize: "14px",
        border:fromAd?"none": '1px solid #e0dfdf',
        minHeight: '50px', // override the row height
        cursor: pointer ? "pointer" : "",
        // background:"red",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        background: fromContact ? "#4fc3f7" : fromAd?"#f3e5f5":'#e9e9e9',
        color: fromContact ? "white":"black",
        display: 'flex',
        justifyContent: fromContact?"flex-start":'center',
        alignItems: 'center',
        // border: "1px solid ",
        // width:'500px'
      },
    },
    cells: {
      style: {
        // highlightOnHoverStyle: {
        //   background:"red"
        // },
       
        // "&hover": {
        //   backgroundColor:"red"
        // },
        padding:fromContact? "  10px 5px 10px 5px":"none",
        borderBottom: '1px solid #e0dfdf',
        paddingLeft: '8px',
        // height:fromAd?"20px":"none",
        // override the cell padding for data cells
        paddingRight: '8px',
      },
     
    },
};
}


export const customStylesTable2 = {
  headCells: {
    style: {
      fontSize: "14px",
   
      background: '#e9e9e9',
     
      // border: "1px solid ",
      // width:'500px'
    },
  },
  
    rows: {
   
      headCells: {
        style: {
          fontSize: "18px",
        },
      },
    }
  
}
  
    

  
export const style= {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
}

  
export const styleQA= {
  display: 'flex',
  // alignItems: 'start',
  justifyContent: 'flex-start',
  color: 'black',
  fontStyle:'italic'
}