import React, {Fragment} from 'react';
import cx from 'classnames';

import {connect} from 'react-redux';
import SwitchAccountTwoToneIcon from '@mui/icons-material/SwitchAccountTwoTone';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';

import SearchBox from './Components/SearchBox';
import UserBox from './Components/UserBox';

import {Button} from 'antd';
import profile from '../../shared/profile2.png';
import pay from '../../shared/payment-method.png';
import {orange} from '@material-ui/core/colors';
import moment from 'moment';
import {diffDate, subscriptionType, subscriptionTypeString, userName} from 'shared/extra';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

class Header extends React.Component {
  render() {
    let {
      enableMobileMenuSmall,
      enableHeaderShadow,
      pageTitle,
      userProfile,
      userData,
      activeTab,
      tabClick,
      setShowEyeModal,
      collaboratorProfile,
      profileImg
    } = this.props;

    // console.log("xcxc : ",userProfile);
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          // className={cx('app-header target-header-bg', {
          //   // 'header-shadow': enableHeaderShadow,
          // })}
          // transitionName="HeaderAnimation"
          // transitionAppear={true}
          // transitionAppearTimeout={1500}
          // transitionEnter={false}
        // transitionLeave={false}
        >
          {/* <HeaderLogo /> */}
          <div
          // className='navBarMain'
          >
            {/* <div className={process.env.REACT_APP_ENV!="sandbox"?"env":"envSB"}> {process.env.REACT_APP_ENV=="sandbox"?process.env.REACT_APP_ENV:process.env.REACT_APP_ENV+"eloper"} mode </div> */}

            <div
            // // filter: blur("4px")
            //  style={{ background:"#363740",borderRadius:"1px",borderLeft:"1px solid white"}}
            // className={cx(
            //   'app-header__content',
            //   userProfile || collaboratorProfile
            //     ? 'user-profile-header'
            //     : null,
            //   {'header-mobile-open': enableMobileMenuSmall},
            // )}
            >
              <div
              // className="app-header-left"
              >
                {/* <p  style={{fontSize:"25px",color:"white", fontWeight:"100px"}} className="page-title">{pageTitle}</p> */}
              </div>
              <div className="app-header-right">{/* <UserBox /> */}</div>
            </div>

            {userProfile || collaboratorProfile ? (
              <div className="user-container-profile">
                <div className="user-img-name">
                  {profileImg ? <img className="rounded-image" src={'https://centerform.s3.amazonaws.com/'+profileImg}></img> :
                 <img className="rounded-image" src={profile}></img>
                 }

                  <div
                    style={{
                      // border: "1px solid green",
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <p
                      style={{
                        marginLeft:"5px",
                        marginBottom: '0',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}>
                      {userData.lastName &&
                        userName(userData.firstName,userData.lastName)}
                    </p>
                    <p
                      style={{
                        marginLeft:"5px",
                        marginBottom: '0',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}>
                      {userData.email}{' '}
                    </p>
                    {userData.type != 'premium' && (
                      <span
                      style={{marginLeft:"5px",}}
                      >
                        {userData.mySubscription?.status == 'Trial'
                          ? 'Essai'
                          : subscriptionTypeString(
                              userData.mySubscription?.type,
                            )}{' '}
                        {userData.mySubscription?.expiration_date ? (
                          userData.mySubscription?.expiration_date &&
                          userData.mySubscription?.status != 'Pending' ? (
                              <span style={{
                                color:
                                diffDate(userData.mySubscription?.expiration_date, new Date()) < 0 ?
                                  'red':"green"
                              }}>
                              { userData.mySubscription?.type? moment(
                                userData.mySubscription?.expiration_date,
                              ).format('DD/MM/YYYY'):""}
                            </span>
                          ) : (
                            ''
                          )
                        ) : userData?.trail_expiration_date ? (
                          moment(userData?.trail_expiration_date).format(
                            'DD/MM/YYYY'
                          )
                        ) : (
                          ''
                        )}
                        <RemoveRedEyeRoundedIcon
                          onClick={setShowEyeModal}
                          style={{
                            margin: '0 0 5px 10px',
                            width: '20px',
                            cursor: 'pointer',
                          }}
                        />
                        {/* <img style={{ cursor:"pointer",marginLeft:"5px",width:"20px"}} src={pay}/> */}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  {userProfile ? (
                    <>
                      <Button
                        onClick={() => tabClick(1)}
                        type="text"
                        style={
                          activeTab === 1
                            ? {
                                width: '160px',
                                fontWeight: 500,
                                margin: '5px',
                                textAlign: 'center',
                                border: '1px solid black',
                                padding: '0px 0 3px 0px',
                                boxShadow: '0 2px 0px #e6f4ff',
                              }
                            : {
                                width: '70px',
                                background: '#fff',
                                fontWeight: 500,
                                textAlign: 'center',
                                padding: '0px 0 3px 0px',
                                color: 'black',
                                boxShadow: '0 2px 0px #e6f4ff',
                              }
                        }
                        // className={`btn-wide tab-button ${
                        //   activeTab === 1 ? 'tab-button-active' : ''
                        // }  mr-2`}
                        // size="lg"
                      >
                        {' '}
                        Profil
                      </Button>
                      <Button
                        onClick={() => tabClick(0)}
                        type="text"
                        style={
                          activeTab === 0
                            ? {
                                width: '160px',
                                fontWeight: 500,
                                margin: '5px',
                                textAlign: 'center',
                                border: '1px solid black',
                                padding: '0px 0 3px 0px',
                                boxShadow: '0 2px 0px #e6f4ff',
                              }
                            : {
                                width: '70px',
                                background: '#fff',
                                fontWeight: 500,
                                textAlign: 'center',
                                padding: '0px 0 3px 0px',
                                color: 'black',
                                boxShadow: '0 2px 0px #e6f4ff',
                              }
                        }>
                        Infos
                      </Button>
                      {/* <Button
                        onClick={() => tabClick(2)}
                        className={`btn-wide tab-button ${
                          activeTab === 2 ? 'tab-button-active' : ''
                        }  mr-2`}
                        size="lg"
                      >
                        <span className="icon-frucration" /> FACTURATION
                      </Button> */}
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

// const mapStateToProps = state => ({
//   enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
//   closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
//   headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
//   enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
// });

// const mapDispatchToProps = dispatch => ({});

export default Header;
// connect(
//   mapStateToProps, mapDispatchToProps)(

// );
