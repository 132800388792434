export const  Checker = (path) => {
  switch (path) {
    case "/users":return true
  }
}


export const MainNav = [
  // {
  //   icon: 'icon-contacts',
  //   label: 'Clients [dev]',
  //   //to: '/physicalUsers',
  //   content: [
  {
    icon: 'icon-contacts',
    label: 'Clients physique',
    to: '/premiumUsers',
    // disabled: true,
   active: true,
  },
  {
    icon: 'icon-tickets',
    label: 'Clients semi-physique',
    to: '/physicalUsers',
    // disabled: true,
    // active: isPathActive,
    // onSelect: () => handleSelect('/physicalUsers'),
  },

  //     {
  //       icon: 'icon-contacts',
  //       label: 'Clients 100 Digital',
  //       to: '/standardUsers',
  //       disabled: true,
  //     },
  //   ],
  // },
  // {
  //   icon: 'icon-ideas',
  //   label: 'Collaborateurs [dev]',
  //   disabled: true,
  //    to: '/collaborateurs',
  // },

  // {
  //   icon: 'lnr-calendar-full',
  //   label: 'Rendez-vous [dev]',
  //   disabled: true,
  //   // to: '/calendar',
  // },
  // {
  //   icon: 'pe-7s-note2',
  //   label: 'Régimes [dev]',
  //   disabled: true,
  //   // to: '/meals',
  //   content: [
      {
        icon: 'pe-7s-note2',
        label: 'Régimes Standard',
         to: '/meals',
        // disabled: true,
      },
      {
        icon: 'pe-7s-note2',
        label: 'Régimes Premium',
         to: '/meals-premium',
        // disabled: true,
      },
  //   ],
  // },

  // {
  //   icon: 'lnr-briefcase',
  //   label: 'Produits [dev]',
  //   // to: '/products',
  //   disabled: true,
  // },
  // {
  //   icon: 'pe-7s-news-paper',
  //   label: 'Plan Tarifaire [dev]',
  //   to: '/prices-plan',
  //   disabled: true,
  // },
  {
    icon: 'icon-contacts',
    label: 'Clients Digital',
    to: '/standardUsers',
    // active: isPathActive,
  },
  {
    icon: 'pe-7s-ticket',
    label: 'Code promo',
    to: '/promo-code',
    // active: isPathActive,
  },
];

export const CollaboratorNav = colId => [
  {
    icon: 'icon-tickets',
    label: 'Clients',
    to: `/collaborateurs/clients/${colId}`,
  },
  {
    icon: 'lnr-calendar-full',
    label: 'Rendez-vous',
    to: '/calendar',
  },
  {
    icon: 'pe-7s-note2',
    label: 'Régimes Premium',
    to: '/meals-premium',
  },
  {
    icon: 'pe-7s-news-paper',
    label: 'Plan Tarifaire',
    to: '/prices-plan',
  },
  {
    icon: 'lnr-briefcase',
    label: 'Produits',
    to: '/products',
  },
];
