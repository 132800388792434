import React from 'react';
import Input from './Input';
const checkValue = (str, max) => {
  if (str.charAt(0) !== '0' || str == '00') {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
        ? '0' + num
        : num.toString();
  }
  return str;
};

const DateCustomInput = ({onChange, value, label}) => {
  const onInput = e => {
    if (!e.target.value) {
      (e) => e.target.setCustomValidity('')
    } else {
      let input = e.target.value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
      let values = input.split('/').map(function (v) {
        return v.replace(/\D/g, '');
      });
      if (values[0]) values[0] = checkValue(values[0], 31);
      if (values[1]) values[1] = checkValue(values[1], 12);
      const output = values.map(function (v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      onChange(output.join('').substr(0, 10));   
    }
   
  };

  const onBlur = e => {
    const input = e.target.value;
    let values = input.split('/').map(function (v, i) {
      return v.replace(/\D/g, '');
    });
    var output = '';

    if (values.length == 3) {
      const year =
        values[2].length !== 4
          ? parseInt(values[2]) + 2000
          : parseInt(values[2]);
      const day = parseInt(values[0]);
      const month = parseInt(values[1]) - 1;
      const d = new Date(year, month, day);
      if (!isNaN(d)) {
        const dates = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
        output = dates
          .map(function (v) {
            v = v.toString();
            return v.length == 1 ? '0' + v : v;
          })
          .join('/');
      }
    }
    onChange(output);
  };
  return (
    <Input
      value={value}
      onBlur={onBlur}
      onInput={onInput}
      placeholder=""
      onInvalid={(e) => e.target.setCustomValidity("Ce champ est obligatoire!")}
      icon="icon-Vector"
      label={label + '(DD/MM/YYYY)'}
      type="Date"
      // required
    />
  );
};

export default DateCustomInput;
