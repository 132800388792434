import React, {useContext, useEffect, useState} from 'react';
import {
  Input,
  DateCustomInput,
  DropDown,
  DateCustomInput2,
} from 'components/atoms';
import Datatable from './dataTable';
import {useParams} from 'react-router-dom';
import CenterFormApi from 'services/api/CenterFormApi';
import DatePicker, {registerLocale} from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import {ToastContainer, toast} from 'react-toastify';
// import {Button} from 'antd';
import {
  Row,
  Col,
  Button,
  Form,
  CardBody,
  Card,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import moment from 'moment';


import {customStylesTable, style} from 'shared/customTable';
import BasicDatePicker from '../../shared/customDatePicker2';
import {AuthContext} from 'services';
import {CloseCircleOutlined, EditOutlined} from '@ant-design/icons';
import ButtonAntDesign from 'components/atoms/AntdButton';

registerLocale('fr', fr);



export const showDate = (currentDate,currentHour) => {
  // Get the current date and time
  console.log(currentDate);
  console.log(currentHour);
  if (currentDate && currentHour) {
    const formattedDate = currentDate.toLocaleDateString('fr-FR');

    // Format the time as HH:MM
    const formattedTime = currentHour.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: true });
    
    // Construct the message
    const message = `Un rendez-vous est déjà prévu pour le ${formattedDate} à ${formattedTime}. Veuillez choisir une autre date.`;
    
    // Output the message (this could be setting innerHTML, alert, etc. in your application)
      return message    
  } else {
    return ""
  }

// Format the date as DD/MM/YYYY

}



const durations = [];
for (let i = 5; i <= 160; i += 5) {
  durations.push({id: i, name: i});
}

const AppointmentsAgenda = ({
  getAppointmentsByDay,
  coll, userData, from, fromAgenda }) => {
  const targetContext = useContext(AuthContext);
  const {loggedIn, user_data} = targetContext.state;
  if (!userData) {
    return null;
  }
  const collOrAdminId = user_data._id;
  const id = userData?.id;
  const appointmentInit = {
    userId: id,
    date: '',
    time: '',
    duration: '',
    reason: '',
    notes: '',
  };
  const [appointment, setAppointment] = useState({...appointmentInit});
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [loadingAppointmentsTable, setLoadingAppointmentsTable] =
    useState(false);
  const [totalAppointmentsRows, setTotalAppointmentsRows] = useState(0);
  const [appointmentSort, setAppointmentSort] = useState({_id: -1});
  const [appointmentPage, setAppointmentPage] = useState(1);
  const [appointmentLimit, setAppointmentLimit] = useState(10);
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [editAppointment, setEditAppointment] = useState({...appointmentInit});
  const [selectedDateDay, setSelectedDateDay] = useState();
  const [errorDateApp, setErrorDateApp] = useState(false);
  const [errorDateAppEdit, setErrorDateAppEdit] = useState(false);
  const [selectedDateDayEdit, setSelectedDateDayEdit] = useState();

  const dismissEdit = () => {
    setErrorDateAppEdit(false)
    setEditAppointment({...appointmentInit});
  };

  const editAppointmentFunc = data => {
  

   
    setSelectedDateDayEdit(new Date(data.date));
    setEditAppointment({
      ...data,
      // date: moment(data.date).locale('fr'),
      time: moment(data.time).toDate(),
      duration: durations.find(item => item.id === data.duration),
    });
  };

  const appointmentsColumns = [
    {
      style: style,
      name: 'Heure et Date',
      selector: row => row.date,
      sortable: true,
      fieldName: 'date',
      width: '300px',
      cell: row => {
        return (
          <div className="cell-container">
            <p>{`Le ${moment(row.date).format('DD/MM/YYYY')} ${moment(
              row.time,
            ).format(' à HH[h]mm')}`}</p>
          </div>
        );
      },
    },
    {
      style: {...style},
      name: 'Durée',
      selector: row => row.duration,
      sortable: true,
      fieldName: 'duration',
      // width: '100px',
      cell: row => row.duration + ' Min',
    },
    {
      style: style,
      name: 'Notes',
      selector: row => row.notes,
      sortable: true,
      fieldName: 'notes',
    },
    {
      style: style,

      name: 'Actions',
      cell: row => (
        <>
          {moment(new Date()).diff(row.date, 'minutes') < 0 && (
            <EditOutlined
              onClick={() => editAppointmentFunc(row)}
              style={{
                fontSize: '20px',
                marginRight:"10px",
                color: 'green'
              }}
            />
          )}
          <CloseCircleOutlined
            onClick={() => setShowDeleteModal(row)}
            style={{color: 'red', fontSize: '20px'}}
          />

          {/* className="action-button"
            title="Effacer">
            <span className="lnr-trash" />
          </button> */}
        </>
      ),
    },
  ];

  const fetchAppointments = async (page, limit, sort) => {
    setLoadingAppointmentsTable(true);
    setAppointmentPage(page);
    setAppointmentLimit(limit);
    setAppointmentSort(sort);
    try {
      const response = await CenterFormApi.get(
        `/fetchAppointments/${id}?page=${page}&limit=${limit}&sort=${JSON.stringify(
          sort,
        )}`,
      );
      setAppointmentsData(response.data.data);
      setTotalAppointmentsRows(response.data.recordsTotal);
    } catch (e) {}
    setLoadingAppointmentsTable(false);
  };

  const handlePageChangeAppointments = page => {
    fetchAppointments(page, appointmentLimit, appointmentSort);
  };

  const handlePerRowsChangeAppointments = async (limit, page) => {
    fetchAppointments(page, limit, appointmentSort);
  };

  const handleSortAppointments = async (column, sortDirection) => {
    fetchAppointments(appointmentPage, appointmentLimit, {
      [column.fieldName]: sortDirection === 'desc' ? -1 : 1,
    });
  };

  const addApointment = async e => {
    // console.log("addApointmentaddApointmentaddApointmentaddApointment");
    e.preventDefault();
    setLoadingAppointment(true);
    try {
      const newDate = appointment.date;
      // new Date(newDate.setHours(newDate.getHours() + 1))
      // console.log("voilavoila", moment(appointment.date).format("YYYY-MM-DD"));
      const payload = {
        ...appointment,
        userId: userData?.id,
        date:moment(appointment.date).format("YYYY-MM-DD"),
          // new Date(newDate.setHours(newDate.getHours() + 1)),
        collaboratorId: coll,
        duration: appointment.duration.id,
      };
      // console.log(payload);

      // // console.log(payload);
    //       // console.log(appointment,appointment.date,);
    console.log('fff');
      const res = await CenterFormApi.post('/addAppointment', payload);
      console.log(res);
      setSelectedDateDay();
      setAppointment({...appointmentInit});
      fetchAppointments(1, appointmentLimit, appointmentSort);
      // setKey(key => key + 1)
      getAppointmentsByDay()
    } catch (e) {
      console.log(e);
      setErrorDateApp(true)
    }
    setLoadingAppointment(false);
  };

  const onSelectChangeEdit = (v, t) => {
    console.log('voila ', v, t);
    if (t == 'date') {
      setSelectedDateDayEdit(new Date(v));
      setEditAppointment(user => ({
        ...editAppointment,
        [t]: new Date(v),
      }));
    } else {
      setEditAppointment(user => ({
        ...editAppointment,
        [t]: v,
      }));
    }
  };

  const onSelectChange = (value, target) => {
    console.log(value);
    if (target == 'date') {
      setSelectedDateDay(value);
      // setEditAppointment(user => ({
      //   ...editAppointment,
      //   [target]: value,
      // }));

      setAppointment(user => ({
        ...appointment,
        [target]: value,
      }));
    } else {
      console.log('valuevaluevalue', value);

      if (editAppointment?._id) {
        return setEditAppointment(user => ({
          ...editAppointment,
          [target]: value,
        }));
      }
      setAppointment(user => ({
        ...appointment,
        [target]: value,
      }));
    }
  };

  const onInputChange = (e, target) => {
    if (editAppointment?._id) {
      return setEditAppointment(user => ({
        ...editAppointment,
        [target]: e.target.value,
      }));
    }
    setAppointment(user => ({
      ...appointment,
      [target]: e.target.value,
    }));
  };

  useEffect(() => {
    fetchAppointments(appointmentPage, appointmentLimit, appointmentSort);
  }, [id]);

  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    const minutes = selectedDate.getMinutes();
    const hour = selectedDate.getHours();

    return (hour === 23 && minutes === 0) || (hour < 23 && hour >= 7);
  };

  const deleteAppointment = async e => {
    e.preventDefault();
    setLoadingData(true);
    try {
      await CenterFormApi.delete('/deleteAppointment', {
        data: {
          id: showDeleteModal?._id,
        },
      });
      setShowDeleteModal(false);
      fetchAppointments(appointmentPage, appointmentLimit, appointmentSort);
      getAppointmentsByDay()
    } catch (e) {}
    setLoadingData(false);
  };
  const updateAppointment = async e => {
    e.preventDefault();
    setLoadingAppointment(true);
    try {
      // Extracting date parts
      const year = selectedDateDayEdit.getFullYear();
      const month = selectedDateDayEdit.getMonth() + 1; // Month is zero-indexed, so adding 1
      const day = selectedDateDayEdit.getDate();

      // Creating the transformed date string
      const transformedDateString = `${year}-${
        month < 10 ? '0' + month : month
      }-${day < 10 ? '0' + day : day}`;
      // const newDate = editAppointment.date;
      // new Date(newDate.setHours(newDate.getHours() + 1))
      // console.log(editAppointment);
      // console.log(moment(new Date(editAppointment.date)).format("YYYY-MM-DD"));
      await CenterFormApi.put('/updateAppointment', {
        ...editAppointment,
        date: transformedDateString,
        // date: new Date(newDate.setHours(newDate.getHours() + 1)),
        time: editAppointment.time,
        collaboratorId: coll || userData.collaborator?.id,
        duration: editAppointment.duration.id,
      });

      dismissEdit();
      fetchAppointments(appointmentPage, appointmentLimit, appointmentSort);
      setErrorDateAppEdit(false)
    } catch (e) {
      setErrorDateAppEdit(true)
      // fetchAppointments(appointmentPage, appointmentLimit, appointmentSort);
      console.log(e);
      // toast.error('Rendez-vous ajouté avec errur', {
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: '',
      //   icon: '🚀',
      // });
    }
    setLoadingAppointment(false);
  };
  // console.log(
  //   parseDate(editAppointment.date,
  //   // "editAppointment", moment(new Date(editAppointment.date)).format('DD-MM-YYYY')
  // );

  return (
    <Row
      style={{
        margin: '0px',
        // border:'1px solid red',
        position: 'relative',
        zIndex: 15,
      }}>
      <ToastContainer />
      <Col
        style={{
          margin: 0,
          padding: 0,
        }}
        lg={12}>
        <Card
          style={{
            border: '1px solid orange',
          paddingRight:"10px"
          }}
          className="card-container">
          <CardBody className="user-details-card">
            <Row style={{marginRight: '0px'}}>
              <Col sm={12}>
                <div
                  style={{
                    margin: from == 'profile' ? '0 50px 40px 0' : '0 50px 0 0',
                    padding: '0',
                  }}
                  className="card-header">
                  <label className="card-label">Prise de rendez-vous</label>
                </div>
              </Col>
            </Row>
            <Form
              style={{
                position: 'relative',
                zIndex: 15,
                // margin: '0 50px 0 0px',
                // border:"1px solid "
              }}
              onSubmit={addApointment}>
              <Row
                lg={12}
                style={
                  {
                   
                  
                  }
                }>
                    
                <Col lg={12} style={{
                  
                  border: '1px solid white',
                }}>
                  <div>
                    <span
                      style={{
                        // border:"1px solid red",
                        marginLeft: '5px',
                        // marginBottom: "-5px",
                        marginTop: '6px',
                        fontFamily: 'Poppins-Regular',
                        fontSize: '13px',
                        color: 'black',
                      }}>
                      Date *{' '}
                    </span>
                    <BasicDatePicker
                      required
                      min={new Date()}
                      onGetDate={date => onSelectChange(date, 'date')}
                      fromvalue={selectedDateDay}
                    />
                    {/* <onSelectChange(date, 'date')
                      calendarClassName="custom-calendar"
                      minDate={new Date()}
                      def
                      selected={selectedDateDay}
                      onChange={date => onSelectChange(date, 'date')}
                      locale={'fr'}
                      required
                      defaultShow={''}
                      // value={appointment.date}
                      dateFormat="d MMMM  yyyy"
                      dayClassName={date =>
                        date.toDateString() === selectedDateDay?.toDateString()
                          ? 'selected-day'
                          : date.toDateString() == new Date().toDateString() &&
                            !selectedDateDay
                          ? 'selected-day'
                          : undefined
                      }
                      customInput={
                        <CustomDatePickerInput
                          value={selectedDateDay}
                          from="app"
                          customValidityMessage="Ce champ est obligatoire"
                        />
                      } */}
                    {/* /> */}
                  </div>
                  <div className="form-group" style={{marginTop: '25px'}}>
                    <DropDown
                      required
                      value={appointment.duration}
                      onClick={item => onSelectChange(item, 'duration')}
                      label="Durée (min)"
                      data={durations}
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{marginTop: '10px', width: '100%'}}>
                    <DatePicker
                      locale="fr"
                      required
                      calendarClassName="custom-time"
                      selected={appointment.time}
                      timeIntervals={15}
                      showTimeSelect
                      showTimeSelectOnly
                      timeCaption="Heure"
                      dateFormat="h:mm aa"
                      filterTime={filterPassedTime}
                      onChange={date => onSelectChange(date, 'time')}
                      customInput={<DateCustomInput2 label="Heure" />}
                    />
                  </div>
                </Col>
                {/* <Col sm={2}>
                  <div className="form-group" style={{marginTop: '10px'}}>
                    <DatePicker
                      locale="fr"
                      required
                      calendarClassName="custom-time"
                      selected={appointment.time}
                      timeIntervals={15}
                      showTimeSelect
                      showTimeSelectOnly
                      timeCaption="Heure"
                      dateFormat="h:mm aa"
                      filterTime={filterPassedTime}
                      onChange={date => onSelectChange(date, 'time')}
                      customInput={<DateCustomInput2 label="Heure" />}
                    />
                  </div>
                </Col> */}
              </Row>
              <Row>
                <Col
                  lg={12}
                  style={{
                    // border: '1px solid white',
                    marginTop: '-5px',
                  }}>
                  <div
                    style={
                      {
                        // border: '1px solid white'
                      }
                    }>
                    <p
                      style={{
                        marginBottom: '2px',
                        fontFamily: 'Poppins-Regular',
                        fontSize: '14px',
                        color: 'black',
                        fontWeight: 500,
                      }}>
                      Notes
                    </p>
                    <textarea
                      className="centerform-textarea"
                      style={{height: '100px', width: '100%'}}
                      value={appointment.notes}
                      pattern=".*\S+.*"
                      onChange={e => onInputChange(e, 'notes')}
                      type="text"
                      placeholder="Notez ici ..."
                    />
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                <Button
                  disabled={
                    !appointment.date ||
                    !appointment.duration ||
                    !appointment.time
                  }
                  onClick={addApointment}
                  type="primary"
                  // className="btn-style"
                  className="profile-btn-orange"
                  style={
                    !appointment.date ||
                    !appointment.duration ||
                    !appointment.time
                      ? {
                          // width: "200px",
                          margin: '0 2px 6px 0',
                          backgroundColor: '#e9e9e9',
                        }
                      : {
                          // width:"200px",
                          margin: '0 2px 6px 0',
                          backgroundColor: '#ef6c00',
                        }
                  }
                  //  icon={}
                >
                  <div>Ajouter rendez-vous</div>
                </Button>
                
                 
              </div>
            </Form>
            {errorDateApp && <div
            style={{margin:2,fontSize:15,fontStyle:"italic",color:"red",fontWeight:800}}
            >
              <p>{showDate(appointment.date,appointment.time)}</p>
            </div>}
            {appointmentsData.length > 0 && (
              <div
                style={{
                  marginTop: '10px',
                  // marginRight: '50px',
                  borderTop: '1px solid orange',
                }}>
                <Datatable
                  columns={appointmentsColumns}
                  data={appointmentsData}
                  progressPending={loadingAppointmentsTable}
                  paginationTotalRows={totalAppointmentsRows}
                  onChangeRowsPerPage={handlePerRowsChangeAppointments}
                  onChangePage={handlePageChangeAppointments}
                  onSort={handleSortAppointments}
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  customStyles={customStylesTable({pointer: false})}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </Col>

      <Modal
        size="lg"
        isOpen={showDeleteModal}
        toggle={!loadingData ? () => setShowDeleteModal(false) : null}>
        <ModalHeader
          toggle={() => setShowDeleteModal(false)}
          close={
            <button className="close" onClick={() => setShowDeleteModal(false)}>
              &times;
            </button>
          }>
          Supprimer le rendez-vous
        </ModalHeader>
        <Form onSubmit={deleteAppointment}>
          <ModalBody>
            <p className="modalMessage">
              Voulez-vous vraiment supprimer ce rendez-vous ?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={loadingData}
              color="link"
              onClick={() => setShowDeleteModal(false)}>
              Annuler
            </Button>
            <Button
              disabled={loadingData}
              type="submit"
              className="btn-wide mb-2 mr-2"
              size="lg"
              color="danger">
              {loadingData ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : null}
              Effacer
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        style={{width: '1000px'}}
        // className='modal-content-cal'
        size="lg"
        isOpen={editAppointment?._id}
        toggle={!loadingData ? dismissEdit : null}>
        <ModalHeader
          toggle={dismissEdit}
          close={
            <button className="close" onClick={dismissEdit}>
              {/* &times; */}
            </button>
          }>
          <label className="card-label">Modifier le rendez-vous</label>
        </ModalHeader>
        <Form onSubmit={updateAppointment}>
          <ModalBody
          // style={{height:"100%"}}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid orange',
                // flexDirection:"column"
              }}></div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid orange',
                flexDirection: 'column',
              }}>
              <Col sm={12} style={{marginTop: '1px'}}>
                <BasicDatePicker
                  required
                  label={'Date*'}
                  onGetDate={date => onSelectChangeEdit(date, 'date')}
                  fromvalue={selectedDateDayEdit}
                />

                <div className="form-group">
                  <span
                    style={{
                      fontSize: '14px',
                      marginLeft: '5px',
                      marginTop: -20,
                      color: 'black',
                    }}>
                    Note
                  </span>
                  <textarea
                    // required
                    className="centerform-textarea2"
                    value={editAppointment.notes}
                    pattern=".*\S+.*"
                    onChange={e => onInputChange(e, 'notes')}
                    placeholder=" "
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <DatePicker
                    locale="fr"
                    required
                    selected={editAppointment.time}
                    timeIntervals={15}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    filterTime={filterPassedTime}
                    onChange={date => onSelectChangeEdit(date, 'time')}
                    customInput={<DateCustomInput2 label="Heure" />}
                  />
                </div>
                <div className="form-group">
                  <DropDown
                    required
                    value={editAppointment.duration}
                    onClick={item => onSelectChangeEdit(item, 'duration')}
                    label="Durée (min)"
                    data={durations}
                  />
                </div>
              </Col>
            </div>
            {errorDateAppEdit && <div
              
              style={{
                textAlign: "center",
                
                marginTop: 20, fontSize: 15, fontStyle: "italic", color: "red", fontWeight: 800
              }}
            >
              <p>{showDate(new Date(editAppointment.date),new Date(editAppointment.time))}</p>
            </div>}
          </ModalBody>
          <ModalFooter>
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <ButtonAntDesign
                color={'red'}
                //  type="submit"
                style={{
                  color: 'black',
                  minWidth: '120px',
                  //  border: '1px solid black',
                  marginRight: '10px',
                  //  display: 'block',
                }}
                // disabled={loading}
                // color="link"
                text={'Annuler'}
                onClick={dismissEdit}></ButtonAntDesign>
              <ButtonAntDesign
                color={'orange'}
                type="primary"
                style={{
                  color: 'black',
                  minWidth: '120px',
                  border: '1px solid black',
                  marginLeft: 'auto',
                  display: 'block',
                }}
                colorBgContainer={'#ce93d8'}
                // icon={
                //   loadingEdit && (
                //     <span style={{marginRight: '.5rem'}}>
                //       <Spinner
                //         as="span"
                //         variant="warning"
                //         size="sm"
                //         role="status"
                //         aria-hidden="true"
                //         animation="grow"
                //       />{' '}
                //     </span>
                //   )
                // }
                text={'Enregistrer'}
                onClick={updateAppointment}
              />
           
            {/* <Button disabled={loadingData} color="link" onClick={dismissEdit}>
              Annuler
            </Button>
            <Button
              disabled={loadingData}
              type="submit"
              className="btn-wide center-form-save-btn"
              size="lg">
              {loadingData ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : null}
              Sauver
            </Button> */}
               </div>
           
          </ModalFooter>
        </Form>
      </Modal>
    </Row>
  );
};

export default AppointmentsAgenda;
