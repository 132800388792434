import React, {Fragment, useEffect, useRef, useState} from 'react';
import debounce from 'lodash.debounce';
import cx from 'classnames';
import CenterFormApi from 'services/api/CenterFormApi';
import history from 'services/history';
import {Input} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const SearchBox = () => {
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchData, setSearchData] = useState();
  const [inputValue, setinputValue] = useState('');
  const [loader, setLoader] = useState(false);
  const serachBox = useRef(null);

  const handleOutsideClick = event => {
    if (serachBox.current && !serachBox.current.contains(event.target)) {
      setActiveSearch(false);
    }
  };
  const handleInsideClick = event => {
    if (serachBox.current && !serachBox.current.contains(event.target)) {
      setActiveSearch(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleInsideClick);
    return () => {
      document.removeEventListener('mousedup', handleInsideClick);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const onChangeInput =async e => {
    setActiveSearch(true);
    setinputValue(e.target.value);
    const value = e.target.value;
    if (value.length >= 3) {
      setLoader(true);
      try {
        const res = await CenterFormApi.post('/generalUserSearch', {
          searchQuery: e.target.value,
        });
        setLoader(false);
        return setSearchData(res.data);
      } catch (e) {
        setLoader(false);
      }
    }
    setSearchData(null);
  }

  const premiumUsers = searchData?.users.filter(
    item => item.type === 'premium',
  );
  const physicalUsers = searchData?.users.filter(
    item => item.type === 'physical',
  );
  const standardUsers = searchData?.users.filter(
    item => item.type === 'standard',
  );
  return (
    <Fragment>
      <div
         
        className="serach-env">
      {loader && (
          
          <LoadingOutlined style={{color: 'black', fontSize: '17px',marginRight:"5px"}} />
        
      )}
        <div
          style={{
            width: '500px',
          }}
          className="search-wrapper-2">
          <Input
            placeholder="Rechercher"
         
            onChange={onChangeInput}
            value={inputValue}
            style={{
              width: '90%',
              border: '1px solid black',
              marginTop: '-5px',
            }}
            type="text"
            className="search-input"
          />
         
        </div>
        
      </div>
      {(searchData?.users || searchData?.collaborators) && activeSearch ? (
        <div
        ref={serachBox}
          style={{
            overflow: "scroll",
            height:"500px"
          }}
          className="search-result-box">
          {premiumUsers?.length > 0 ? (
            <ul>
              <li>
                <label><span style={{ padding:"0 5px", background: "orange", borderRadius: "5px" }}>
                    Clients Physique :
                  </span></label>
              </li>
              {premiumUsers.map(item => (
                <li
               
                  
                  onClick={() => {
                    setActiveSearch(false);
                    setinputValue("")
                    history.push(`/premiumUsers/${item._id}`);
                  }}
                  key={item.firstName + item.lastName}>
                  <p className='search-p'>
                    {item.firstName} {item.lastName}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <li>
              <label>
                  <span style={{ padding:"0 5px", background: "orange", borderRadius: "5px" }}>
                    Clients Physique :
                  </span>
                    {" "}  
                    aucun résultat trouvé pour "{inputValue}"
              </label>
            </li>
          )}
          {physicalUsers?.length > 0 ? (
            <ul>
              <li>
                <label> <span style={{ padding:"0 5px", background: "orange", borderRadius: "5px" }}>
                    Clients Semi-Physique :
                  </span></label>
              </li>
              {physicalUsers.map(item => (
                <li
                  onClick={() => {
                    setActiveSearch(false);
                    setinputValue("")
                    history.push(`/physicalUsers/${item._id}`);
                  }}
                  key={item.firstName + item.lastName}>
                  <p className='search-p'>
                    {item.firstName} {item.lastName}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <li>
                <label>
                <span style={{ padding:"0 5px", background: "orange", borderRadius: "5px" }}>
                    Clients Semi-Physique :
                  </span>
                    {" "} 
                    aucun résultat trouvé pour "{inputValue}"</label>
            </li>
          )}
          {standardUsers?.length > 0 ? (
            <ul
            
            
            >
              <li > 
                <label><span style={{ padding:"0 5px", background: "orange", borderRadius: "5px" }}>
                    Clients Digital :
                  </span></label>
              </li>
              {standardUsers.map(item => (
                <li
               
                  
                  onClick={() => {
                    setActiveSearch(false);
                    setinputValue("")
                    history.push(`/standardUsers/${item._id}`);
                  }}
                  key={item.firstName + item.lastName}>
                  <p className='search-p'>
                    {item.firstName} {item.lastName} 
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <li>
                <label>
                <span style={{ padding:"0 5px", background: "orange", borderRadius: "5px" }}>
                    Clients Digital :
                  </span>
                    {" "} 
                    aucun résultat trouvé pour "{inputValue}"</label>
            </li>
          )}
          {searchData.collaborators.length > 0 ? (
            <ul>
              <li>
                <label>Collaborateurs</label>
              </li>
              {searchData.collaborators.map(item => (
                <li
                 
                  onClick={() => {
                    setActiveSearch(false);
                    history.push(`/collaborateurs/${item._id}`);
                  }}
                  key={item.firstName + item.lastName}>
                  <p  className='search-p'>
                    {item.firstName} {item.lastName}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <li>
                <label>
                <span style={{ padding:"0 5px", background: "orange", borderRadius: "5px" }}>
                Collaborateurs :
                  </span>
                    {" "} 
                    aucun résultat trouvé pour "{inputValue}"</label>
            </li>
          )}
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default SearchBox;
