import React, {useState, useEffect} from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import CenterFormApi from 'services/api/CenterFormApi';
import {DropDown, MySpinner, Input} from 'components/atoms';
import Datatable from './dataTable';
import moment from 'moment';

const payOptions = [
  {id: true, name: 'Payé'},
  {id: false, name: 'Impayé'},
];

const selectedProduct = {
  _id: null,
  name: '',
  quantity: 1,
};

const UserProducts = ({userData}) => {
  const [loadingData, setLoadingData] = useState(false);
  const [prodcuts, setProducts] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([
    {...selectedProduct},
  ]);
  const btnDisabled = selectedProducts.filter(item => !item._id).length;
  const totalPrice = selectedProducts.reduce(
    (a, b) => (b['price'] ? a + b['price'] * b['quantity'] : a),
    0,
  );
  const [selectedProductId, setSelectedProductId] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [sort, setSort] = useState({
    _id: -1,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedPayOption, setSelectedPayOption] = useState();
  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Nom',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.createdAt,
      sortable: true,
      cell: row => <p>{moment(row.createdAt).format('DD/MM/yyyy')}</p>,
    },
    {
      name: 'Prix',
      selector: row => row.price + '€',
      sortable: true,
    },
    {
      name: 'Quantitét',
      selector: row => row.quantity,
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => row.price * row.quantity + '€',
      sortable: true,
    },
    {
      name: 'Etat',
      selector: row => row.state,
      sortable: true,
      cell: row => <p>{row.paid ? 'Payé' : 'Non Payé'}</p>,
    },
    {
      name: 'Actions',
      cell: row => (
        <button
          onClick={() => setSelectedProductId(row.id)}
          className="action-button"
          title="Effacer"
        >
          <span className="lnr-trash" />
        </button>
      ),
    },
  ];

  const getProducts = async () => {
    try {
      const response = await CenterFormApi.get(`/getProducts`);
      const productsArray = [];
      response.data.map(item => {
        productsArray.push({...item, name: `${item.name} - ${item.price}€`});
      });
      setProducts(productsArray);
    } catch (e) {}
  };

  const fetchUserProducts = async (page, limit, sort) => {
    setLoadingTable(true);
    setPage(page);
    setLimit(limit);
    setSort(sort);
    try {
      const response = await CenterFormApi.get(
        `/fetchUserProducts?userId=${
          userData.id
        }&page=${page}&limit=${limit}&sort=${JSON.stringify(sort)}`,
      );
      setUserProducts(response.data.data);
      setTotalRows(response.data.recordsTotal);
    } catch (e) {}
    setLoadingTable(false);
  };

  const handlePageChange = page => {
    fetchUserProducts(page, limit, sort);
  };

  const handlePerRowsChange = async (limit, page) => {
    fetchUserProducts(page, limit, sort);
  };

  const handleSort = async (column, sortDirection) => {
    fetchUserProducts(page, limit, {
      [column.fieldName]: sortDirection === 'desc' ? -1 : 1,
    });
  };

  const addUserProduct = async e => {
    e.preventDefault();
    setLoadingData(true);
    try {
      const res = await CenterFormApi.post('/addUserProduct', {
        id: userData.id,
        products: selectedProducts,
        selectedPayOption: selectedPayOption?.id,
      });
      setSelectedProducts([{...selectedProduct}]);
      fetchUserProducts(1, limit, sort);
    } catch (e) {}
    setLoadingData(false);
  };

  const deleteProduct = async e => {
    e.preventDefault();
    setLoadingData(true);
    try {
      const res = await CenterFormApi.delete('/deleteUserProduct', {
        data: {
          id: userData.id,
          billId: selectedProductId,
        },
      });
      fetchUserProducts(page, limit, sort);
    } catch (e) {}
    setLoadingData(false);
    setSelectedProductId(null);
  };
  useEffect(() => {
    getProducts();
    fetchUserProducts(page, limit, sort);
  }, []);

  const changeSelectedProduct = (selected, index) => {
    selectedProducts[index]._id = selected._id;
    selectedProducts[index].name = selected.name;
    selectedProducts[index].price = selected.price;
    setSelectedProducts([...selectedProducts]);
  };

  const changeQuantity = (index, e) => {
    selectedProducts[index].quantity = e.target.value;
    setSelectedProducts([...selectedProducts]);
  };

  const addNewInput = () => {
    selectedProducts.push({...selectedProduct});
    setSelectedProducts([...selectedProducts]);
  };

  const deleteInput = index => {
    const filterdProducts = selectedProducts.filter(
      (item, index2) => index2 !== index,
    );
    setSelectedProducts(filterdProducts);
  };

  return (
    <>
      <Card
        style={{paddingBottom: '4rem', paddingTop: '2rem'}}
        className="card-container"
      >
      {/* <MySpinner active={loadingData} /> */}
        <div className="card-header">
          <label className="card-label">Produits</label>
        </div>
        <Form className="ecs-form" onSubmit={addUserProduct}>
          {selectedProducts.map((item, index) => (
            <Row style={{margin: 0, zIndex: 15 - index, position: 'relative'}}>
              <Col sm={6}>
                <div className="form-group">
                  <DropDown
                    value={item._id ? {id: item.id, name: item.name} : null}
                    onClick={selected => changeSelectedProduct(selected, index)}
                    label="Choisissez un produit"
                    data={prodcuts}
                    required
                  />
                </div>
              </Col>
              <Col style={{zIndex: 11}} sm={4}>
                <div className="form-group">
                  <Input
                    value={item.quantity}
                    onChange={e => changeQuantity(index, e)}
                    label="Quantitét"
                    pattern="[0-9]"
                    min={1}
                    type="number"
                    required
                  />
                  {index + 1 === selectedProducts.length ? (
                    <div onClick={addNewInput} className="add-another">
                      <span className="icon-plus" />
                    </div>
                  ) : null}
                  {index ? (
                    <div
                      onClick={() => deleteInput(index)}
                      className="add-another"
                    >
                      <span className="icon-minus" />
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          ))}
          <Row style={{margin: 0, zIndex: 11, position: 'relative'}}>
            {' '}
            <Col sm={3}>
              <div className="form-group">
                <DropDown
                  value={selectedPayOption}
                  onClick={setSelectedPayOption}
                  label="Statut de paiement"
                  data={payOptions}
                  required
                />
              </div>
            </Col>
            <Col style={{zIndex: 11}} sm={3}>
              <p className="product-total-price">Total: {totalPrice}€</p>
            </Col>
            <Col style={{display: 'flex', alignItems: 'center'}} sm={3}>
              <Button
                disabled={btnDisabled || loadingData}
                type="submit"
                style={{width: '100%'}}
                className={`btn-wide center-form-save-btn  ${
                  btnDisabled ? 'disabledBtn' : ''
                }`}
                size="lg"
              >
                {loadingData ? (
                  <span style={{marginRight: '.5rem'}}>
                    <Spinner
                      as="span"
                      variant="warning"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="grow"
                    />{' '}
                  </span>
                ) : null}
                Ajouter
              </Button>
            </Col>
          </Row>
        </Form>
        <div>
          <Datatable
            columns={columns}
            data={userProducts}
            progressPending={loadingTable}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            defaultSortFieldId={1}
            defaultSortAsc={false}
          />
        </div>
      </Card>

      <Modal
        size="lg"
        isOpen={selectedProductId ? true : false}
        toggle={!loadingData ? () => setSelectedProductId(null) : null}
      >
        <ModalHeader
          toggle={() => setSelectedProductId(null)}
          close={
            <button
              className="close"
              onClick={() => setSelectedProductId(null)}
            >
              &times;
            </button>
          }
        >
          Supprimer le produit
        </ModalHeader>
        <Form onSubmit={deleteProduct}>
          <ModalBody>
            <p className="modalMessage">
              Voulez-vous vraiment supprimer ce produit?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={loadingData}
              color="link"
              onClick={() => setSelectedProductId(null)}
            >
              Annuler
            </Button>
            <Button
              disabled={loadingData}
              type="submit"
              className="btn-wide mb-2 mr-2"
              size="lg"
              color="danger"
            >
              {loadingData ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : null}
              Effacer
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default UserProducts;
