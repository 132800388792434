import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import React from 'react';

export const customValidationMessage = 'ce champ est obligatoire!'; // Customize the validation message here
const Input = ({forNumbers,inputType,label, icon, value, withEye,border,onClick,setPassword ,...props }) => {
  return (
    <div
      onClick={onClick}
      className="ecs-group"
      style={{  paddingTop: '8px', paddingBottom: '6px', width: '100%' }}>
      {props.type == "password" && withEye && <EyeOutlined
        onClick={() => {
          setPassword('text')
          console.log('clicked');
        }}
        onMouseDown={() => {
          setPassword('text')
          
          // console.log('dddd');
        }}
        style={{
          cursor:"pointer",
          fontSize: 18, position: "absolute", right: 10, top: 22
        }} />}
      
      {props.type == "text" && withEye &&
        <EyeInvisibleOutlined
        onClick={() => {
          setPassword('password')
          console.log('clicked');
        }}
        onMouseDown={() => {
          setPassword('password')
          
          // console.log('dddd');
        }}
        style={{
          cursor:"pointer",
          fontSize: 18, position: "absolute", right: 10, top: 22
        }}
      />}



      <input
        onInput={e => e.target.setCustomValidity('')} // Reset the custom validity on input
        onInvalid={e => {
          e.target.setCustomValidity('Ce champ est obligatoire!');
        }} // Set the custom validation message
        title=""
        {...props}
        // ref={ref}
        value={value}
        //  pattern={forNumbers?"[0-9]{2}-[0-9]{2}-[0-9]{4}":null}
        style={{
          paddingLeft: '10px',
          borderRadius: '6px',
          outline:border?border:"",
          width:
            // inputType ?
              // "20px" :
              '100%',
          height: props.islarge ? '150px' : '',
        }}
      />
      <span className="ecs-highlight"></span>
      <span className="ecs-bar"></span>
      <label
        style={{
          paddingLeft: '6px',
          marginBottom: '10px',
          color: props.disabled ? '#d5d9eb' : 'black',
        }}>
        {label} {icon ? <span className={icon} /> : null}
      </label>
    </div>
  );
};
export default Input;
