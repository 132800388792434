import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {toast} from 'react-toastify';
import {ar} from 'date-fns/locale';
import moment from 'moment';

export function userType(data) {
  console.log("ho ",data);
  switch (data) {
    case 'physical': {
      return (
        <div
          style={{
            width: '125px',
            background: '#6528F7',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          SEMI-PHYSIQUE
        </div>
      );
    }
    case 'standard': {
      return (
        <div
          style={{
            width: '120px',
            background: '#A6CF98',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          DIGITAL
        </div>
      );
    }
    case 'premium': {
      return (
        <div
          style={{
            width: '120px',
            background: '#F55353',
            color: 'White',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          PHYSIQUE
        </div>
      );
    }

    default:
      return '-';
  }
}
export function userTypeString(data) {
  // console.log("ho ",data);
  switch (data) {
    case 'physical': {
      return  'semi-physique'
    }
    case 'standard': {
      return "digital"
      
    }
    case 'premium': {
      return  "physique" 
    
    }

    default:
      return '-';
  }
}
export function subscriptionType(data) {
  switch (data) {
    case 'TRIMESTR': {
      return (
        <div
          style={{
            width: '90px',
            background: '#6528F7',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Trimestriel
        </div>
      );
    }
    case 'MONTHELY': {
      return (
        <div
          style={{
            width: '80px',
            background: '#0766AD',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Mensuel
        </div>
      );
    }
    case 'YEARLY': {
      return (
        <div
          style={{
            width: '70px',
            background: '#F55353',
            color: 'White',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Annuel
        </div>
      );
    }
    case 'Trial': {
      return (
        <div
          style={{
            width: '70px',
            background: '#008170',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Essai
        </div>
      );
    }
    default:
      return '-';
  }
}

export function subscriptionTypeString(data) {
  // console.log(data);
  switch (data) {
    case 'TRIMESTR': {
      return 'Trimestriel';
    }
    case 'MONTHELY': {
      return 'Mensuel • Expiration le ';
    }
    case 'YEARLY': {
      return 'Annuel  • Expiration le ';
    }
    case 'Trial': {
      return 'Essai  • Expiration le ';
    }
    case '3MONTH':
      return '3 mois • Expiration le ';
    case '6MONTH':
      return '6 mois • Expiration le ';
    case '1MONTH':
      return 'Un mois • Expiration le';
    case '12MONTH':
      return '12 mois  • Expiration le';

    default:
      return 'Pas de forfait ou abonnement';
  }
}

export function subscriptionStatus(data) {
  switch (data) {
    case 'Pending': {
      return 'En cours';
    }
    case 'Active': {
      return 'Activé';
    }
    case 'Cancelled': {
      return 'Annulé';
    }
    case 'Trial': {
      return 'Activé';
    }
    default:
      return '-';
  }
}
export const userVacation = value => {
  if (value === true) {
    return (
      <div
        style={{
          minWidth: '110px',
          color: 'black',
          background: '#ECF4D6',
          padding: '6px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        En vacance
      </div>
    );
  } else if (value === false) {
    return (
      <div
        style={{
          minWidth: '110px',
          background: '#2D9596',
          color: 'white',
          padding: '6px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        En régime
      </div>
    );
  } else {
    return (
      <div
        style={{
          minWidth: '110px',
          opacity: '.9',
          background: 'gray',
          color: 'white',
          padding: '6px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        En traitement
      </div>
    );
  }
};

export function userStatus(data) {
  // console.log(data);
  switch (data) {
    case 'active': {
      return (
        <div
          style={{
            width: '70px',
            background: '#008170',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Activé
        </div>
      );
    }
    case 'block': {
      return (
        <div
          style={{
            width: '70px',
            background: 'gray',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Bloqué
        </div>
      );
    }
    case 'deleted': {
      return (
        <div
          style={{
            width: '90px',
            background: 'gray',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Supprimé
        </div>
      );
    }
    default:
      return '-';
  }
}
export function userName(firstName, lastName) {
  // console.log(firstName);
  // console.log(lastName);
  if (firstName?.includes('newclient')) {
    return '- -';
  } else {
    return `${lastName?.toUpperCase()} ${firstName}`;
  }
}

export function parseUrlSegments(url, coll) {
  const segments = url.split('/').filter(segment => segment !== ''); // Remove empty segments
  const sag = segments.map(data => {
    switch (data) {
      case 'standardUsers':
        return 'Client Digital';
      case 'create':
        return 'création';
      case 'PremiumUsers':
        return 'Clients physique';
      case 'premiumUsers':
        return 'Clients physique';
      case 'physicalUsers':
        return 'Clients semi-physique';
      case 'collaborateurs':
        return !coll ? 'Collaborateurs & Bannières publicitaires' : 'Profile';
      case 'meals-premium':
        return 'Régimes premium ';
      case 'meals-standard':
        return 'Régimes standard';
      case 'createMeal':
        return 'Création';
      case 'fruits-vegtables':
        return 'Fruits et Légumes';
      case 'calendar':
        return 'Rendez-vous';
      case 'products':
        return 'les produits';
      case 'prices-plan':
        return 'Plans Tarifaires & Abonnements';
      case 'promo-code':
        return 'Code Promo';
      case 'mes-demandes':
        return 'Mes demandes';
      case 'messagerie':
        return 'Messagerie';
      case 'edit':
        return 'Modification';
      case 'clients':
        return 'Clients';

      case 'cgu':
        return 'Les conditions générales d’utilisation (CGU) & Contact';
      default:
        return isNaN(data) ? "" : 'Détails';
    }
  });
  // console.log("sag ",sag);
  return sag;
}

export const ReturnMenu = ({coll}) => {
  const menus = parseUrlSegments(document.location.pathname, coll);
  const menuElements = menus.map((segment, i) => {
    if (i + 1 !== menus.length) {
      return (
        <>
          <span className="rootLink"> {segment}</span>{' '}
          <KeyboardArrowRightIcon />
        </>
      );
    } else {
      return <span className="rootLink"> {segment}</span>;
    }
  });

  return menuElements;
};

export function parseDate(dateString) {
  // Split the date string into day, month, and year components
  // console.log('mmmmmmmmm :', dateString);
  const [day, month, year] = dateString.split('/').map(Number);
  // Create a new Date object (months are zero-indexed in JavaScript, so subtract 1 from the month)
  const parsedDate = new Date(year, month - 1, day);

  return parsedDate;
}

export const EventComponent = ({event}) => (
  <div
    style={{
      fontSize: '12px',
      color: 'black',
      width: '250px',
      fontSize: '15px',
      background: 'orange',
    }}>
    {/* Customize the styles of the content inside the event */}
    <p style={{margin: 0, fontWeight: 700}}>{event.title}</p>
    De {moment(event.start).format('hh:mm')} à{' '}
    {moment(event.end).format('hh:mm')}
  </div>
);

export function ObjectToHeading(obj) {
  return Object.entries(obj).map(([key, value]) => (
    <div key={key}>
      {/* <h2>{key}:</h2> */}
      {Array.isArray(value) ? (
        // If the value is an array, render each element in a paragraph
        value.map((item, index) => (
          <p key={index}>
            {key}: <span>{item}</span>
          </p>
        ))
      ) : typeof value === 'object' ? (
        // If the value is an object, recursively call renderObject
        ObjectToHeading(value)
      ) : (
        // Otherwise, render a paragraph with the key and value
        <p>
          {key}: <span>{value}</span>
        </p>
      )}
    </div>
  ));
}

export function findObjectsWithEmptyFields(data) {
  let results = [];
  let totalObjects = 0;
  let emptyObjects = 0;

  data.forEach((meal, dayIndex) => {
    // Check mealBreakFast
    checkMeal(meal.mealBreakFast, 'mealBreakFast', dayIndex);

    // Check mealLunch
    checkMeal(meal.mealLunch, 'mealLunch', dayIndex);

    // Check mealDinner
    checkMeal(meal.mealDinner, 'mealDinner', dayIndex);

    // Check mealSnacks
    checkMeal(meal.mealSnacks, 'mealSnacks', dayIndex);
  });

  const completenessPercentage = calculateCompletenessPercentage();
  return {results, completenessPercentage};

  function checkMeal(mealArray, mealName, dayIndex) {
    mealArray.forEach((item, mealIndex) => {
      totalObjects++;
      if (item.gender === '') {
        emptyObjects++;
        results.push({
          name: mealName,
          dayIndex: dayIndex,
          mealIndex: mealIndex,
        });
      } else if (item.mealType === '') {
        emptyObjects++;
        results.push({
          name: mealName,
          dayIndex: dayIndex,
          mealIndex: mealIndex,
        });
      } else if (item.description === '') {
        emptyObjects++;
        results.push({
          name: mealName,
          dayIndex: dayIndex,
          mealIndex: mealIndex,
        });
      }
    });
  }

  function calculateCompletenessPercentage() {
    if (totalObjects === 0) {
      return 100; // Empty array is considered 100% complete
    }
    const completeness = ((totalObjects - emptyObjects) / totalObjects) * 100;
    return completeness.toFixed(2);
  }
}

// export  function findObjectsWithEmptyFields(data) {
//   let results = [];

//   data[0].forEach((meal, index) => {

//       // Check mealBreakFast
//       checkMeal(meal.mealBreakFast, "mealBreakFast", index);

//       // Check mealLunch
//       checkMeal(meal.mealLunch, "mealLunch", index);

//       // Check mealDinner
//       checkMeal(meal.mealDinner, "mealDinner", index);

//       // Check mealSnacks
//       checkMeal(meal.mealSnacks, "mealSnacks", index);
//   });

//   return results ;

//   function checkMeal(mealArray, mealName, dayIndex) {
//     mealArray.forEach((item, mealIndex) => {
//         const totalFields = Object.keys(item).length - 1; // Exclude "weights"
//         const emptyFields = Object.values(item).filter(value => value === "").length - 1; // Exclude "weights"

//         const completionPercentage = ((data[0].length - emptyFields) / data[0].length) * 100;

//         results.push({
//             name: mealName,
//             dayIndex: dayIndex,
//             mealIndex: mealIndex,
//             completionPercentage: completionPercentage.toFixed(2)
//         });
//     });
//   }

// }

export function truncateString(inputString, maxLength) {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength - 3) + '...';
  }
  return inputString;
}

export function showToast(type, message, delay,pos) {
  if (type == 'error') {
    return toast.error(`${message}`, {
      className: 'foo-bar',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      // position: "",
      position: pos?pos:'bottom-center',
    })
    
  } else {
    return toast.success(`${message}`, {
      className: 'foo-bar',
      autoClose: delay ? delay : 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: 'light',
      position: 'bottom-center',
    })
  }
}

export function findEmptyField(jsonObject) {
  for (const field in jsonObject) {
    if (jsonObject.hasOwnProperty(field)) {
      const fieldValue = jsonObject[field];

      if (
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === ''
      ) {
        return fieldValue; // Return the first empty field value
      }
    }
  }
}

export function getDate15YearsAgo(currentDate) {
  const today = currentDate || new Date(); // Use the provided date or current date if not provided
  const pastDate = new Date(today); // Create a new date object to avoid modifying the original

  // Subtract 15 years from the current year
  pastDate.setFullYear(today.getFullYear() - 15);

  return pastDate;
}

export function Requeststatus(data) {
  switch (data) {
    case 'Pending': {
      return 'En cours';
    }
    case 'Declined': {
      return 'Refusé';
    }
    case 'Accepted': {
      return 'Accepté';
    }
    default:
      return '-';
  }
}

export const emojis = [
  {id: 1, emoji: '👋'},
  {id: 2, emoji: '🙋'},
  {id: 55, emoji: '💪'},
  {id: 56, emoji: '🔥'},
  {id: 57, emoji: '🤝'},
  {id: 58, emoji: '🎖️'},
  {id: 4, emoji: '💯'},
  {id: 3, emoji: '😀'},
  {id: 5, emoji: '😄'},
  {id: 6, emoji: '😁'},
  {id: 7, emoji: '😎'},
  {id: 8, emoji: '😅'},
  {id: 9, emoji: '🤣'},
  {id: 10, emoji: '😂'},
  {id: 11, emoji: '🙂'},
  {id: 12, emoji: '😉'},

  {id: 14, emoji: '🥰'},
  {id: 15, emoji: '😟'},
  {id: 16, emoji: '🙁'},

  {id: 18, emoji: '😯'},
  {id: 19, emoji: '😲'},
  {id: 20, emoji: '😳'},
  {id: 21, emoji: '🥺'},
  {id: 22, emoji: '😦'},

  {id: 24, emoji: '😨'},
  {id: 25, emoji: '😰'},
  {id: 26, emoji: '😥'},
  {id: 27, emoji: '😱'},

  {id: 29, emoji: '😣'},
  {id: 30, emoji: '😞'},
  {id: 31, emoji: '😓'},

  {id: 33, emoji: '😫'},
  {id: 34, emoji: '😤'},
];

export const diffDate = (date1, date2) => {
  var a = moment(date1);
  var b = moment(date2);
  // 1
  return a.diff(b, 'days');
};

export function getMonthNameInFrench(monthNumber) {
  // Check if the input is a valid month number
  if (monthNumber < 1 || monthNumber > 12) {
    return 'Invalid month number';
  }

  // Array of month names in French
  const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  // Get the corresponding month name
  const monthName = monthNames[monthNumber - 1];
  return monthName;
}

export function getLightColorForMonth(monthNumber) {
  // Check if the input is a valid month number
  if (monthNumber < 1 || monthNumber > 12) {
    return 'Invalid month number';
  }

  // Array of light colors associated with each month
  const monthLightColors = [
    '#FFCCCC', // Janvier (Light Red)
    '#CCCCFF', // Février (Light Blue)
    '#CCFFCC', // Mars (Light Green)
    '#1AACAC', // Avril (Light Yellow)
    '#FFD699', // Mai (Light Orange)
    '#FFCCE6', // Juin (Light Pink)
    '#E6E6FF', // Juillet (Light Purple)
    '#D2B48C', // Août (Light Brown)
    '#E0FFFF', // Septembre (Light Cyan)
    '#FFCCFF', // Octobre (Light Magenta)
    '#008080', // Novembre (Teal)
    '#D3D3D3', // Décembre (Light Grey)
  ];

  // Get the corresponding light color for the month
  const monthLightColor = monthLightColors[monthNumber - 1];
  return monthLightColor;
}

export const months = [
  {
    month: 1,
  },
  {
    // _id: '',
    month: 2,
  },
  {
    month: 3,
  },
  {
    month: 4,
  },
  {
    month: 5,
  },
  {
    month: 6,
  },
  {
    month: 7,
  },
  {
    month: 8,
  },
  {
    month: 9,
  },
  {
    month: 10,
  },
  {
    month: 11,
  },
  {
    month: 12,
  },

];



export const fakeData = {
  "userWeights": [
    {
        "_id": 1,
        "week": 1,
        "weight": 75.9,
        "weightUnit": "KG",
        "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 2,
      "week": 2,
      "weight": 76.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 3,
      "week": 3,
      "weight": 77.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 4,
      "week": 4,
      "weight": 70.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 5,
      "week": 5,
      "weight": 73.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
  }
],
}

export function findMin(arr) {
  if (arr.length === 0) {
    return undefined; // Return undefined if array is empty
  }
  
  let mix = arr[0]; // Initialize max with the first element of the array
  
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < mix) {
      mix = arr[i]; // Update max if a larger value is found
    }
  }
  // console.log(arr);
  return mix;
}


export function findMax(arr) {
  if (arr.length === 0) {
    return undefined; // Return undefined if array is empty
  }
  
  let max = arr[0]; // Initialize max with the first element of the array
  
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i]; // Update max if a larger value is found
    }
  }
  // console.log(arr);
  return max;
}

export const mealTfrench = (meal) => {
  switch (meal) {
    case "mealSnacks": return "Grignotages"; break;
    case "mealLunch":return "Déjeuner"; break;
    case "mealDinner":return "Dîner"; break;
    case "mealBreakFast": return "Petit déjeuner"; break;
    default:""
    
  }
}
export const mealToId = (meal) => {
  switch (meal) {
    case "mealSnacks": return "gri-"; break;
    case "mealLunch":return "dej-"; break;
    case "mealDinner":return "din-"; break;
    case "mealBreakFast": return "ptt-"; break;
    default:""
    
  }
}

export const  checkEmptyProperties=(data)=> {
  return data?.some(item => {
    return !item.description || !item.gender || !item.mealType ||
           item.description === '' || item.gender === '' || item.mealType === '';
  });
}


export const preProcessingData = () => {
  [
    {
      lte: 6,
      color: 'green'
    },
    {
      gt: 6,
      lte: 8,
      color: 'red'
    },
    
     ]
}


export const userWeights = [
  {
      "_id": 1,
      "week": 1,
      "weight": 60.3,
      "weightUnit": "KG",
      "inv": false,
      "createdAt": "2022-04-22T14:50:42.966Z"
  },
  {
      "_id": 2,
      "week": 2,
      "weight": 59.5,
      "inv": false,
      "weightUnit": "KG",
      "createdAt": "2022-04-06T22:00:00.000Z"
  },
  {
      "_id": 3,
      "week": 3,
      "weight": 58.5,
      "inv": false,
      "weightUnit": "KG",
      "createdAt": "2022-04-13T22:00:00.000Z"
  },
  {
      "_id": 4,
      "week": 4,
      "weight": 57.1,
      "weightUnit": "KG",
      "inv": true,
      "createdAt": "2002-02-19T23:00:00.000Z"
  },
  {
      "_id": 5,
      "week": 5,
      "weight": 46.5,
      "weightUnit": "KG",
      "inv": false,
      "createdAt": "2022-04-27T22:00:00.000Z"
  },
  {
      "_id": 6,
      "week": 6,
      "inv": false,
      "weight": 45.6,
      "weightUnit": "KG",
      "createdAt": "2022-05-04T22:00:00.000Z"
  },
  {
    "_id": 7,
    "week": 7,
    "inv": false,
    "weight": 45.6,
    "weightUnit": "KG",
    "createdAt": "2022-05-04T22:00:00.000Z"
  },
  {
    "_id": 8,
    "week": 8,
    "inv": false,
    "weight": 45.6,
    "weightUnit": "KG",
    "createdAt": "2022-05-04T22:00:00.000Z"
  },
  {
    "_id": 9,
    "week": 9,
    "inv": false,
    "weight": 45.6,
    "weightUnit": "KG",
    "createdAt": "2022-05-04T22:00:00.000Z"
}
];

export const  generateConditions=(data)=> {
  const conditions = [];
  let prevInv = false;
  let currentRange = {};

  data.forEach(entry => {
      if (entry.inv) {
          if (!prevInv) {
              if (currentRange.gt !== undefined) {
                  conditions.push({ ...currentRange, color: 'red' });
                  currentRange = {};
              }
              currentRange.lte = entry.week;
          }
          prevInv = true;
      } else {
          if (prevInv) {
              if (currentRange.lte !== undefined) {
                  conditions.push({ ...currentRange, color: 'orange' });
                  currentRange = {};
              }
              currentRange.gt = entry.week;
          }
          prevInv = false;
      }
  });

  if (Object.keys(currentRange).length !== 0) {
      conditions.push({ ...currentRange, color: prevInv ? 'red' : 'orange' });
  }
console.log(conditions);
  return conditions;
}



export function formatDate(dateString) {
  const parts = dateString.split('-');
  if (parts.length !== 3) {
      return "Invalid date format. Please provide date in the format DD-MM-YYYY.";
  }
  const day = String(parts[0]).padStart(2, '0');
  const month = String(parts[1]).padStart(2, '0');
  const year = parts[2];
  
  const parsedDate = new Date(`${year}-${month}-${day}`);
  if (isNaN(parsedDate)) {
      return "Invalid date. Please provide a valid date.";
  }

  return `${year}-${month}-${day}`;
}


function allTrueOrFalse(arr) {
  // Check if the array is empty
  if (arr.length === 0) {
    return false;
  }

  // Check if all elements are true
  const allTrue = arr.every(element => element === true);

  // Check if all elements are false
  const allFalse = arr.every(element => element === false);

  // Return true if all elements are either true or false, otherwise false
  return allTrue || allFalse;
}





export function generateIntervalsAll(arr,inv,col1,col2) {
  if (!allTrueOrFalse(arr)) {
    console.log(col1);
    console.log(col2);
  const intervals = [];
  let start = 0;
  let color =  col1;

  for (let i = 1; i <= arr.length; i++) {
      if (arr[i] !== arr[i - 1] || i === arr.length) {
          const interval = {};
          if (color === col1) {
              interval.lt = i;
              interval.color = inv?col2:col1;
          } else {
              interval.gt = start;
              interval.lt = i;
              interval.color = inv?col1: col2;
          }
          intervals.push(interval);
          start = i;
          color = color === col1 ? col2 : col1;
      }
  }

  // Adjusting intervals with both gt and lt
  for (let i = 1; i < intervals.length; i++) {
      intervals[i] = { ...intervals[i], gt: intervals[i - 1].lt };
  }

    return intervals;
  } else {
    return [
      {
        lte: arr.length,
        color: !inv?col1:col2
      },
      {
        gt: arr.length,
        lte: arr.length+1,
        color: !inv?col1:col2
      },
    ]

  }
}


export function generateIntervalsW(arr,inv) {
  if(!allTrueOrFalse(arr)){
  const intervals = [];
  let start = 0;
  let color =  'orange';

  for (let i = 1; i <= arr.length; i++) {
      if (arr[i] !== arr[i - 1] || i === arr.length) {
          const interval = {};
          if (color === 'orange') {
              interval.lt = i;
              interval.color = inv?'blue':'or';
          } else {
              interval.gt = start;
              interval.lt = i;
              interval.color = inv?'orange': 'blue';
          }
          intervals.push(interval);
          start = i;
          color = color === 'orange' ? 'blue' : 'orange';
      }
  }

  // Adjusting intervals with both gt and lt
  for (let i = 1; i < intervals.length; i++) {
      intervals[i] = { ...intervals[i], gt: intervals[i - 1].lt };
  }

    return intervals;
  } else {
    return [
      {
        lte: arr.length,
        color: inv?'blue':'orange'
      },
      {
        gt: arr.length,
        lte: arr.length+1,
        color: inv?'blue':'orange'
      },
    ]

  }
}





export function generateIntervals(arr,from) {
  const intervals = [];
  console.log(arr);
  let start = 0;
  let color = 'orange';

  for (let i = 1; i <= arr.length; i++) {
      if (arr[i] !== arr[i - 1] || i === arr.length) {
          const interval = {};
          if (color === 'orange') {
              interval.lt = i;
              interval.color = from?'#ADE498':'orange';
          } else {
              interval.gt = start;
              interval.lt = i;
              interval.color = from?'#FB6D48': '#40A2E3';
          }
          intervals.push(interval);
          start = i;
          color = color === 'orange' ? '#40A2E3' : 'orange';
      }
  }

  // Adjusting intervals with both gt and lt
  for (let i = 1; i < intervals.length; i++) {
      intervals[i] = { ...intervals[i], gt: intervals[i - 1].lt };
  }

  return intervals;
}


export const tagoToMessage = (tag) => {
  // console.log(tag);
  switch (tag){
case "CHANGE_ACCOUNT_BACKOFFICE": return " a demandé de changer la prestation 🔄"
case "VACATION_TO_BACKOFFICE": return " a changé vers le mode vacance ✈️"
case "MESSAGE_BACKOFFICE": return " vous envoie un message 🗨️"
  }
}



export const titleToMessage = (tag) => {
  // console.log(tag);
  switch (tag){
case "CHANGE_ACCOUNT_BACKOFFICE": return "Demandes"
    case "VACATION_TO_BACKOFFICE": return "Vacances"
    case "MESSAGE_BACKOFFICE":return "Messages"
  }
}


export const gotToLink = (tag,targetId) => {
  console.log(tag);
  switch (tag){
case "CHANGE_ACCOUNT_BACKOFFICE": return "/mes-demandes"
case "MESSAGE_BACKOFFICE": return "/messagerie?id="+ targetId
// case "VACATION_TO_BACKOFFICE": return "/mes-demandes"
  }
}

export function applyChecker2(weight, checker) {
  console.log("mm",weight, );
  console.log("mm",checker);
  const result = [];
  let lastTrueWeight = null;
  let sw=false
let j=0
  for (let i = 0; i < checker.length; i++) {
    if (j <= weight.length) {
      
    
    // console.log("ff",i,j);
      if (checker[i] == true) {
        sw=false
          lastTrueWeight = weight[j];
          result.push(weight[j])
          j++
      }
      else {
        sw=true
        if (!sw) {    
          result.push(lastTrueWeight)
        } else {
          result.push(weight[j])
          sw=true
          
          }
      }
      
  }
}
  // Remove trailing undefined values
  
console.log(result);
  return result;
}


export function applyChecker(weight, checker) {
  console.log("mm",weight, );
  console.log("mm",checker);
  const result = [];
  let lastTrueWeight = null;
  let sw=false
let j=0
  for (let i = 0; i < checker.length; i++) {
    if (j <= weight.length) {
      
    
    // console.log("ff",i,j);
      if (checker[i] == true) {
        sw=false
          lastTrueWeight = weight[j];
          result.push(weight[j])
          j++
      }
      else {
        if (!sw &&checker[0]!==false) {    
          result.push(lastTrueWeight)
        } else {
          result.push(weight[j])
          sw=true
          
          }
      }
      
  }
}
  // Remove trailing undefined values
  
console.log(result);
  return result;
}

export function checkSuccessive(arr) {
  var res = [];
  for (var i = 0; i < arr.length ; i++) {
      // console.log(i)
    if ((arr[i] === arr[i - 1] ) ||(i==0)) {
      res.push(true);
    } else {
    res.push(false);
    }
  
  }
  return res;
}


export function expandArray(arr, checker) {
  console.log(arr);
  console.log(checker);
  var result = [];
var currentIndex = 0;


for (var i = 0; i < checker.length-1; i++) {
    if(i==0){
         result.push(arr[currentIndex])
    }
   if(checker[i]==checker[i+1]){
       result.push(arr[currentIndex])
   }else{
        result.push(arr[currentIndex+1])
    currentIndex++
       
   }
  }
  return result     

}


export function applyColorWO(weight,from) {
  let c=weight[0]
  let res=[]
  let x = from?true:false
  for( let i =0 ;i<weight.length ;i++){
      if(c==weight[i]){
          res.push(x)
      } else{
          c=weight[i]
          x=!x
          res.push(x)
      }
     
  }
       return res
   }

export function removeDuplicateMeals(meals) {
    const uniqueMeals = {};
  
    // Iterate through the array
    meals.forEach(meal => {
      // Store the meal in the uniqueMeals object using the meal_date as the key
      uniqueMeals[meal.meal_date] = meal;
    });
  
    // Convert the object back to an array of unique meals
    const uniqueMealsArray = Object.values(uniqueMeals);
  
    return uniqueMealsArray;
}
function getWeekNumber(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

function getFirstDayOfWeek(date) {
  var day = date.getDay(),
      diff = date.getDate() - day + (day == 0 ? -5:1); // adjust when day is sunday
  return new Date(date.setDate(diff));
}  


function getFirstDayOfWeekMoment(date) {
  console.log(date);
  // Create a moment object from the input date
  const momentDate = moment(date.toISOString().toString().split('T')[0]).locale('fr');
  
  // Set the moment object to the start of the week (Monday)
  const firstDayOfWeek = momentDate.startOf('Week');
  console.log(firstDayOfWeek);
  
  // Return the first day of the week
  return firstDayOfWeek;
}

export function getStartOfWeeksOfYearFromNow2v(td,last) {
  // console.log('td',td);
 // console.log(fin);
 let fin =  getFirstDayOfWeek(new Date()).toISOString().toString().split('T')[0]
//  console.log(fin);
 const today = new Date(td);
 let stop = false;
 const currentYear = today.getFullYear();
 const currentWeek = getWeekNumber(today);
 // console.log(currentWeek);
 const weeks = [];
 let mealWeek = 0;
 // Loop through each week of the year starting from the current week
 for (let i = currentWeek; i <= 52; i++) {
   mealWeek++;
   const date = new Date(currentYear, 0, 8 + (i - 2) * 7);
   const dd = String(date.getDate()).padStart(1, '0');
   const mm = String(date.getMonth() + 1).padStart(1, '0');
   const weekStart = `${date.getFullYear()}-${
     mm.length == 1 ? '0' + mm : mm
   }-${dd.length == 1 ? '0' + dd : dd}`;
   
        weeks.push(weekStart);
   
   }
  
   console.log(fin);
  const finnalo=weeks.indexOf(fin)
  const finnalo2=weeks.indexOf(last)
 console.log(finnalo);
 console.log("www",last);
   return  finnalo!=-1?weeks.slice(0,finnalo+1):weeks.slice(0,finnalo2+1);
 
}

export function cleanObject(obj) {
  const propertiesToDelete = ['accountType', 'id', 'createdAt', 'userWeights', 'goal', 'type'];
  
  propertiesToDelete.forEach(prop => {
      delete obj[prop];
  });

  return obj;
}

export const all = [

  {
    "_id": 13848,
    "weight": 88,
    "weightUnit": "KG",
    "week": 1,
    "userId": 1563,
    "weightRef": true,
    "creationDate": "2024-05-13T00:00:00.000Z",
    "nextDateRef": "2024-05-21T00:00:00.000Z",
    "goal": "losing weight",
    "meal_week": 1,
    "createdAt": "2024-04-30T10:36:30.185Z",
    "updatedAt": "2024-04-30T10:36:30.185Z",
    "startOfWeek": "2024-05-13T00:00:00.000Z",
    "endOfWeek": "2024-05-19T00:00:00.000Z",
    "weekNumberAgenda": 20,
    "year": 2024,
    "deleted": false,
    "deletedAt": null,
    "__v": 0,
    "meal_date_without": "2024-05-15",
    "meal_date": "2024-05-29"
},

//   {
//     "_id": 13848,
//     "weight": 66,
//     "weightUnit": "KG",
//     "week": 1,
//     "userId": 1563,
//     "weightRef": true,
//     "creationDate": "2024-05-13T00:00:00.000Z",
//     "nextDateRef": "2024-05-21T00:00:00.000Z",
//     "goal": "losing weight",
//     "meal_week": 1,
//     "createdAt": "2024-04-30T10:36:30.185Z",
//     "updatedAt": "2024-04-30T10:36:30.185Z",
//     "startOfWeek": "2024-05-13T00:00:00.000Z",
//     "endOfWeek": "2024-05-19T00:00:00.000Z",
//     "weekNumberAgenda": 20,
//     "year": 2024,
//     "deleted": false,
//     "deletedAt": null,
//     "__v": 0,
//     "meal_date_without": "2024-05-15",
//     "meal_date": "2024-04-22"
// },
  {
      "_id": 13848,
      "weight": 88,
      "weightUnit": "KG",
      "week": 1,
      "userId": 1563,
      "weightRef": true,
      "creationDate": "2024-05-13T00:00:00.000Z",
      "nextDateRef": "2024-05-21T00:00:00.000Z",
      "goal": "losing weight",
      "meal_week": 1,
      "createdAt": "2024-04-30T10:36:30.185Z",
      "updatedAt": "2024-04-30T10:36:30.185Z",
      "startOfWeek": "2024-05-13T00:00:00.000Z",
      "endOfWeek": "2024-05-19T00:00:00.000Z",
      "weekNumberAgenda": 20,
      "year": 2024,
      "deleted": false,
      "deletedAt": null,
      "__v": 0,
      "meal_date_without": "2024-05-15",
      "meal_date": "2024-04-08"
  },
  {
      "_id": 13847,
      "weight": 89.5,
      "weightUnit": "KG",
      "week": 2,
      "userId": 1563,
      "weightRef": true,
      "creationDate": "2024-05-07T00:00:00.000Z",
      "nextDateRef": "2024-05-14T00:00:00.000Z",
      "goal": "improving health",
      "meal_week": 2,
      "createdAt": "2024-04-30T09:57:31.453Z",
      "updatedAt": "2024-04-30T09:57:31.453Z",
      "startOfWeek": "2024-05-06T00:00:00.000Z",
      "endOfWeek": "2024-05-12T00:00:00.000Z",
      "weekNumberAgenda": 19,
      "year": 2024,
      "deleted": false,
      "deletedAt": null,
      "__v": 0,
      "meal_date_without": "2024-05-07",
      "meal_date": "2024-03-25"
  },
  {
      "_id": 13841,
      "weight": 90.5,
      "weightUnit": "KG",
      "week": 1,
      "userId": 1563,
      "weightRef": true,
      "creationDate": "2024-04-29T00:00:00.000Z",
      "nextDateRef": "2024-05-07T00:00:00.000Z",
      "goal": "improving health",
      "meal_week": 1,
      "createdAt": "2024-04-29T08:57:06.944Z",
      "updatedAt": "2024-04-29T08:57:06.944Z",
      "startOfWeek": "2024-04-29T00:00:00.000Z",
      "endOfWeek": "2024-05-05T00:00:00.000Z",
      "weekNumberAgenda": 18,
      "year": 2024,
      "deleted": false,
      "deletedAt": null,
      "__v": 0,
      "meal_date_without": "2024-04-30",
      "meal_date": "2024-03-18"
  },
  {
      "_id": 13841,
      "weight": 90.5,
      "weightUnit": "KG",
      "week": 1,
      "userId": 1563,
      "weightRef": true,
      "creationDate": "2024-04-29T00:00:00.000Z",
      "nextDateRef": "2024-05-07T00:00:00.000Z",
      "goal": "improving health",
      "meal_week": 1,
      "createdAt": "2024-04-29T08:57:06.944Z",
      "updatedAt": "2024-04-29T08:57:06.944Z",
      "startOfWeek": "2024-04-29T00:00:00.000Z",
      "endOfWeek": "2024-05-05T00:00:00.000Z",
      "weekNumberAgenda": 18,
      "year": 2024,
      "deleted": false,
      "deletedAt": null,
      "__v": 0,
      "meal_date_without": "2024-04-30",
      "meal_date": "2024-03-11"
  },
  {
      "_id": 13841,
      "weight": 90.5,
      "weightUnit": "KG",
      "week": 1,
      "userId": 1563,
      "weightRef": true,
      "creationDate": "2024-04-29T00:00:00.000Z",
      "nextDateRef": "2024-05-07T00:00:00.000Z",
      "goal": "improving health",
      "meal_week": 1,
      "createdAt": "2024-04-29T08:57:06.944Z",
      "updatedAt": "2024-04-29T08:57:06.944Z",
      "startOfWeek": "2024-04-29T00:00:00.000Z",
      "endOfWeek": "2024-05-05T00:00:00.000Z",
      "weekNumberAgenda": 18,
      "year": 2024,
      "deleted": false,
      "deletedAt": null,
      "__v": 0,
      "meal_date_without": "2024-04-30",
      "meal_date": "2024-02-05"
  }
]
