import {TextareaAutosize} from '@mui/material';
import {Button} from 'antd';
import moment from 'moment';
import React, {useState} from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardBody,
  Spinner,
} from 'reactstrap';

const ShowContact = ({showPushNotification, onHide, data}) => {
  return (
    <Modal size="lg" isOpen={showPushNotification} toggle={onHide}>
      <ModalHeader
        toggle={onHide}
        close={
          <button className="close" onClick={onHide}>
            &times;
          </button>
        }>
        <p style={{margin:"0 0 0 10px", fontWeight:"700",fontStyle:"italic",padding:0}}> {data?.from_user?.firstName} {data?.from_user?.lastName}</p>
      </ModalHeader>

      <ModalBody>
        <Card style={{paddingBottom: '4rem'}} className="card-container">
          <CardBody>
            <ul className="user-info-container">
              <li>
                <label>En vacance</label>
                <p>{data?.from_user?.vacation ? 'Oui' : 'Non'}</p>
              </li>
              <li>
                <label>Email</label>
                <p>{data?.from_user?.email}</p>
              </li>
              <li>
                <label>Téléphone</label>
                <p>{data?.from_user?.phoneNumber}</p>
              </li>
              <li>
                <label>Adresse, Code Postale</label>
                <p>
                  {data?.from_user?.address}
                  {data?.from_user?.address ? ',' : ''}{' '}
                  {data?.from_user?.zipCode}
                </p>
              </li>
              <li>
                <label>Ville, Pays</label>
                <p>
                  {data?.from_user?.city ? data?.from_user?.city : ''}{' '}
                  {data?.from_user?.country ? data?.from_user?.country : ''}
                </p>
              </li>
              <li>
                <label>Date d’inscription </label>
                <p>{moment(data?.from_user?.createdAt).format('DD/MM/YYYY')}</p>
              </li>
              <li>
                <label>Date de naissance</label>
                <p>{moment(data?.from_user?.birthDay).format('DD/MM/YYYY')}</p>
              </li>
            </ul>
          <span style={{ fontWeight:"700",margin:"50px 0",padding:0}}>Question : </span>
                      <TextareaAutosize
                          disabled={true}
                          style={{
                              marginTop: "20px",
                              fontStyle: "italic",
                          width:"100%"
                          }}
              value={data?.message}
              className="text-area-style"
            />
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide} style={{marginLeft: 'auto', display: 'block'}}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowContact;
