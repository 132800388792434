import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import AppHeader from 'Layout/AppHeader/';
import AppSidebar from 'Layout/AppSidebar/';
import AppFooter from 'Layout/AppFooter/';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {MySpinner} from 'components/atoms';
import {debounce} from 'lodash';
import {Datatable, Collaborator, Appointments} from 'components/organisms';
import {Button, Divider, Input} from 'antd';
import bg from '../../shared/bg2.png';
import profile from '../../shared/profile2.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import messageHere from '../../shared/msg-here.jpg';
import {
  Row,
  // Button,
  Form,
  CardBody,
  Card,
  Spinner,
  Input as Input2,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
} from 'reactstrap';
import CenterFormApi from 'services/api/CenterFormApi';
import history from 'services/history';
import moment from 'moment';
import {customStylesTable, style} from 'shared/customTable';
import iconUser from 'shared/user.png';
import Diagnosis from 'pages/User/Diagnosis';
import UserProfile from '../UserProfile/index';
import {
  EyeOutlined,
  LeftOutlined,
  LoadingOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  chatArray,
  chatData,
  demands,
  returnBGcolor,
  returnStatement,
} from 'shared/data';
import {diffDate, emojis, truncateString} from 'shared/extra';
import TextArea from 'antd/es/input/TextArea';
import soundUrl from '../../shared/bub.mp3';
import newMsgsoundUrl from '../../shared/cute_sound.mp3';
// import PusherApp from 'shared/pusher';

import Pusher from 'pusher-js';
import {border} from '@mui/system';
import UserChatPanel from 'components/organisms/messagerieComponent';
import {Howl} from 'howler';
import { AuthContext } from 'services';


export  function getQueryVariable(variable)
{
        var query = window.location.search.substring(1);
        // console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
}

const ChatPage = () => {
  // console.log(getQueryVariable('id'));
  const targetContext = useContext(AuthContext);
  const {loggedIn, user_data} = targetContext.state;

  const sound = new Howl({
    src: [soundUrl],
  });
  const newMsgsound = new Howl({
    src: [newMsgsoundUrl],
  });
  const [arrayData, setArrydata] = useState([]);
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const [listOfUser, setListOfUser] = useState([]);
  const [userNamesearch, setUserNamesearch] = useState('');
  const [channelId, setchannelId] = useState('');
  const [inputValue, setinputValue] = useState('');
  const [keys, setKey] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [singleUser, setSingleUser] = useState({});
  const [showConversation, setshowConversation] = useState({
    id: 0,
    firstName: '',
    lastName: '',
    messageList: [],
  });
  const [emptyConversation, setemptyConversation] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [newListeMessage, setnewListeMessage] = useState([]);
  const chatBoxRef = useRef(null);
  const divRef = useRef();

  const sendMessageToServer = async (newMessage, channelId) => {
    console.log('sendit');
    const res = await CenterFormApi.post(`/api/back-office/send-message`, {
      channelId: channelId,
      message: newMessage,
    });
  };

  const sendMessage = (id, channelId) => {
    if (newMessage === '') {
      alert('Veuillez saisir un message');
    } else {
      const newCannel = channelId ? channelId : id;
      let newmsg = {
        message: newMessage,
        channelId: newCannel,
        from_user: false,
        createdAt: new Date(),
      };
      newListeMessage.unshift(newmsg);
      // console.log(newListeMessage);
      setnewListeMessage(pre => [...newListeMessage]);
      sendMessageToServer(newMessage, newCannel);

      setNewMessage('');
    }
  };

  const getUserDate = async (id) => {
 
  }
    // 1352

    useEffect(() => {
      // console.log(getQueryVariable('id'));
     
      setArrydata(chatArray);
      initialUserListe('');
  
      // fetchCollaborators(1, 10, sort, search); // fetch page 1 of users
    }, []);
  
  
  useEffect(() => {
    // console.log(getQueryVariable('id'));
    // if (getQueryVariable('id')) {
    //   console.log("here");
    //   getUserDate(getQueryVariable('id'))
    // }
    setArrydata(chatArray);
    initialUserListe('');

    // fetchCollaborators(1, 10, sort, search); // fetch page 1 of users
  }, []);

  useEffect(() => {
    const PusherApp = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true,
      secret: process.env.REACT_APP_PUSHER_SECRET,
      appId: process.env.REACT_APP_PUSHER_APPID,
    });

    const channel = PusherApp.subscribe(channelId.toString());
    channel.bind('new message', function (data) {
      if (data.from != 'admin') {
        let newmsg = {
          message: data.message,
          channelId: channelId,
          from_user: true,
          createdAt: new Date(),
        };
        newListeMessage.unshift(newmsg);
        // console.log(newListeMessage);
        setnewListeMessage(pre => [...newListeMessage]);
        newMsgsound.play();
      }
      // Update your component state or perform actions based on the received data
    });

    return () => {
      PusherApp.unsubscribe(channelId);
      PusherApp.disconnect();
    };
  }, [channelId]);

  useEffect(() => {
    // Scroll to the bottom of the chat box when messages change
    // if (chatBoxRef.current) { divRef.current.scrollTo(0, chatBoxRef.current.scrollHeight); }
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollIntoView({behavior: 'smooth'});
      // .scrollIntoView({ behavior: 'smooth' });
    }
  }, [newMessage, showConversation]);

  const handleClick = emoji => {
    setNewMessage(newMessage + emoji);
  };

  const initialUserListe = async () => {
    let single={}
    if (getQueryVariable('id')) {
      console.log("here", getQueryVariable('id'));
      const res = await CenterFormApi.get(`/userData/${getQueryVariable('id')}`)
     single=res.data
      // setSingleUser(res.data)
      // getUserDate()
    }
    // console.log('here');
    if (user_data.type != 'admin') {
      const resForColl = await CenterFormApi.get(
        `/fetchCollaboratorClients?collaboratorId=${
          user_data._id || user_data.id
        }&page=1&limit=10&sort={%22_id%22:-1}`,
      );

      const users = resForColl.data.data.map(data => {
        return {
          id: data._id,
          firstName: `${data.firstName}`,
          lastName: `${data.lastName}`,
          messageList: [],
        };
      });
      setLoading(false);
      setListOfUser(users);
      setTotal(resForColl.data.recordsTotal);
    } else {
      let users
      setLoading(true);
      if (getQueryVariable('id')) {
        users=[{
         ...single,
          messageList: [],
        }];
        // console.log(users);
        setLoading(false);
        setListOfUser(users);
        setTotal(1);
      } else {
      const listOfUsers = await CenterFormApi.get(
        `api/back-office/fetch-users?page=${page}&size=${10}&fromChat=${true}`,
      );

       users = listOfUsers.data.data.map(data => {
        return {
          id: data._id,
          firstName: `${data.firstName}`,
          lastName: `${data.lastName}`,
          messageList: [],
        };
      });
      setLoading(false);
      setListOfUser(users);
      setTotal(listOfUsers.data.total);
      }
    }
  };
// console.log(singleUser);
  const getUserPerMsg = async data => {
    setLoadingChange(true);
    console.log('afterOncklick item ===> :', data);
    const listOfMsgPerUser = await CenterFormApi.post(
      `api/back-office/get-user-messages`,
      {
        userId: data.id,
        limit: 20,
        page: 1,
      },
    );

    // console.log('voila : ', listOfMsgPerUser.data.data.messages);
    setnewListeMessage([]);
    setLoadingChange(false);
    setnewListeMessage(listOfMsgPerUser.data.data.messages);
    listOfMsgPerUser.data.data.messages.length > 0
      ? setemptyConversation(false)
      : setemptyConversation(true);

    setshowConversation(data);
    // console.log('ggg', listOfMsgPerUser.data.data.channelId);
    setchannelId(listOfMsgPerUser?.data.data.channelId);
  };

  // console.log("channelId",channelId);
  const getUsers = async serchVal => {
    if (user_data.type != 'admin') {
      setshowConversation({
        id: 0,
        firstName: '',
        lastName: '',
        messageList: [],
      });
      setUserNamesearch(serchVal);
      setLoading(true);
      const resForColl = await CenterFormApi.get(
        `/fetchCollaboratorClients?collaboratorId=${
          user_data._id || user_data.id
        }&page=1&limit=10&sort={%22_id%22:-1}&search=${serchVal}`,
        // {
        //   searchQuery: serchVal,
        //   collaboratorId: user_data._id || user_data.id,
        // },
      );

      const users = resForColl.data.data.map(data => {
        return {
          id: data._id,
          firstName: `${data.firstName}`,
          lastName: `${data.lastName}`,
          messageList: [],
        };
      });
      setLoading(false);
      setListOfUser(users);
    } else {
      setshowConversation({
        id: 0,
        firstName: '',
        lastName: '',
        messageList: [],
      });
      setUserNamesearch(serchVal);
      setLoading(true);
      const listOfUsers = await CenterFormApi.get(
        `api/back-office/fetch-users?search=${serchVal}&page=${page}&size=${10}&fromChat=${true}`,
      );

      const users = listOfUsers.data.data.map(data => {
        return {
          id: data._id,
          firstName: `${data.firstName}`,
          lastName: `${data.lastName}`,
          messageList: [],
        };
      });
      setLoading(false);
      setListOfUser(users);
      setTotal(listOfUsers.data.total);
    }
  };

  const handleScroll = async () => {
    const divElement = scrolDiv.current;

    if (divElement) {
      // Calculate the difference between the scroll position and the bottom of the content
      const isAtBottom =
        divElement.scrollHeight -
          (divElement.scrollTop + divElement.clientHeight) <=
        1;

      if (isAtBottom) {
        if (total > listOfUser.length) {
          setLoadingScroll(true);
          let newlistOfUsers;
          if (!inputValue.length > 0) {
            // console.log('withOutsearch');
            if (user_data.type != 'admin') {
              newlistOfUsers = await CenterFormApi.get(
                `/fetchCollaboratorClients?collaboratorId=${
                  user_data._id || user_data.id
                }&page=1&limit=10&sort={%22_id%22:-1}`,
                // {
                //   searchQuery: serchVal,
                //   collaboratorId: user_data._id || user_data.id,
                // },
              );
            } else {
              newlistOfUsers = await CenterFormApi.get(
                `api/back-office/fetch-users?page=${
                  page + 1
                }&size=${10}&fromChat=${true}`,
              );
            }
          } else {
            // console.log('withsearch');

            if (user_data.type != 'admin') {
              newlistOfUsers = await CenterFormApi.get(
                `/fetchCollaboratorClients?collaboratorId=${
                  user_data._id || user_data.id
                }&page=1&limit=10&sort={%22_id%22:-1}&search=${inputValue}`,
                // {
                //   searchQuery: serchVal,
                //   collaboratorId: user_data._id || user_data.id,
                // },
              );
            } else {
              newlistOfUsers = await CenterFormApi.get(
                `api/back-office/fetch-users?search=${inputValue}&page=${
                  page + 1
                }&size=${10}fromChat=${true}`,
              );
            }
          }
          // console.log('newUsersListe ', newlistOfUsers);
          const users = newlistOfUsers.data.data.map(data => {
            return {
              id: data._id,
              firstName: `${data.firstName}`,
              lastName: `${data.lastName}`,
              messageList: [],
            };
          });
          const newDataArray = [...listOfUser, ...users];
          setListOfUser(newDataArray);
          const val=user_data.type != 'admin'?newlistOfUsers?.data?.total:newlistOfUsers?.data?.recordsTotal
          setTotal(val);

          setPage(page + 1);
          console.log('Scrolled to the bottom!');
        } else {
          console.log('Scrolled to the bottom! finishid');
        }

        // Do something when scrolled to the bottom
      }
    }
  };

  const scrolDiv = useRef(null);

  useEffect(() => {
    const divElement = scrolDiv.current;

    if (divElement) {
      // Attach the scroll event listener
      divElement.addEventListener('scroll', handleScroll);

      // Remove the event listener when the component is unmounted
      return () => {
        divElement.removeEventListener('scroll', handleScroll);
      };
    }
  });

  return (
    <Fragment>
      <div
        style={{
          // height: 'fit-content',
          // border: "2px solid red",
          overflow: 'none',
          padding: '0 25px',
        }}>
        <div>
          <div>
            {/* <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}> */}
              
              <Row>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    gap: 10,

                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}>
                 {!getQueryVariable('id')&& <div
                    style={{borderTop: '5px solid #BBE2EC'}}
                    className="statstics-card2">
                    <label> Votre conversation</label>
                    <p></p>
                  </div>}
                  {/* <div
                    style={{borderTop: '5px solid green'}}
                    className="statstics-card2">
                    <label>Messages répondus</label>
                    <p>-/-</p>
                  </div>
                  <div
                    style={{borderTop: '6px solid #363062'}}
                    className="statstics-card2">
                    <label>Messages non répondus</label>
                    <p>-/-</p>
                  </div> */}
                </div>
              </Row>

              <Row style={{}}>
                <Card className="card-containerDme">
                  <CardBody className="users-table">
                    <Row>
                      <span style={{fontWeight: 700}}>
                       {!getQueryVariable('id') ?"Liste des utilisateurs":""} 
                      </span>
                     {!getQueryVariable('id')&& <Input
                        placeholder="Rechercher utilisateur"
                        style={{
                          height: '30px',
                          margin: '10px 40px',
                          width: showConversation.id != 0 ? '28%' : '95%',
                        }}
                        value={inputValue}
                        onChange={e => {
                          setinputValue(e.target.value);
                          getUsers(e.target.value);
                        }}
                    />}
                    {getQueryVariable('id') && <span
                      onClick={() => {
                        // history.push('/messagerie'),
                        document.location.href='/messagerie'
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = 'orange';
                        // e.target.style.border = '2px solid rgb(31, 0, 69)';
                        // e.target.style.boxShadow = '-2px 0px 7px 2px #e13570';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "white";
                        // e.target.style.border = '2px solid #593676';
                        // e.target.style.boxShadow = '-2px 0px 7px 2px #e13570';
                    }}
                      style={{
                        backgroundColor:"white",
                        width: "150px",
                        marginLeft:"10px",
                        cursor: "pointer", fontWeight: 700
                      }}> <LeftOutlined /> Retour </span>}
                    </Row>
                    <div style={{}} className="show-demande-container">
                      <div
                        ref={scrolDiv}
                        className="show-demande-sub-container-left"
                        style={{
                          overflowY: 'auto',
                          // height: 'fit-content',
                          animation: loading
                            ? 'fadeInOut 1s ease-in-out infinite alternate'
                            : 'none',

                          // border: '1px solid black',
                          width: showConversation.id != 0 ? '30%' : '100%',
                        }}>
                        {listOfUser.length != 0 ? (
                          listOfUser.map((data, i) => (
                            <div
                              onClick={e => {
                                getUserPerMsg(data);
                                // setinputValue('');
                                setKey(currentKey => currentKey + 1);
                              }}
                              className="show-demande-items"
                              style={{
                                backgroundColor:
                                  data.id == showConversation.id
                                    ? '#ff6f0026'
                                    : undefined,
                              }}
                              key={data.id}>
                              <div
                                style={{
                                  // border:"1px solid red",
                                  width:
                                    showConversation.id != 0 ? '100px' : '10px',
                                }}
                                className="itme-part1">
                                <img
                                  className="rounded-image"
                                  src={profile}></img>
                              </div>
                              <div
                                className="itme-part2"
                                style={{
                                  marginTop: '5px',
                                  paddingRight: '5px',
                                }}>
                                <div className="avatar-data">
                                  <p className="plabel-msg">{data.firstName}</p>
                                  <div
                                    style={{
                                      margin: 0,
                                      lineHeight: '1px',
                                    }}
                                    className="p-msg">
                                    {data.lastName}
                                  </div>
                                  <p className="p-date-mesg">
                                    Le {moment(new Date()).format('DD/MM/YYYY')}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : !loading &&
                          userNamesearch &&
                          listOfUser.length == 0 ? (
                          <div>
                            Désolé, il n'y a aucun utilisateur avec ce nom{' '}
                            <span style={{fontWeight: 700}}>
                              {' '}
                              {'<< ' + userNamesearch + ' >>'}{' '}
                            </span>
                            !{' '}
                          </div>
                        ) : (
                          userNamesearch ||
                          (loading && (
                            <div
                              style={{
                                display: 'flex',
                                alignContent: 'center',
                                  justifyContent: 'center',
                                // flexDirection:"column",
                                background: 'black',
                                padding: '3px',
                                borderRadius: '5px',
                                // flexDirection: 'column',
                                }}>
                                <p style={{color:"white",padding:0,margin:0}}>En train de préparer votre conversation </p>
                              <LoadingOutlined
                                  style={{
                                  marginLeft:"10px",
                                  color: 'orange',
                                  fontSize: '27px',
                                  fontWeight: 700,
                                }}
                              />
                            </div>
                          ))
                        )}
                      </div>
                      {showConversation.id != 0 && (
                        <UserChatPanel
                          setemptyConversation={setemptyConversation}
                          emptyConversation={emptyConversation}
                          sound={sound}
                          profileImg={profile}
                          divRef={divRef}
                          setNewMessage={setNewMessage}
                          handleClick={handleClick}
                          bg={bg}
                          chatBoxRef={chatBoxRef}
                          messageHere={messageHere}
                          sendMessage={sendMessage}
                          keys={keys}
                          channelId={channelId}
                          newMessage={newMessage}
                          showConversation={showConversation}
                          newListeMessage={newListeMessage}
                          loadingChange={loadingChange}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Row>
            {/* </ReactCSSTransitionGroup> */}
          </div>
          {/* <AppFooter /> */}
        </div>
      </div>
    </Fragment>
  );
};

export default ChatPage;
