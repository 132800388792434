export const bmiCalculator = ({weight, height, weightUnit, heightUnit}) => {
  // const weightKG = weightUnit === 'KG' ? weight : weight / 2.205;
  // const heightCM = heightUnit === 'CM' ? height : height * 30.48;
  console.log(weight,height);
// console.log(parseFloat(((weight / Math.pow(height/100,2))).toFixed(1)));
  return parseFloat(((weight / Math.pow(height/100,2))).toFixed(1));
};

export const idealWeight = ({gender, height, heightUnit}) => {
  const heightCM = heightUnit === 'CM' ? height : height * 30.48;

  if (gender === 'male') {
    return Math.round((50 + 0.91 * (heightCM - 152.4)).toFixed(1));
  } else {
    return Math.round((45.5 + 0.91 * (heightCM - 152.4)).toFixed(1));
  }
};
export const idealWeightLB = ({gender, height, heightUnit}) => {
  const heightCM = heightUnit === 'CM' ? height : height * 30.48;

  if (gender === 'male') {
    return Math.round((50 + 0.91 * (heightCM - 152.4) * 2.205).toFixed(1));
  } else {
    return Math.round((45.5 + 0.91 * (heightCM - 152.4) * 2.205).toFixed(1));
  }
};
