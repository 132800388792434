import {
  BarsOutlined,
  LeftOutlined,
  LeftSquareFilled,
  RightOutlined,
  TagOutlined,
  UserOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import HeaderLogo from '../../assets/images/logo.png';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HeaderLogo2 from '../../assets/images/logo2.png';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import StandardUsers from 'pages/StandardUsers';
import {useContext, useState} from 'react';
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import {Link} from 'react-router-dom';
import profile from '../../shared/prof2.png';
import iconSM from '../../shared/house-return.png';
import iconD from '../../shared/devices.png';
import iconC from '../../shared/calendar-clock.png';
import iconRS from '../../shared/restaurant.png';
import iconRP from '../../shared/restaurant (1).png';
import iconPRO from '../../shared/box-open.png';
import iconPR from '../../shared/tags.png';
import iconCOU from '../../shared/ticket.png';
import iconMEE from '../../shared/meeting2.png';
import iconA from '../../shared/headset.png';
import iconCond from '../../shared/terms-and-conditions.png';
// import iconDM from '../../shared/message.png';
import iconDM from '../../shared/comment-question.png';
import {AuthContext} from 'services';
import {orange} from '@mui/material/colors';
const emojis = [
  '😃',
  '😄',
  '😊',
  '😁',
  '😎',
  '👋',
  '🌟',
  '🌈',
  '💪',
  '👍',
  '🙌',
  '🌞',
  '🎉',
  '🚀',
  '🌺',
  '🌼',
  '🌻',
  '🌟',
  '🌞',
  '💖',
  '💫',
  '🔥',
  '🌈',
  '🌟',
  '🚀',
  '💪',
  '😊',
  '😃',
  '😁',
  '🎉',
];
function getRandomEmoji() {
  const randomIndex = Math.floor(Math.random() * emojis.length);
  return emojis[randomIndex];
}
export const SideBar = ({getAppBarMessage, width, getcollapsed}) => {
  const targetContext = useContext(AuthContext);
  const {loggedIn, user_data} = targetContext.state;

  // console.log("user_data :",user_data);
  const [collapsed, setCollapsed] = useState(true);

  if (document.location.pathname === '/standardUsers') {
    getAppBarMessage('Client Digital');
  }
  if (document.location.pathname.startsWith('/premiumUsers')) {
    getAppBarMessage('Client physique');
  }
  if (document.location.pathname === '/promo-code') {
    getAppBarMessage('Code Promo');
  }
  getcollapsed(collapsed);

  // if (width < 1200) {
  //   return (
  //     <Sidebar
  //       collapsed={collapsed}
  //       collapsedWidth={!collapsed ? '160px' : '0px'}
  //       style={{
  //         position: 'absolute',
  //         width: !collapsed ? '100%' : '50px',
  //         height: !collapsed ? '100%' : '54px',
  //         // border: "1px solid red"
  //       }}>
  //       <Menu
  //         style={
  //           {
  //             // marginTop:'10px'
  //             // display: 'flex',
  //             // alignItems: 'center',
  //             // justifyContent: 'space-between',
  //             // border:"1px solid orange"
  //           }
  //         }
  //         onClick={() => setCollapsed(!collapsed)}>
  //         {' '}
  //         <div
  //           style={{
  //             width: !collapsed ? '54px' : '54px',
  //             height: '50px',
  //             marginTop: '0px',
  //             cursor: 'pointer',
  //             display: 'flex',
  //             alignItems: 'center',
  //             justifyContent: 'space-around',
  //             flexDirection: 'row',
  //             background: !collapsed ? 'orange' : 'black',
  //             // border: '1px solid green',
  //           }}>
  //           {/* {collapsed && (
  //           <h1 style={{fontSize: '17px', fontWeight: 700, marginTop: '7px'}}>
  //             {' '}

  //           </h1>
  //         )} */}
  //           {/* Center Form */}
  //           {collapsed ? (
  //             <RightOutlined style={{fontSize: '20px', color: 'white'}} />
  //           ) : (
  //             <LeftOutlined style={{fontSize: '20px', color: 'white'}} />
  //           )}
  //           {/* <LeftSquareFilled size={10}  style={{ fontSize: '40px', color: !collapsed ? "white" : "white" }} /> */}
  //         </div>
  //       </Menu>
  //       {!collapsed && (
  //         <Menu
  //           menuItemStyles={{
  //             button: ({level, active, disabled, ...poprs}) => {
  //               console.log(poprs);
  //               console.log('level ', level, active, disabled);
  //               // only apply styles on first level elements of the tree
  //               if (level === 0) {
  //                 return {
  //                   zIndex: 999999999,
  //                   color: !active ? 'black' : 'white',
  //                   backgroundColor: active ? 'black' : undefined,
  //                   borderLeft: active ? '5px solid orange' : undefined,
  //                 };
  //               }
  //             },
  //           }}
  //           onClick={() => setCollapsed(!collapsed)}
  //           style={{
  //             // minWidth: '150% !important',
  //             // border:"1px solid red",

  //             marginTop: '150px',
  //             width: '100%',
  //           }}>
  //           <MenuItem
  //             //  rootStyles={{
  //             //   ['.' + menuClasses.button]: {
  //             //     backgroundColor: '#eaabff',
  //             //     color: '#9f0099',
  //             //     '&:hover': {
  //             //       backgroundColor: '#eecef9',
  //             //     },
  //             //   },
  //             // }}
  //             icon={<UserOutlined />}
  //             active={document.location.pathname === '/standardUsers'}
  //             component={<Link to="/standardUsers" />}>
  //             Client Digital
  //           </MenuItem>
  //           <MenuItem
  //             icon={<TagOutlined />}
  //             active={document.location.pathname === '/promo-code'}
  //             component={<Link to="/promo-code" />}>
  //             Code promo
  //           </MenuItem>
  //           <MenuItem
  //             icon={<ContactPageIcon />}
  //             style={{marginTop: '5px'}}
  //             active={document.location.pathname.startsWith('/premiumUsers')}
  //             component={<Link to="/premiumUsers" />}>
  //             Client Physique
  //           </MenuItem>
  //           <MenuItem
  //             icon={<ContactPhoneIcon />}
  //             active={document.location.pathname.startsWith('/physicalUsers')}
  //             component={<Link to="/physicalUsers" />}>
  //             {' '}
  //             Clients Semi-physique
  //           </MenuItem>

  //           {/* <MenuItem
  //           icon={<SmartphoneIcon />}
  //           active={document.location.pathname.startsWith('/standardUsers')}
  //           component={<Link to="/standardUsers" />}>
  //           Client Digital
  //         </MenuItem> */}
  //         </Menu>
  //       )}
  //     </Sidebar>
  //   );
  // } else
  return (
    <Sidebar
      // ,
      style={{zIndex:999999}}
      collapsed={collapsed}
      collapsedWidth={'65px'}>
      <Menu
        style={{
          overflow: 'hidden',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'space-between',
          // border:"1px solid orange"
        }}
        onClick={() => setCollapsed(!collapsed)}>
        {' '}
        <div
          style={{
            position: 'fixed',
            width: !collapsed ? '248px' : '64px',
            height: '50px',
            marginTop: '0px',
            cursor: 'pointer',
            display: 'flex',
            // opacity:1,
            // zIndex: 99999999,
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'row',
            animationName: !collapsed ? 'open' : 'close',
            animationDuration: !collapsed ? '.3s' : '.4s',
            background: !collapsed ? 'orange' : 'black',
            border: '1px solid black',
          }}>
          {!collapsed && (
            <h1
              style={{
                fontSize: '20px',
                fontWeight: 700,
                opacity: 1,
                marginTop: '7px',
                color: 'black',
              }}>
              {' '}
              Bienvenue {getRandomEmoji()}
            </h1>
          )}
          {/* Center Form */}
          {collapsed ? (
            <RightOutlined style={{fontSize: '20px', color: 'white'}} />
          ) : (
            <LeftOutlined style={{fontSize: '20px', color: 'white'}} />
          )}
          {/* <LeftSquareFilled size={10}  style={{ fontSize: '40px', color: !collapsed ? "white" : "white" }} /> */}
        </div>
      </Menu>
      <Menu
        style={{
          borderRadius: '5px',
          // boxShadow: "inset #424242 0px 0px 68px -42px",
          margin: '50px 5px 5px 5px',
          borderBottom: '1px solid #80808036',
          display: 'flex',
          height: '100px',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {!collapsed && <img width={150} src={HeaderLogo2} />}
        {collapsed && (
          <img style={{margin: '10px'}} width={50} src={HeaderLogo} />
        )}
      </Menu>
      <Menu
        renderMenuItemStyles={() => ({
          '.menu-anchor': {
            backgroundColor: 'red',
            '&:hover': {
              backgroundColor: 'green',
            },
          },
        })}
        menuItemStyles={{
          button: ({level, active, disabled, ...poprs}) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                fontWeight: '600',
                width: '92%',
                color: !active ? 'gray' : 'black',
                backgroundColor: active ? 'orange' : undefined,
                borderLeft: active ? '5px solid black' : undefined,
                borderRight: active ? '1px solid black' : undefined,
                '&:hover': {
                  // opacity: active ? '.7' : '1',
                  backgroundColor: active ? 'orange' : '#F9E8D9',
                  color: active ? 'white !important' : undefined,
                  // borderRadius: "8px !important",
                  // fontWeight: "bold !important"
                },
              };
          },
        }}
        style={{marginTop: '1px', width: '270px'}}>
        <div
        // style={{background:"#F7B787"}}
        >
          {user_data.type != 'admin' && (
            <MenuItem
              icon={<img style={{width: '25px'}} src={profile}></img>}
              // icon={<ComputerIcon></ComputerIcon>}
              active={document.location.pathname.startsWith('/collaborateurs/')}
              component={<Link to={`/collaborateurs/${user_data._id}`} />}>
              Profile
            </MenuItem>
          )}

          <MenuItem
            icon={<img style={{width: '25px'}} src={iconDM}></img>}
            // icon={<ComputerIcon></ComputerIcon>}
            active={document.location.pathname.startsWith('/mes-demandes')}
            component={<Link to="/mes-demandes" />}>
            Mes Demandes
          </MenuItem>
          {user_data.type == 'admin' && (
            <MenuItem
              title="Collaborateurs & Bannières publicitaires"
              icon={<img style={{width: '25px'}} src={iconA}></img>}
              // icon={<ComputerIcon></ComputerIcon>}
              active={document.location.pathname.startsWith('/collaborateurs')}
              component={<Link to="/collaborateurs" />}>
              {/* <div className='moving-text'> */}
              Collaborateurs & <br /> Bannières pub.
              {/* </div> */}
            </MenuItem>
          )}
        </div>

        <div>
         {user_data.type == 'admin' && <MenuItem
            // icon={<ContactPageIcon />}
            icon={<img style={{width: '25px'}} src={iconMEE}></img>}
            // style={{marginTop: '5px'}}
            active={document.location.pathname.startsWith('/premiumUsers')}
            component={<Link to="/premiumUsers" />}>
            Physique
          </MenuItem>}
          <MenuItem
            // icon={<ContactPhoneIcon />}
            icon={<img style={{width: '25px'}} src={iconSM}></img>}
            active={document.location.pathname.startsWith('/physicalUsers')}
            component={<Link to="/physicalUsers" />}>
            {' '}
            Semi-physique
          </MenuItem>
          {user_data.type == 'admin' && (
            <MenuItem
              icon={<img style={{width: '25px'}} src={iconD}></img>}
              // icon={<SmartphoneIcon />}
              active={document.location.pathname.startsWith('/standardUsers')}
              component={<Link to="/standardUsers" />}>
              Digital
            </MenuItem>
          )}
        </div>

        {/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

        <div
        // style={{ background: "#F7B787" }}
        >
          {/* {user_data.type != 'admin' && (
            <MenuItem
              // icon={<RestaurantMenuIcon />}
              icon={<img style={{width: '25px'}} src={iconSM}></img>}
              style={{marginTop: '5px'}}
              active={document.location.pathname.startsWith('/collaborateurs/clients')}
              component={<Link to={"/collaborateurs/clients/" + user_data._id} />}>
              Mes clients
            </MenuItem>
          )} */}

          {
            <MenuItem
              // icon={<RestaurantMenuIcon />}
              icon={<img style={{width: '25px'}} src={iconRP}></img>}
              // style={{marginTop: '5px'}}
              active={document.location.pathname.startsWith('/meals-premium')}
              component={<Link to="/meals-premium" />}>
              Régimes Premium
            </MenuItem>
          }

          {
            <MenuItem
              icon={<img style={{width: '25px'}} src={iconRS}></img>}
              // icon={<RestaurantIcon />}
              // style={{borderTop:"1px solid #8080801c",marginTop:"15px"}}
              active={document.location.pathname.startsWith('/meals-standard')}
              component={<Link to="/meals-standard" />}>
              Régimes Standard
            </MenuItem>
          }
        </div>
        <div>
          <MenuItem
            // style={{marginTop: '5px'}}
            icon={<img style={{width: '25px'}} src={iconC}></img>}
            // icon={<CalendarMonthIcon />}
            active={document.location.pathname.startsWith('/calendar')}
            component={<Link to="/calendar" />}>
            Rendez-vous
          </MenuItem>
        </div>

        <div>
          <MenuItem
            // icon={<BeenhereIcon />}
            icon={<img style={{width: '25px'}} src={iconPRO}></img>}
            // style={{marginTop: '5px'}}
            active={document.location.pathname.startsWith('/products')}
            component={<Link to="/products" />}>
            Produits
          </MenuItem>

          {user_data.type == 'admin' && (
            <MenuItem
              // icon={<EuroSymbolIcon />}
              icon={<img style={{width: '25px'}} src={iconPR}></img>}
              // style={{marginTop: '5px'}}
              active={document.location.pathname.startsWith('/prices-plan')}
              component={<Link to="/prices-plan" />}>
              Plan Tarifaire
            </MenuItem>
          )}
        </div>

        <div>
          {user_data.type == 'admin' && (
            <MenuItem
              // icon={<ReceiptLongIcon />}
              icon={
                <img
                  style={{width: '25px', color: 'orange'}}
                  src={iconCOU}></img>
              }
              style={{borderTop: '1px solid #8080801c'}}
              active={document.location.pathname.startsWith('/promo-code')}
              component={<Link to="/promo-code" />}>
              Code promo
            </MenuItem>
          )}
        </div>
        <div style={{transform: 'translateY(50px)', width: '109%'}}>
          <MenuItem
            // icon={<ReceiptLongIcon />}
            icon={<ChatOutlinedIcon />}
            style={{
              color: 'black',
              backgroundColor: document.location.pathname.startsWith(
                '/messagerie',
              )
                ? '#52D3D8'
                : undefined,
              borderTop: '1px solid #8080801c',
              // marginTop: '5px',
            }}
            // active={document.location.pathname.startsWith('/messagerie')}
            component={<Link to="/messagerie" />}>
            Messagerie
          </MenuItem>

          {user_data.type == 'admin' && (
            <MenuItem
              style={{
                color: 'black',
                backgroundColor: document.location.pathname.startsWith('/cgu')
                  ? '#52D3D8'
                  : undefined,
              }}
              title="Les conditions générales d’utilisation"
              // icon={<img style={{marginLeft:"10px",width: '30px'}} src={iconCond}></img>}
              icon={<ComputerIcon></ComputerIcon>}
              // active={document.location.pathname.startsWith('/cgu')}
              component={<Link to="/cgu" />}>
              CGU
            </MenuItem>
          )}
        </div>
      </Menu>
    </Sidebar>
    // </div>
  );
};
