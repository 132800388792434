import React, {Fragment, useEffect, useState} from 'react';
import {MySpinner, Input} from 'components/atoms';
import AppHeader from 'Layout/AppHeader/';
import AppSidebar from 'Layout/AppSidebar/';
import AppFooter from 'Layout/AppFooter/';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
  Row,
  Button,
  Form,
  CardBody,
  Card,
  Spinner,
  FormGroup,
  Col,
} from 'reactstrap';

import CenterFormApi from 'services/api/CenterFormApi';
import history from 'services/history';
import {useParams} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import {showToast} from 'shared/extra';

const diagnosisObj = {
  numberOfMeals: '',
  grigontageHabbit: [''],
  starchConsumption: ['', '', ''],
  fishSeafood: '',
  redMeat: '',
  whiteMeat: '',
  eggs: '',
  milk: '',
  dairyProducts: '',
  cheeses: '',

  fattyDescribtion: {
    butter: '',
    creamOil: '',
    fastFood: '',
    takeoutRestaurant: '',
    restaurantPlan: '',
  },
  otherData: '',
  drink: {
    water: '',
    sparklingWater: '',
    coffee: '',
    tea: '',
    salt: '',
  },
  sugarSweetDescribtion: {
    fruit: '',
    juice: '',
    sugar: '',
    compote: '',
    alcohol: '',
    pastries: '',
    pastry: '',
    pancake: '',
    candy: '',
    chocolate: '',
    soda: '',
    light: '',
  },

  yesterdayMeal: {
    breakfast: '',
    lunch: '',
    dinner: '',
    snacks: '',
  },
};

const Diagnosis = ({userData, type, from, setOnfinish, setFromData}) => {
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const {id} = useParams();

  const [user, setUser] = useState({
    id,
    ...diagnosisObj,
  });

  const getUserData = async () => {
    setLoadingData(true);
    try {
      const res = await CenterFormApi.get(
        `/userData/${from ? userData._id : id}`,
      );
      if (
        !Array.isArray(res.data.grigontageHabbit) ||
        !res.data.grigontageHabbit.length
      ) {
        res.data.grigontageHabbit = [''];
      }

      if (
        !Array.isArray(res.data.starchConsumption) ||
        !res.data.starchConsumption.length
      ) {
        res.data.starchConsumption = ['', '', ''];
      }

      setUser({...diagnosisObj, ...res.data});
    } catch (e) {}
    setLoadingData(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const submitForm = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await CenterFormApi.put('/updateUserData', {
        ...user,
        account_verified: true,
        signupFinshed: true,
      });
      if (res) {
        showToast('success', 'Données enregistrées !', 1000);
      }
      setLoading(false);

      if (type === 'premium') {
        history.push(`/premiumUsers/${id}`);
      } else {
        history.push(`/physicalUsers/${id}`);
      }
    } catch (e) {
      showToast('error', "Diagnostic d'erreur de réglage");
      setLoading(false);
    }
  };

  const submitFormDemandes = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      // const res = await CenterFormApi.put('/updateUserData', {
      //   ...user,
      //   account_verified: true,
      //   signupFinshed: true,
      // });
      // if (res) {
      //   showToast('success', 'Données enregistrées !', 1000);
      // }
      setLoading(false);
      setOnfinish(true);
     
      setFromData(prev => ({
        ...user,
        ...prev
      }));
    } catch (e) {
      showToast('error', "Diagnostic d'erreur de réglage");
      setLoading(false);
    }
  };

  const onInputChange = (e, target) => {
    setUser(user => ({
      ...user,
      [target]: e.target.value,
    }));
  };

  const onDrinkChange = (e, target) => {
    user.drink[target] = e.target.value;
    setUser(user => ({
      ...user,
    }));
  };

  const onSugarChange = (e, target) => {
    user.sugarSweetDescribtion[target] = e.target.value;
    setUser(user => ({
      ...user,
    }));
  };

  const onYesterdayMealChange = (e, target) => {
    user.yesterdayMeal[target] = e.target.value;
    setUser(user => ({
      ...user,
    }));
  };

  const onFattyChange = (e, target) => {
    user.fattyDescribtion[target] = e.target.value;
    setUser(user => ({
      ...user,
    }));
  };

  const onInputGrigontageChange = (e, i) => {
    user.grigontageHabbit[i] = e.target.value;
    setUser(user => ({
      ...user,
    }));
  };
  const addNewInput = () => {
    user.grigontageHabbit.push('');
    setUser(user => ({
      ...user,
    }));
  };
  const deleteInput = index => {
    user.grigontageHabbit = user.grigontageHabbit.filter(
      (item, index2) => index !== index2,
    );
    setUser(user => ({
      ...user,
    }));
  };

  const onInputStarchConsumptionChange = (e, i) => {
    user.starchConsumption[i] = e.target.value;
    setUser(user => ({
      ...user,
    }));
  };
  const addNewStarchConsumptionInput = () => {
    user.starchConsumption.push('');
    setUser(user => ({
      ...user,
    }));
  };
  const deleteStarchConsumptionInput = index => {
    user.starchConsumption = user.starchConsumption.filter(
      (item, index2) => index !== index2,
    );
    setUser(user => ({
      ...user,
    }));
  };

  return (
    <Fragment>
      {/* <AppHeader
        pageTitle=}
      /> */}
      {from ? (
        ''
      ) : (
        <p className="pageTitle">{`${user.firstName} ${user.lastName} - Diagnostic Diététique`}</p>
      )}
      <div className={from ? null : 'app-main centerform-main'}>
        {/* <AppSidebar /> */}
        <div className={from ? '' : 'app-main__outer'}>
          <div className={from ? '' : 'app-main__inner'}>
            {/* <MySpinner active={loadingData} /> */}
            <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <Row>
                <Card
                  style={{
                    boxShadow: 'none',
                    borderTop: '1px solid #bfbfbf38',
                    borderBottom: '1px solid #bfbfbf38',
                  }}
                  className="card-container">
                  <CardBody>
                    <span style={{fontSize: '14px', color: '#bfbfbf'}}>
                      (*) champs obligatoires
                    </span>
                    <Form
                      className="ecs-form"
                      autoComplete="off"
                      onSubmit={from ? submitFormDemandes : submitForm}>
                      <label className="center-form-label">
                        Habitudes Alimentaires
                      </label>
                      <FormGroup row>
                        <Col style={{zIndex: 11}} sm={12}>
                          <Row>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.numberOfMeals}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onInputChange(e, 'numberOfMeals')
                                  }
                                  label="Nombre de repas par jour*"
                                  type="text"
                                  required
                                />
                              </div>
                            </Col>
                            {user.grigontageHabbit.map((item, index) => (
                              <Col sm={4}>
                                <div className="form-group">
                                  <Input
                                    value={item}
                                    pattern=".*\S+.*"
                                    onChange={e =>
                                      onInputGrigontageChange(e, index)
                                    }
                                    label="Grignotage"
                                    type="text"
                                    placeholder=" "
                                  />
                                  {index + 1 ===
                                  user.grigontageHabbit.length ? (
                                    <div
                                      onClick={addNewInput}
                                      className="add-another">
                                      <span className="icon-plus" />
                                    </div>
                                  ) : null}
                                  {index ? (
                                    <div
                                      onClick={() => deleteInput(index)}
                                      className="add-another">
                                      <span className="icon-minus" />
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            ))}
                            {user.starchConsumption.map((item, index) => (
                              <Col style={{zIndex: 11}} sm={4}>
                                <div className="form-group">
                                  <Input
                                    value={item}
                                    pattern=".*\S+.*"
                                    onChange={e =>
                                      onInputStarchConsumptionChange(e, index)
                                    }
                                    label="Consommation de féculents"
                                    type="text"
                                    placeholder=" "
                                  />
                                  {index + 1 ===
                                  user.starchConsumption.length ? (
                                    <div
                                      onClick={addNewStarchConsumptionInput}
                                      className="add-another">
                                      <span className="icon-plus" />
                                    </div>
                                  ) : null}
                                  {index ? (
                                    <div
                                      onClick={() =>
                                        deleteStarchConsumptionInput(index)
                                      }
                                      className="add-another">
                                      <span className="icon-minus" />
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            ))}
                          </Row>

                          <Row>
                            <Col sm={12}>
                              <label className="center-form-label">
                                Consommation de protéines par semaine
                              </label>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.fishSeafood}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onInputChange(e, 'fishSeafood')
                                  }
                                  label="Poisson/ Fruits de mer"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.redMeat}
                                  pattern=".*\S+.*"
                                  onChange={e => onInputChange(e, 'redMeat')}
                                  label="Viandes rouges"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.whiteMeat}
                                  pattern=".*\S+.*"
                                  onChange={e => onInputChange(e, 'whiteMeat')}
                                  label="Viandes blanches"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.eggs}
                                  pattern=".*\S+.*"
                                  onChange={e => onInputChange(e, 'eggs')}
                                  label="Oeufs"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12}>
                              <label className="center-form-label">
                                Consommation de produits laitiers par jour 
                              </label>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.milk}
                                  pattern=".*\S+.*"
                                  onChange={e => onInputChange(e, 'milk')}
                                  label="Lait"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.dairyProducts}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onInputChange(e, 'dairyProducts')
                                  }
                                  label="Laitages"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.cheeses}
                                  pattern=".*\S+.*"
                                  onChange={e => onInputChange(e, 'cheeses')}
                                  label="Fromages"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12}>
                              <label className="center-form-label">
                                Boisson
                              </label>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.drink.water}
                                  pattern=".*\S+.*"
                                  onChange={e => onDrinkChange(e, 'water')}
                                  label="Eau"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.drink.sparklingWater}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onDrinkChange(e, 'sparklingWater')
                                  }
                                  label="Eaux gazeuses"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.drink.coffee}
                                  pattern=".*\S+.*"
                                  onChange={e => onDrinkChange(e, 'coffee')}
                                  label="Café"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.drink.tea}
                                  pattern=".*\S+.*"
                                  onChange={e => onDrinkChange(e, 'tea')}
                                  label="Thé"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.drink.salt}
                                  pattern=".*\S+.*"
                                  onChange={e => onDrinkChange(e, 'salt')}
                                  label="Sel"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <label className="center-form-label">
                                Consommation de sucres/produits sucrés par jour 
                              </label>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.fruit}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'fruit')}
                                  label="Fruit"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.juice}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'juice')}
                                  label="Jus"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.sugar}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'sugar')}
                                  label="Sucre"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.compote}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'compote')}
                                  label="Compote"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.alcohol}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'alcohol')}
                                  label="Alcool"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.pastries}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'pastries')}
                                  label="Pâtisseries"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.pastry}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'pastry')}
                                  label="Viennoiserie"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.pancake}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'pancake')}
                                  label="Galette"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.candy}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'candy')}
                                  label="Bonbon"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.chocolate}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'chocolate')}
                                  label="Chocolat"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.soda}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'soda')}
                                  label="Soda"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.sugarSweetDescribtion.light}
                                  pattern=".*\S+.*"
                                  onChange={e => onSugarChange(e, 'light')}
                                  label="Light"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <label className="center-form-label">
                                Consommation de corps gras (beurre et huile)
                              </label>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.fattyDescribtion.butter}
                                  pattern=".*\S+.*"
                                  onChange={e => onFattyChange(e, 'butter')}
                                  label="Beurre"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.fattyDescribtion.creamOil}
                                  pattern=".*\S+.*"
                                  onChange={e => onFattyChange(e, 'creamOil')}
                                  label="Huile crème"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.fattyDescribtion.fastFood}
                                  pattern=".*\S+.*"
                                  onChange={e => onFattyChange(e, 'fastFood')}
                                  label="Fast food"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={
                                    user.fattyDescribtion.takeoutRestaurant
                                  }
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onFattyChange(e, 'takeoutRestaurant')
                                  }
                                  label="Restaurant"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.fattyDescribtion.restaurantPlan}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onFattyChange(e, 'restaurantPlan')
                                  }
                                  label="Plat à emporter"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12}>
                              <label className="center-form-label">
                                Informations complémentaires
                              </label>
                            </Col>

                            <Col sm={12}>
                              <div className="form-group">
                                <Input
                                  value={user.otherData}
                                  pattern=".*\S+.*"
                                  onChange={e => onInputChange(e, 'otherData')}
                                  label="Ajouter des informations comlplémentaires"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12}>
                              <label className="center-form-label">
                                Repas de la veille
                              </label>
                            </Col>

                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.yesterdayMeal.breakfast}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onYesterdayMealChange(e, 'breakfast')
                                  }
                                  label="Petit Déjeuner"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.yesterdayMeal.lunch}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onYesterdayMealChange(e, 'lunch')
                                  }
                                  label="Déjeuner"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.yesterdayMeal.dinner}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onYesterdayMealChange(e, 'dinner')
                                  }
                                  label="Dîner"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <Input
                                  value={user.yesterdayMeal.snacks}
                                  pattern=".*\S+.*"
                                  onChange={e =>
                                    onYesterdayMealChange(e, 'snacks')
                                  }
                                  label="Grignotages"
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <div
                          style={{
                            marginBottom: from ? '0px' : '80px',
                            width: '100%',
                          }}
                          className="save-btn-container">
                          <Button
                            disabled={loading}
                            type="submit"
                            className={`btn-wide center-form-save-btn -mb-1`}
                            size="lg">
                            {loading ? (
                              <span style={{marginRight: '.5rem'}}>
                                <Spinner
                                  as="span"
                                  variant="warning"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="grow"
                                />{' '}
                              </span>
                            ) : null}
                            {from ? 'Confirmer et enregister' : 'Enregistrer'}
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Row>
              <ToastContainer closeOnClick />
            </ReactCSSTransitionGroup>
          </div>
          {/* <AppFooter /> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Diagnosis;
