import React, {useState, useEffect, useRef} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';


const DatePicker = ({onGetDate, fromvalue, label, ...props}) => {
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);

  const handleDateChange = newDate => {
    setDate(newDate);
    setShowCalendar(false);
  };

  const handleOutsideClick = event => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div style={{position: 'relative', width: '100%'}} ref={calendarRef}>
     {label&& <label
        style={{
          marginBottom: '-10px'
          ,
          marginLeft:"5px",
          fontFamily: 'Poppins-Regular',
          fontSize: '13px',
          color: 'black',
        }}>
        {label}
      </label>}
      <input
        // onChange={(e) => {
        //   fromvalue&& e.target.setCustomValidity('')
        // }}
        //  onInput={(e) => e.target.setCustomValidity('')} // Reset the custom validity on input
        onInvalid={e => {
          console.log(!fromvalue, fromvalue);
          !fromvalue && e.target.setCustomValidity('Ce champ est obligatoire!');
          fromvalue && e.target.setCustomValidity('');
        }} // Set the custom validation message
        title=""
        {...props}
        className="form-datePiker"
        type="text"
        value={fromvalue ? moment(fromvalue).format('DD/MM/YYYY') : ''}
        onFocus={() => {
          setShowCalendar(true);
        }}
        onChange={e => console.log(e)}
        onBeforeInput={e => fromvalue && e.target.setCustomValidity('')}
      />

      {showCalendar && (
        <div
          style={{
            zIndex: 1000,
            background: 'transparent',
            position: 'absolute',
            top: 70,
            left: 1,
            padding: '1px',
            outline: '1px solid orange',
          }}
          // className="calendar-modal"
        >
          <Calendar
            locale="fr"
            maxDate={props.max?props.max:null}
            minDate={props.min?props.min:null}
            onChange={onGetDate}
            value={fromvalue ? fromvalue : new Date()}
            tileClassName={({date, view}) =>
              view === 'month' &&
              fromvalue &&
              fromvalue.getDate() === date.getDate()
                ? 'selected-day'
                : date.getDate() === new Date().getDate() && view === 'month'
                ? 'today'
                : null
            }
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;

// // import {width} from '@material-ui/system';
// // import {Button} from 'antd';
// // import {Input} from 'components/atoms';
// // import moment from 'moment';
// // import {object} from 'prop-types';
// // import * as React from 'react';
// // import {useRef} from 'react';
// // import {useState} from 'react';
// // import Calendar from 'react-calendar';

// // export default function BasicDatePicker({isValid, onGetDate, fromvalue,SetOnvalid}) {
// //   const inputRef = useRef(null);
// //   const divRef = useRef(null);
// //   // const [focused, setFocused] = useState(false);
// //   // const [value, onChange] = useState(fromvalue);
// //   const [focuced, setFcused] = useState(false);

// //   //  setValue=(e)=>{ onChange(e)
// //   // }

// //   const handleDivFocus = () => {
// //     setFcused(true);
// //     // Focus on the input when the div is focused
// //     inputRef.current && inputRef.current.focus();
// //   };

// //   const handleInputFocus = () => {

// //     setFcused(true);

// //   };

// //   return (
// //     <>
// //       <div style={{position: 'relative', width: '100%'}}>
// //         <input
// //           required
// //           ref={inputRef}
// //           // onBlur={()=>setFcused(false)}
// //           className="form-datePiker"
// //           onFocus={handleInputFocus}
// //           value={fromvalue?moment(fromvalue).format('YYYY-MM-DD'):""}
// //         />
// //       </div>
// //       {focuced&&
// //         <div
// //           tabIndex={0}
// //           ref={divRef}

// //           onFocus={handleDivFocus}
// //           style={{
// //             zIndex: 99999,
// //             background: 'transparent',
// //             position: 'absolute',
// //             top: 150,
// //             left: 120,
// //             padding: '1px',

// //           }}>
// //           <Calendar   onFocus={handleDivFocus} locale="fr" onChange={onGetDate} value={fromvalue} />
// //           <div
// //             style={{
// //               paddingTop: '10px',
// //               width: '100%',
// //               display: 'flex',
// //               alignItems: 'center',
// //               justifyContent: 'flex-end',
// //             }}>
// //             <Button onClick={e => { setFcused(false); fromvalue&& SetOnvalid(true) }}>Valider</Button>
// //           </div>
// //         </div>
// //       }
// //     </>
// //   );
// // }
