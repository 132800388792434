export const demands = [
  {
    id: 1,
    userName: 'John Doe',
    age: 25,
    to: 'DIGITAL',
    email: 'john.doe@example.com',
    city: 'New York',
    dateSignup: '2023-01-01',
    phoneNumber: '123-456-7890',
  },
  {
    id: 2,
    userName: 'Jane Smith',
    age: 30,
    to: 'SEMIPHYSIQUE',
    email: 'jane.smith@example.com',
    city: 'Los Angeles',
    dateSignup: '2023-01-02',
    phoneNumber: '987-654-3210',
  },
  {
    id: 3,
    userName: 'Alex Johnson',
    age: 28,
    to: 'PHYSIQUE',
    email: 'alex.johnson@example.com',
    city: 'Chicago',
    dateSignup: '2023-01-03',
    phoneNumber: '555-123-4567',
  },
  {
    id: 4,
    userName: 'Emily White',
    age: 22,
    to: 'DIGITAL',
    email: 'emily.white@example.com',
    city: 'San Francisco',
    dateSignup: '2023-01-04',
    phoneNumber: '111-222-3333',
  },
  {
    id: 5,
    userName: 'Robert Miller',
    age: 35,
    to: 'SEMIPHYSIQUE',
    email: 'robert.miller@example.com',
    city: 'Miami',
    dateSignup: '2023-01-05',
    phoneNumber: '444-555-6666',
  },
  {
    id: 6,
    userName: 'Olivia Brown',
    age: 26,
    to: 'PHYSIQUE',
    email: 'olivia.brown@example.com',
    city: 'Seattle',
    dateSignup: '2023-01-06',
    phoneNumber: '777-888-9999',
  },
  {
    id: 7,
    userName: 'Daniel Davis',
    age: 31,
    to: 'DIGITAL',
    email: 'daniel.davis@example.com',
    city: 'Dallas',
    dateSignup: '2023-01-07',
    phoneNumber: '222-333-4444',
  },
  {
    id: 8,
    userName: 'Sophia Wilson',
    age: 29,
    to: 'SEMIPHYSIQUE',
    email: 'sophia.wilson@example.com',
    city: 'Houston',
    dateSignup: '2023-01-08',
    phoneNumber: '666-777-8888',
  },
  {
    id: 9,
    userName: 'Liam Taylor',
    age: 27,
    to: 'PHYSIQUE',
    email: 'liam.taylor@example.com',
    city: 'Denver',
    dateSignup: '2023-01-09',
    phoneNumber: '999-000-1111',
  },
  {
    id: 10,
    userName: 'Ava Jackson',
    age: 33,
    to: 'DIGITAL',
    email: 'ava.jackson@example.com',
    city: 'Atlanta',
    dateSignup: '2023-01-10',
    phoneNumber: '333-444-5555',
  },
  {
    id: 11,
    userName: 'Liam Taylor',
    age: 27,
    to: 'PHYSIQUE',
    email: 'liam.taylor@example.com',
    city: 'Denver',
    dateSignup: '2023-01-09',
    phoneNumber: '999-000-1111',
  },
  {
    id: 12,
    userName: 'Ava Jackson',
    age: 33,
    to: 'DIGITAL',
    email: 'ava.jackson@example.com',
    city: 'Atlanta',
    dateSignup: '2023-01-10',
    phoneNumber: '333-444-5555',
  },
  {
    id: 13,
    userName: 'Liam Taylor',
    age: 27,
    to: 'PHYSIQUE',
    email: 'liam.taylor@example.com',
    city: 'Denver',
    dateSignup: '2023-01-09',
    phoneNumber: '999-000-1111',
  },
  {
    id: 14,
    userName: 'Ava Jackson',
    age: 33,
    to: 'DIGITAL',
    email: 'ava.jackson@example.com',
    city: 'Atlanta',
    dateSignup: '2023-01-10',
    phoneNumber: '333-444-5555',
  },
];

export const returnStatement = type => {
  switch (type) {
    case 'DIGITAL':
      return 'Digital';
    case 'PHYSIQUE':
      return 'Physique';
    case 'SEMIPHYSIQUE':
      return 'semi-Physique';
    default:
      return '';
  }
};

export const returnBGcolor = type => {
  switch (type) {
    case 'DIGITAL':
      return 'black';
    case 'PHYSIQUE':
      return '#EE7214';
    case 'SEMIPHYSIQUE':
      return '#E48F45';
    default:
      return 'white';
  }
};

export const chatArray = [
  {
    id: 1,
    sender: 'Alice',
    message:
      'Hi, how are you?Hi, Hi, how are you?Hi Hi, how are you?Hi Hi, how are you?Hi how are you?Hi, how are you?Hi, how are you?Hi, how are you?Hi, how are you?Hi, how are you?Hi, how are you?Hi, how are you?',
    timestamp: new Date('2023-01-01T10:00:00'),
    messageList: [
      {
        id: 1,
        sender: 'Alice',
        receiver: 'Bob',
        messageBody:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.',
      },
      {id: 2, sender: 'Bob', receiver: 'Alice', messageBody: 'Hello Alice!'},
      {id: 3, sender: '0', receiver: 'Alice', messageBody: 'Hello Alice!'},
      {
        id: 4,
        sender: '0',
        receiver: 'Alice',
        messageBody: 'how are you Alice!',
      },

      // Add more objects as needed
    ],
  },
  {
    id: 2,
    sender: 'Bob',
    message: "I'm doing well, thank you!",
    timestamp: new Date('2023-01-01T10:15:00'),
    messageList: [
      {id: 1, sender: 'Alice', receiver: 'Bob', messageBody: 'Hi Bob!'},
      {id: 2, sender: 'Bob', receiver: 'Alice', messageBody: 'Hello Alice!'},
      // Add more objects as needed
    ],
  },
  {
    id: 3,
    sender: 'Alice',
    message: "That's great to hear!",
    timestamp: new Date('2023-01-01T10:30:00'),
    messageList: [
      {id: 1, sender: 'Alice', receiver: 'Bob', messageBody: 'Hi Bob!'},
      {id: 2, sender: 'Bob', receiver: 'Alice', messageBody: 'Hello Alice!'},
      // Add more objects as needed
    ],
  },
  {
    id: 4,
    sender: 'Bob',
    message: 'How was your day?',
    timestamp: new Date('2023-01-01T11:00:00'),
    messageList: [
      {id: 1, sender: 'Alice', receiver: 'Bob', messageBody: 'Hi Bob!'},
      {id: 2, sender: 'Bob', receiver: 'Alice', messageBody: 'Hello Alice!'},
    ],
  },
  
];



export const chatData = [
    {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow22222', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
    {
      _id: 1,
      user: 'Alice',
      channelId: '2',
      from_user: false,
      message:
        'Hi Bob! To prevent overflow', 
      createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
    },
    {
      _id: 1,
      user: 'Alice',
      channelId: '2',
      from_user: true,
      message:
        'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
      createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
      _id: 1,
      user: 'Alice',
      channelId: '2',
      from_user: true,
      message:
        'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
      createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
      _id: 1,
      user: 'Alice',
      channelId: '2',
      from_user: false,
      message:
        'Hi Bob! To prevent overflow', 
      createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
      _id: 1,
      user: 'Alice',
      channelId: '2',
      from_user: false,
      message:
        'Hi Bob! To prevent overflow', 
      createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },

      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
    },
    {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-01T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob! To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points in the line.To prevent overflow, normally unbreakable words may be broken at arbitrary points if there are no otherwise acceptable break points',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: true,
        message:
          'Hi Bob!there ads may be broken at arare no otherwise acceptable break points in the line.',
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },
      {
        _id: 1,
        user: 'Alice',
        channelId: '2',
        from_user: false,
        message:
          'Hi Bob! To prevent overflow', 
        createdAt:new Date("2023-12-15T13:50:52.368+00:00"),
      },


    // Add more objects as needed
  ]