import {ConfigProvider, Input} from 'antd';
import React from 'react';

// export const customValidationMessage = 'ce champ est obligatoire!'; // Customize the validation message here
const InputAnt = ({token, placeholder, style, onChange, value, ...props}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: 'orange',
          borderRadius: 5,

          // Alias Token
          colorBgContainer: '#bfbfbf0f',
        },
      }}>
          <Input
              {...props}
        placeholder={placeholder}
        style={!style ? {marginLeft: '90px'} : style}
        onChange={onChange}
        value={value}
        
      />
    </ConfigProvider>
  );
};

export default InputAnt;
