import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import {AuthProvider} from 'services';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './assets/base.css';
import configureStore from './config/configureStore';
import {Provider} from 'react-redux';
// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
  // Handle the error globally
  console.error("Global error:", error);
  // Optionally return true to prevent the default browser error handling
  return true;
}

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AuthProvider>
  </Provider>,
  document.getElementById('root'),
);
