import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

export default function MySpinner({active, children}) {
  return (
    <LoadingOverlay
      active={active}
      spinner={<BounceLoader />}
      styles={{
        wrapper: {
          // width: active ? '100%' : 'auto',
          // height: active ? '100%' : 'auto',
          top: 1000,
          right:0,
          position: 'absolute',
        
          'z-index': 20000,
          left: 0,
        },
      }}
    ></LoadingOverlay>
  );
}
